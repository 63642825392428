import React from 'react';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { getDWRecords } from '../../lib/wallet';
import TokensConfig from '../../assets/js/tokens';
import HistoryItemMobile from './HistoryItemMobile'
import queryString from 'query-string'
import { connect } from 'react-redux';
import KR from '../../assets/json/kr.json';
import EN from '../../assets/json/en.json';
import CH from '../../assets/json/ch.json';

const mapStateToProps = state => {
  return
};

class DepositWithdrawHistoryMobile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      history: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const values = queryString.parse(this.props.location.search)
  
      dispatch(getDWRecords(values.records, values.address)).then(res => {
        let historyLocal = [];
        res.map(resItem => {
          if (TokensConfig.PR_CODE[values.token] === resItem[0]) {
            //if (resItem[6] !== '0x') 
              historyLocal.push(resItem);
          }
          return null
        });
        this.setState({
          history: historyLocal
        });
      });
    
  }

  render() {
    const { history } = this.state;
    const values = queryString.parse(this.props.location.search)
    const langJson = values.lang === 'en'? EN : values.lang === 'ko'? KR : CH
    const { DW } = langJson;
    return (
      <Wrap1>
        <Content>
          {history.length > 0 ? (
            history.map((historyItem, index) => {
              return (
                 <HistoryItemMobile key={index} data={historyItem} decimals={TokensConfig.DECIMALS[values.token].DECIMAL} lang={values.lang} Token={values.token} LTPValue={values.ltp} USDValue={values.usd}/>
              );
            })
          ) : (
            <div className="empty">
              <span>{DW.NO_DATA}</span>
            </div>
          )}
        </Content>
      </Wrap1>
    );
  }
}

const Wrap1 = styled.div`
  width: 100%;
`;

const Content = styled.div`
  height: 100%;

  .empty {
    width: 100%;
    height: 372px;
    line-height: 428px;
    text-align: center;
    span {
      top: 60%;
      font-size: 15px;
      font-family: D-DIN;
      color: #6e93fe;
    }
  }
`;

export default connect(mapStateToProps)(DepositWithdrawHistoryMobile);
