import KR from '../assets/json/kr.json';
import EN from '../assets/json/en.json';
import CH from '../assets/json/ch.json';

const language = (
  state = {
    lang: localStorage.getItem('exnomy_lang') ? localStorage.getItem('exnomy_lang') : 'EN',
    langJson: localStorage.getItem('exnomy_lang') ? (localStorage.getItem('exnomy_lang') === 'EN' ? EN : KR) : EN
  },
  action
) => {
  switch (action.type) {
    case 'changeToEN':
      localStorage.setItem('exnomy_lang', 'EN');
      return {
        lang: 'EN',
        langJson: EN
      };
    case 'changeToKR':
      localStorage.setItem('exnomy_lang', 'KR');
      return {
        lang: 'KR',
        langJson: KR
      };
    case 'changeToCH':
      localStorage.setItem('exnomy_lang', 'CH');
      return {
        lang: 'CH',
        langJson: CH
      };
    default:
      return state;
  }
};

export default language;
