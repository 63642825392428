import React from 'react';
import styled from 'styled-components';

const InfoItem = props => {
  const { img, title, description, lang, isDarkMode } = props;
  return (
    <Wrap img={img} lang={lang} dark={isDarkMode}>
      <div className="image" />
      <div className="content">
        <div className="infoTitle">{title}</div>
        <div className="infoDescription">{description}</div>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 516px;
  margin-right: 69px;
  display: inline-block;
  vertical-align: top;
  .image {
    height: 70px;
    width: 70px;
    margin-left: 10px;
    margin-bottom: 15px;
    background: url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/${({ img }) => img}) no-repeat;
    background-size: 100%;
  }
  .content {
    height: ${({ lang }) => (lang === 'CH' ? '103px' : lang === 'EN' ? '150px' : '132px')};
    border-radius: 5px;
    //background: #f2f4fd;
    background: ${({dark}) => dark ? '#15222f' : '#f2f4fd'};
    text-align: left;
    margin-bottom: 50px;
    box-sizing: border-box;
    padding: 12px 12px 12px 15px;
    .infoTitle {
      font-size: 18px;
      color: #6666ff;
      font-weight: 500;
    }
    .infoDescription {
      height: ${({ lang }) => (lang === 'CH' ? '72px' : lang === 'EN' ? '96px' : '84px')};
      margin-top: 10px;
      font-size: 15px;
      //color: #1a1a1a;
      color : ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
      line-height: 22px;
      font-weight: 500;
    }
  }
`;

export default InfoItem;
