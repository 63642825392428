import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import market from './market';
import account from './account';
import config from './config';
import trade from './trade';
import theme from './theme'
import wallet from './wallet';
import loading from './loading';
import language from './language';
import DepositAddress from './DepositAddress';
import AccountsInfo from "./AccountsInfo";
import { WalletReducer } from 'exnomy-wallet';

const rootReducer = combineReducers({
  market,
  account,
  config,
  wallet,
  trade,
  loading,
  theme,
  language,
  DepositAddress,
  AccountsInfo,
  form: !!formReducer ? formReducer : {},
  WalletReducer
});

export default rootReducer;
