import { getSelectedAccountWallet, getSelectedAccount } from 'exnomy-wallet';
import api from './api';
import { getSContract } from './utils';
import coinAddressValidator from 'coin-address-validator';
import { toDoOperation } from '../actions/DepositAddress';
import config from './config';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import BigNumber from 'bignumber.js';
import { loadTokens } from '../actions/account';
import { loadingActions } from '../actions/loading'

export const checkWallet = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get('address') : null;
    const wallet = getSelectedAccountWallet(state);
    if (!wallet) {
      return;
    }

    const isLocked = await wallet.isLocked(address)
    return isLocked;
  };
};

export const VerifyAccount = () => {
  return async (dispatch, getState) => {
    const contract = getSContract(config.contract.DEXHI);
    const state = getState();
    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get('address') : null;
    const wallet = getSelectedAccountWallet(state);
    if (!wallet) {
      return;
    }

    const accountid = await contract.methods.GetAccountId(address).call({ from: address });
    if (accountid > 0) {
      //alert('Account ID Already Exist')
      return accountid;
    }
    return;
  };
};


export const withdrawKlay = (amount, TOAST) => {
  return async (dispatch, getState) => {
    try {
      const value = new BigNumber(amount).multipliedBy(Math.pow(10, 18)).toString();
      const state = getState();
      const selectedAccount = getSelectedAccountWallet(state);
      if (null === selectedAccount._wallet) {
        //throw "Please unlock BrowserWallet Key"
        Error(JSON.stringify({ error: 'Please unlock BrowserWallet Key' }));
      }
      const contract = getSContract(config.contract.DEXHI, selectedAccount._wallet.privateKey);
      const wallet = getSelectedAccountWallet(state);
      if (!wallet) {
        dispatch(loadingActions("WITHDRAW_LOADING", false))
        return;
      }
      //console.log(selectedAccount)

      const accountid = await contract.methods.withdrawKLAY(value).send({ from: selectedAccount._address, gas: '250000' });
      console.log(accountid)
      dispatch(loadingActions("WITHDRAW_LOADING", false))
      // calling balance to update balance
      dispatch(loadTokens())
      toast.success(TOAST.WITHDRAW_IS_SUCCESSFULL, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          background: '#6666ff !important',
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
      return;
    } catch (error) {
      dispatch(loadingActions("WITHDRAW_LOADING", false))
      console.log(error)
      toast.error(error.toString(), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
    }
    return null;
  };
};


export const AddAccount = () => {
  return async (dispatch, getState) => {
    const contract = getSContract(config.contract.DEXHI);
    const message = '{"type":"AddAccount"}';
    const state = getState();
    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get('address') : null;
    const wallet = getSelectedAccountWallet(state);
    if (!wallet) {
      return;
    }

    const accountid = await contract.methods.GetAccountId(address).call({ from: address });
    if (accountid > 0) {
      //alert('Account ID Already Exist')
      return accountid;
    }

    const signature = await wallet.signPersonalMessage(message)
      .catch((error) => {
        console.log("came here %%%" + error)
        toast.error(error.toString(), {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          className: css({
            textAlign: 'center',
            width: '280px',
            fontSize: '15px'
          })
        });
      });
    if (!signature) {
      return;
    }
    const res = await api.post('/bitgo/addAccount', {
      address: address,
      signature: signature,
      message: message
    });

    if (res.data.status === 0) {
      //alert("Add Account Successful")
      return res.data.data;
    } else {
      alert(res.data.desc);
      return;
    }
  };
};

export const minAmountOfToken = (tokenAddress) => {
  return async (dispatch, getState) => {
    const state = getState();

    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get('address') : null;
    const wallet = getSelectedAccountWallet(state);
    if (!wallet) {
      return;
    }

    const contract = getSContract(config.contract.DEX_ADMIN);
    const productInfo = await contract.methods.GetProductInfo(tokenAddress).call({ from: address });
    //console.log("Product info ",Number(productInfo[6])/Number(productInfo[1]))
    return Number(productInfo[6])/Number(productInfo[1])
  };
};

export const NewDepositAddress = (tokenAddress, tokenName) => {
  return async (dispatch, getState) => {
    const state = getState();

    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get('address') : null;
    const wallet = getSelectedAccountWallet(state);
    if (!wallet) {
      return;
    }

    const contract = getSContract(config.contract.DEX_ADMIN);
    const productInfo = await contract.methods.GetProductInfo(tokenAddress).call({ from: address });
    const message = '{"type":"NewDepositAddress","prCode":"' + productInfo.prCode + '"}';
    //const SignStr = message.replace(/(["'])/g, "\\$1");
    console.log("message ", message)
    const signature = await wallet.signPersonalMessage(message)
      .catch((error) => {
        dispatch(loadingActions("DEPOSIT_LOADING", false))
        toast.error(error.toString(), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          className: css({
            textAlign: 'center',
            width: '280px',
            fontSize: '15px'
          })
        });
      });
    if (!signature) {
      return;
    }

    const res = await api.post('/bitgo/newDepositAddress', {
      address: address,
      signature: signature,
      message: message
    });
    if (res.data.status === 0) {
      dispatch(loadingActions("DEPOSIT_LOADING", false))
      dispatch(toDoOperation(address, tokenAddress, res.data.data));
      if (res.data.data.length > 0) {
        var oldItems = JSON.parse(localStorage.getItem('itemsArray')) || [];
        var new_deposit_address = {
          address: address,
          token_address: tokenAddress,
          deposit_address: res.data.data
        };
        oldItems.push(new_deposit_address);
        localStorage.setItem('itemsArray', JSON.stringify(oldItems));
        return 1;
      }
      return 0;
    } else {
      dispatch(loadingActions("DEPOSIT_LOADING", false))
      // console.log(res.data.desc)
      // alert(res.data.desc)
      return;
    }
  };
};

export const NewWithdrawRequest = (accountAddress, tokenName, tokenAddress, amount, Waddress, TOAST) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = await getSContract(config.contract.DEXHI)
      .methods.GetAccountId(accountAddress)
      .call({ from: accountAddress });
    const lastTransactionId = await getSContract(config.contract.DW)
      .methods.GetLastTransactionId(accountId)
      .call({ from: accountAddress });
    const productInfo = await getSContract(config.contract.DEX_ADMIN)
      .methods.GetProductInfo(tokenAddress)
      .call({ from: accountAddress });

    if ((tokenName !== 'EOS' && tokenName !== 'LNC' && tokenName !== 'TUSD') && !validateWithdrawAddress(tokenName, Waddress)) {
      //alert("Invalid Withdraw Address");
      dispatch(loadingActions("WITHDRAW_LOADING", false))
      toast.error(TOAST.ENTER_VALID_ADDRESS, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
      return;
    }

    //message =  "{\"type\":\"WithdrawRequest\",\"lastTransactionId\":\"15\",\"prCode\":\"2\",\"amount\":\"3055533\",\"state\":\"1\",\"withdrawAddress\":\"23232gbfsdgbsB\"};
    const message =
      '{"type":"WithdrawRequest","lastTransactionId":' +
      '"' +
      lastTransactionId +
      '",' +
      '"prCode":' +
      '"' +
      productInfo.prCode +
      '",' +
      '"amount":' +
      '"' +
      amount +
      '",' +
      '"state":"1",' +
      '"withdrawAddress":' +
      '"' +
      Waddress +
      '",' +
      '"info":""}';
    //const SignStr = message.replace(/(["'])/g, "\\$1");

    console.log(message)

    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get('address') : null;
    const wallet = getSelectedAccountWallet(state);
    if (!wallet) {
      dispatch(loadingActions("WITHDRAW_LOADING", false))
      return;
    }

    const signature = await wallet.signPersonalMessage(message)
      .catch((error) => {
        console.log("came here %%%" + error)
        dispatch(loadingActions("WITHDRAW_LOADING", false))
        toast.error(error.toString(), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          className: css({
            textAlign: 'center',
            width: '280px',
            fontSize: '15px'
          })
        });
      });
    if (!signature) {
      dispatch(loadingActions("WITHDRAW_LOADING", false))
      return;
    }

    const res = await api.post('/bitgo/newWithdrawRequest', {
      address: address,
      signature: signature,
      message: message
    });

    if (res.data.status === 0) {
      //alert(res.data.data)
      dispatch(loadingActions("WITHDRAW_LOADING", false))
      // calling balance to update balance
      dispatch(loadTokens())
      toast.success(TOAST.WITHDRAW_IS_SUCCESSFULL, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          background: '#6666ff !important',
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
      return;
    } else {
      //alert(res.data.desc)
      dispatch(loadingActions("WITHDRAW_LOADING", false))
      toast.error(res.data.desc, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
      return;
    }
  };
};

const validateWithdrawAddress = (coinName, address) => {
  const address2 = address.split('?')[0];
  return coinAddressValidator.validate(address2, coinName, 'both');
};
