import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { Row } from 'react-bootstrap';

const mapStateToProps = state => {
  const lang = state.language.lang;
  const langJson = state.language.langJson;
  return {
    lang,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class MobileApp extends React.PureComponent {
  render() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
        <Container textAlign='center'>
          <Row>
            <div className="col-12 col-md-6 col-xl-5">
              <div className="mb-5 mb-md-0 mt">
                <img src={`${require('../../images/mobileapp.png').default}`} loading="lazy" alt="…" className="img-fluid" />
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-6 offset-xl-1">
              <div className="mb-3 JSDJ">
                <h4 className="fs-3 fw-bold mb-2 SDJKJ">Mobile app</h4>
                <p className="text-gray"> Get the Robo Advisory on the go: </p>
              </div>
              <ul className="check-list">
                <li>Strategy List</li>
                <li>View strategy details & performace</li>
              </ul>
              <div className="mt-4 d-grid gap-2 d-sm-block">
                <a href='https://play.google.com/store/apps/details?id=com.exnomyai' target="_blank" rel="noreferrer" className="me-sm-3"><img alt='Get it on Google Play' width="200px" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
              </div>
            </div>
          </Row>
        </Container>
      </Wrap>
    );
  }
}

const Wrap = styled.section`
  width: 100%;
  // height: 900px;
  // padding-top: 7.42857em;
  // padding-bottom: 7.42857em;
  // padding-top: 300px;
  padding-bottom: 100px;
  background: ${({ dark }) => dark ? '#1c2b3a' : '#fff'};
  .mb-3 {
    margin-bottom: 1rem!important;
  }
  .fs-3 {
    font-size: 1.75rem!important;
  }
  .fw-bold {
    font-weight: 700!important;
  }
  .mb-2 {
    margin-bottom: .5rem!important;
  }
  .JSDJ {
    text-align: left;
  }
  .mt-4 {
    margin-top: 1.5rem!important;
  }
  .btn, button {
    float: left;
    padding: 5px 30px;
    display: inline-block;
    border-radius: 5px;
    min-width: 130px;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .btn:after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    z-index: -1;
    border-radius: 5px;
    content: "";
    background: rgba(0,0,0,.09);
    transition: all .4s ease-in-out;
  } 
  .me-sm-3 {
    margin-right: 1rem!important;
  }
  .rounded-pill {
    border-radius: 50rem!important;
  }
  .btn-success {
    color: #000;
    background-color: #10d876;
    border-color: #10d876;
  }
  .gap-2 {
    gap: .5rem!important;
  }


  .text-gray {
    color: #869ab8;
    line-height: 1.6;
    font-weight: 400;
    // font-family: Nunito;
    font-size: 16px;
  } 
  ul {
    padding: 0;
    margin: 0;
  }
  .SDJKJ {
    color: ${({ dark }) => dark ? '#869ab8' : 'inherit'};
  }
  .check-list li {
    position: relative;
    padding: 10px 0;
    margin-left: 30px;
    font-size: 16px;
    color: #869ab8;
    list-style: none;
    text-align: left;
  }
  .check-list li:after {
    position: absolute;
    content: "\f00c";
    color: #1652f0;
    font-weight: 900;
    left: -30px;
    font-family: 'Icons';
  } 
  @media (max-width: 1024px) {
    // display: none;
    .mt {
      margin-top: 10px;
    }
  }
`;

export default connect(mapStateToProps)(MobileApp);
