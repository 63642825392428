import React, { useEffect, useState } from 'react'
// import DataTable, { registerType } from 'semantic-react-datatable'
import DataTable from 'react-data-table-component';
import moment from "moment";
import {
  Icon,
  Button,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getStrategyName } from "../../../lib/smartcontract/wallet";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function PanelItem({ expandedRow, isDarkMode, onlySubsribed }) {

  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const { firstBlocktimes, lastBlocktimes, owners, rates, ids, coinPair, lastSignal } = expandedRow;
    const isNative = false
    const numOfRows = parseInt(expandedRow[0]);

    async function fetchDetails() {
      let blockData = [];


      if (numOfRows == 0) {
        setRowData(0)
      }

      await firstBlocktimes.slice(0, numOfRows).map(async (data, i) => {
        let rateStars = [];
        for (let j = 0; j < rates[i]; j++) {
          rateStars.push(<Icon name="star" color="yellow" />)
        }

        let sName = await dispatch(getStrategyName({ id: parseInt(ids[i]) }))
        let blockDataObj = {
          firstBlock: moment(parseInt(firstBlocktimes[i]) * 1000).format("ll"),
          lastBlock: moment(parseInt(lastBlocktimes[i]) * 1000).format("ll"),
          fee: 0,
          rating: <div>{rateStars}</div>,
          coinPair: coinPair[i],
          lastSignal: lastSignal[i],
          advisor: <div>
            {owners[i].substr(0, 5) + "..." + owners[i].substr(owners[i].length - 3, owners[i].length + 1)}
            {" "}<CopyToClipboard text={owners[i].substr(0, 5) + "..." + owners[i].substr(owners[i].length - 3, owners[i].length + 1)}>
              <Button size="mini" basic compact color='blue'> <Icon name="copy" /></Button>
            </CopyToClipboard>
            {/* {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null} */}
          </div>,
          strategyName: sName,
          action: <div><Link to={"/robo/details/" + ids[i] + "/" + coinPair[i] + "/" + onlySubsribed + "/" + "live" + "/" + isNative}>
            Live</Link> | <Link to={"/robo/details/" + ids[i] + "/" + coinPair[i] + "/" + onlySubsribed + "/" + "test" + "/" + isNative}>
              Test</Link></div>,
        };

        blockData.push(blockDataObj);
        if (blockData.length == numOfRows) {

          setRowData(blockData)
        }

      });

      setLoading(false)

    }

    fetchDetails()
  }, [])

  return (
    <div>
      {/* <Divider horizontal>
        <Header as="h4">
          <Icon name="database" size="small" />

          {ADVISORY_PANEL.SEARCH_RESULT} {coin1 == "ALL" || coin1 == "all" ? coin1.toUpperCase() : coin1 + "/" + coin2} {advisorSearch ? ADVISORY_PANEL.AND + " " + advisorSearch.substr(0, 5) + "..." + advisorSearch.substr(advisorSearch.length - 3, advisorSearch.length + 1) : ""}
        </Header>
      </Divider> */}

      {
        loading && <div>Loading...</div>
      }
      {rowData !== null && rowData !== 0 &&
        <DataTable
          columns={columns}
          data={rowData}
          paginationPerPage={10}
          striped={true}
          pagination={true}
          noHeader={true}
          highlightOnHover={true}
          customStyles={customStyles}
          theme={isDarkMode ? "dark" : "light"}
        />
      }
      {
        rowData == 0 && <div>No Data</div>
      }
    </div>


  )
}

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      fontSize: "14px"
    },
  },
  rows: {
    style: {
      backgroundColor: "#f2f6fe",
      color: "#869ab8",// override the row height
      borderbottom:  "0px sold",
    }
  },
  
};

const columns = [
  {
    name: "Strategy",
    selector: "strategyName",
    sortable: true,
    wrap: true
    // minWidth: "170px"
  },
  {
    name: "Pair",
    selector: "coinPair",
    sortable: true,
    hide: ("sm")
  },
  {
    name: "Advisor",
    selector: "advisor",
    sortable: false,
    wrap: true
    // minWidth: "160px"
  },
  // {
  //   name: "Start Date",
  //   selector: "firstBlock",
  //   sortable: true,
  //   width: "170px"
  // },
  {
    name: "Last Signal",
    selector: "lastSignal",
    sortable: true,
    hide: ("sm")
  },
  {
    name: "Last Updated",
    selector: "lastBlock",
    sortable: true,
    // width: "170px",
    hide: ("sm")
  },
  // {
  //   name: "Fee",
  //   selector: "fee",
  //   sortable: true,
  //   right: true
  // },
  {
    name: "Data",
    selector: "action",
    sortable: false,
    wrap: true
  },
  {
    name: "Rating",
    selector: "rating",
    wrap: true,
    sortable: false,
  }
];
