import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';

const mapStateToProps = state => {
  const langJson = state.language.langJson;
  return {
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class ServiceDiscontinuation extends React.PureComponent {

  goTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { isDarkMode } = this.props;

    return (
      <div style={{ background: `${isDarkMode ? '#1c2b3a' : '#f9fbfd'}`, paddingTop: '50px' }}>
        <Terms>
          <Title>Service Discontinuation Notice</Title>

          <section className="terms" role="main">
            <div className="content">

              <h4>Notice of EXNOMY Service Termination</h4>
              <p>We regret to inform you that all EXNOMY services were terminated on July 8, 2024. We sincerely thank everyone who has used EXNOMY.</p>
              <p><strong>Service Termination Details</strong></p>
              <p><strong>Service Termination Date:</strong> July 8, 2024</p>
              <p><strong>Asset Withdrawal Method After Termination:</strong> Please contact info@dexhigh.com</p>
              <p><strong>Important Notes:</strong></p>
              <ul>
                <li>Trading will not be possible after the service termination.</li>
                <li>Asset withdrawal requests may take up to 14 days to process.</li>
              </ul>
              <p>Thank you.</p>

              <h4>Notice of LinkerCoin Buyback and EXNOMY Service Termination</h4>
              <p>EXNOMY services were terminated on July 8, 2024.</p>
              <p>As of June 8, 2024, LinkerCoin can be sold at approximately 0.111 USD on EXNOMY, and the buyback will continue until the termination of EXNOMY services. At the time of LinkerCoin's ICO, the price ranged between 0.03 USD and 0.1 USD. The minimum buyback price is calculated as follows:</p>
              <p><strong>Minimum Buyback Price = Current Ethereum Balance / Total Number of LinkerCoins Distributed to ICO Participants</strong></p>
              <p>The buyback is exclusively for the quantities distributed to ICO participants. Amounts distributed to employees, directors, advisors, etc., are not eligible for the buyback.</p>
              <p>Thank you.</p>

            </div>
          </section>
          <button className="topAnchor" onClick={this.goTop}>
            <i className="xi-caret-up"></i>
          </button>
        </Terms>
        <Footer />
      </div>
    );
  }
}

const Terms = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
  margin-bottom: 50px;
  background: #ffff;
  border: 1px solid #e2e6fa;
  .topAnchor {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: block;
    width: 40px;
    height: 40px;
    background: #036;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #fff;
  }
  .terms {
    width: 1100px;
    margin: 0 auto;
    .termsTitle {
      width: 100%;
      overflow: hidden;
      margin: 30px 0 30px;
      padding: 0 0 5px;
      h2 {
        font-family: 'Apple SD Gothic Neo', 'Malgun Gothic', sans-serif;
        font-size: 28px;
        font-weight: bold;
        color: #003366;
      }
    }
    .content {
      width: 100%;
      overflow: hidden;
      padding: 0 10px 20px;
      box-sizing: border-box;
      margin: 0 0 50px;
      h4 {
        font-size: 1.4rem;
        font-weight: 700;
        display: table;
        border-bottom: 1px solid #000;
        padding: 20px 0 0;
        margin: 0 0 15px;
      }
      p {
        font-size: 1rem;
        line-height: 1.6em;
        word-break: keep-all;
        margin: 0 0 8px;
        font-weight: 300;
      }
      ul {
        li {
          font-size: 1rem;
          line-height: 1.6em;
          margin: 0 0 15px;
          word-break: keep-all;
          ul {
            li {
              padding-left: 20px;
              line-height: 1.6em;
              margin: 3px 0;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .terms {
      width: 100%;
      padding: 0 0.8rem;
      box-sizing: border-box;
      .termsTitle {
        width: 100%;
        border-bottom: 1px solid #003366;
        h2 {
          font-size: 1.6rem;
          font-weight: normal;
        }
      }
      .content {
        width: 100%;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        h4 {
          font-size: 1.3rem;
        }
        p {
          font-size: 1rem;
          line-height: 1.6em;
        }
        ul {
          li {
            font-size: 1rem;
            line-height: 1.6em;
            ul {
              li {
                padding-left: 20px;
                line-height: 1.6em;
              }
            }
          }
        }
      }
    }
  }
`;

const Title = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 64px;
  padding-top: 16px;
  line-height: 48px;
  color: #6666ff;
  border-bottom: 1px solid #e2e6fa;
`;

export default connect(mapStateToProps)(ServiceDiscontinuation);
