import { getRESTclient } from './utils';

const restClient = getRESTclient();

export const get_token_value_toBTC = () => {
  let answer = 0;
  let found = false;
  var oldItems = JSON.parse(sessionStorage.getItem('token_value')) || [];
  oldItems.map(value => {
    if (value.token === 'BTC') {
      answer = value.USDtoBTC;
      found = true;
      return false;
    }
    return null;
  });
  if (!found) {
    restClient.getTickerDataPerSymbol(
      'global',
      'BTCUSD',
      function(response) {
        var p = JSON.parse(response);
        let new_value = {
          token: 'BTC',
          USDtoBTC: p.ask
        };
        oldItems.push(new_value);
        sessionStorage.setItem('token_value', JSON.stringify(oldItems));
        answer = p.ask;
        return false;
      },
      function(error) {
        console.log(error);
      }
    );
  }
  return answer;
};
