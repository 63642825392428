import React from 'react';
import styled from 'styled-components';
import InfoItem from './elements/InfoItem';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  const lang = state.language.lang;
  const langJson = state.language.langJson;
  return {
    lang,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class Info extends React.PureComponent {
  render() {
    const { langJson, lang, isDarkMode} = this.props;
    const { INFO } = langJson;
    return (
      <Wrap dark={isDarkMode}>
        <div className="logo" />
        <div className="title">{INFO.TITLE}</div>
        <div className="subTitle">{INFO.SUBTITLE}</div>
        <div className="imageArea">{getInfoItem(INFO, lang, isDarkMode)}</div>
      </Wrap>
    );
  }
}

const getInfoItem = (INFO, lang, isDarkMode) => {
  let content = [];
  for (let i = 1; i <= 4; i++) {
    content.push(
      <InfoItem
        isDarkMode={isDarkMode}
        lang={lang}
        img={`Dexhi_renewal_icon${i}.png`}
        title={INFO[`INFO${i}_TITLE`]}
        description={INFO[`INFO${i}_DESCRIPTION`]}
        key={INFO[`INFO${i}_TITLE`]}
      />
    );
  }
  return content;
};

const Wrap = styled.div`
  width: 100%;
  height: 900px;
  background: ${({dark}) => dark ? '#1c2b3a' : '#f9fbfd'};
  .logo {
    background: url(${require('../../images/exnomy_logo.png')}) no-repeat;
    background-size: 100%;
    background-position-y: bottom;
    margin: 100px auto 0;
    padding-top: 100px;
    width: 200px;
    height: 65px;
  }
  .title {
    text-align: center;
    font-size: 22px;
    color: #364958;
    font-weight: bold;
  }
  .subTitle {
    margin-top: 10px;
    font-size: 15px;
    color: #364958;
    text-align: center;
  }
  .imageArea {
    width: 1110px;
    margin: 80px auto 0;
    div:nth-child(even) {
      margin-right: 0;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export default connect(mapStateToProps)(Info);
