import React from 'react';
import { connect } from 'react-redux';
import { loadTokens } from '../../actions/account';
import { toUnitAmount } from '../../lib/utils';
import { stateUtils } from '../../selectors/account';
import { getSelectedAccount } from 'exnomy-wallet';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import 'react-toastify/dist/ReactToastify.css';
import { BigNumber } from 'bignumber.js';
import { get_token_value_toBTC } from '../../lib/bitcoinaverage';
import TokensConfig from '../../assets/js/tokens';
import { thousands_separators } from '../../lib/helper'
import MediaQuery from 'react-responsive';
import { getAccountId } from '../../lib/wallet';



const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  const langJson = state.language.langJson;
  return {
    tokensInfo: stateUtils.getTokensInfo(state, address),
    address,
    ethBalance: toUnitAmount(state.WalletReducer.getIn(['accounts', selectedAccountID, 'balance']), 18),
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    markets: state.market.getIn(['markets', 'data']),
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

const get_total_value = btc_balance => {
  let total_value = get_token_value_toBTC() * btc_balance;
  return thousands_separators(total_value.toFixed(1));
};
const get_Balance = (Token, tokenInfo) => {
  let total_value = 0;
  {
    tokenInfo.toArray().map(([token, info]) => {
      const { balance, decimals, holdBalance } = info.toJS();
      const availableBalance = toUnitAmount(BigNumber.max(balance.plus(holdBalance), '0'), decimals).toFixed(
        TokensConfig.DECIMALS.BTC.UI_DECIMAL
      );
      if (token.trim() === Token.trim()) total_value = availableBalance;
      return null
    });
  }
  return total_value;
};
const get_value_in_BTC = (markets, tokenInfo) => {
  var BTC_value = 0;
  {
    markets.reverse().map(obj => {
      if (obj.quoteToken === 'BTC') {
        BTC_value += get_Balance(obj.baseToken, tokenInfo) * obj.lastPrice;
      }
      return null
    });
  }
  return BTC_value;
};

const getAccountID = async(address) =>{
  return await getAccountId(address)
}

class Balance extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      userWalledId: ""
    };
  }

  notify = TOAST => {
    toast.success(TOAST.ADDRESS_COPIED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        background: '#6666ff !important',
        textAlign: 'center',
        width: '280px',
        fontSize: '15px',
      })
    });
  };

  componentDidMount() {
    const { address, dispatch } = this.props;
    if (address) {
      dispatch(loadTokens());
    }
  }

  componentDidUpdate(prevProps) {
    const { address, dispatch } = this.props;
    if (address && address !== prevProps.address) {
      dispatch(loadTokens());
    }
  }

  render() {
    const { address, tokensInfo, markets, langJson, isDarkMode } = this.props;
    const total_BTC = get_Balance('BTC', tokensInfo);
    const token_value_in_BTC = parseFloat(total_BTC) + get_value_in_BTC(markets, tokensInfo);
    const total_value_in_USD = get_total_value(token_value_in_BTC);
    const { BALANCE, TOAST } = langJson;
    let addressParsed;
    if (address){

      addressParsed = address.slice(0, 2) === '0x' ? address.slice(2) : address
      getAccountID(address).then((res)=> {this.setState({ userWalledId:res})})

    }
    else{

      addressParsed = ""
    }

    return (
      <Wrap dark={isDarkMode}>
        <Header dark={isDarkMode}>
          <div className="total_value">
            {BALANCE.TOTAL_VALUE} ({TokensConfig.CONSTANT.APPEND}BTC)
          </div>
          <div className="total_value">{BALANCE.TOTAL_VALUE} (USD)</div>
        
        {  <div className="wallet_lbl">{BALANCE.WALLET_ADDRESS} {this.state.userWalledId && `(${this.state.userWalledId})`}</div>}
        </Header>

        <Body>
          <div className="eth_balance">{token_value_in_BTC.toFixed(TokensConfig.DECIMALS.BTC.UI_DECIMAL)}</div>
          <div className="total_value_down">{TokensConfig.CONSTANT.APPEND}BTC</div>
          <div className="eth_balance">{total_value_in_USD}</div>
          <div className="total_value_down">USD</div>
         
          <div className="Wallet_Address_parent">
            <CopyToClipboard text={addressParsed}>
              <span className="Wallet_Address" onClick={() => this.notify(TOAST)}>
                 <MediaQuery minWidth={1024}> {addressParsed}</MediaQuery>
                 <MediaQuery maxWidth={767}>{addressParsed.slice(0,2)+".."+addressParsed.slice(-2)}</MediaQuery>
                 <MediaQuery minWidth={768} maxWidth={1023}>{addressParsed.slice(0,4)+"..."+addressParsed.slice(-4)}</MediaQuery>
              </span>
            </CopyToClipboard>
            <CopyToClipboard text={addressParsed}>
              <img
                className="img-copy"
                src={process.env.PUBLIC_URL + '/images/copy.png'}
                alt="Copy"
                width="18"
                height="18"
                onClick={() => this.notify(TOAST)}
              />
            </CopyToClipboard>
          </div>
        </Body>
      </Wrap>
    );
  }
}
const Wrap = styled.div`
  width: 1200px;
  height: 55px;
  margin: 0 auto;
  display: inline-block;
  box-sizing: border-box;
  border-left: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  border-right: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
   @media (max-width: 1024px) {
    width:100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
  .total_value {
    
    height: 55px;
    margin-top: 40px;
    margin-left: 30px;
    width: 30%;
    @media (max-width: 1024px) {
      width: 40%;
      font-size: 0.85rem;
    }
  }
  .wallet_lbl {
    height: 55px;
    margin-top: 40px;
    padding-left: 10px;
    width: 40%;
    @media (max-width: 1024px) {
      font-size: 0.85rem;
    }
  }

`;

const Body = styled.div`
	display: flex;
	align-items: center;
  height: 35px;
  @media (max-width: 1024px) {
    margin-left : 7%;
  }
  
  .total_value_down{
    margin-top : 48px
    height : 55px;
    margin-bottom : 0px;
    margin-left : 2px;
    color: #C0C0C0;
    width:2%;
    @media (max-width: 1024px) {
      margin-bottom : 0.5vh;
    }
  }

  .eth_balance{
    margin-top:8px
    width:28%;
    text-align:end;
    font-size: 20px;
    color: #6666ff;
    @media (max-width: 1024px) {
      font-size: 1.1rem;
    }
  }

  .Wallet_Address_parent{
    width:40%;
    padding-left : 10px;
    margin-left: 52px;
    .Wallet_Address{
      margin-top:16px;
      margin-right:4px;
      text-align: bottom;
      font-size: 16px;
      color: #6666ff;
      cursor: pointer;
    }
    .img-copy{
      margin-top:0px;
      cursor: pointer;
    }
  }
  
  `;

export default connect(mapStateToProps)(Balance);
