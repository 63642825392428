import { Map } from 'immutable';

const initialState = Map({
  depositAddressinfo: Map(),
  account_address: Map()
});

const DepositAddressinfo = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ADDRESS':
      state = state.setIn([
        'depositAddressinfo',
        action.account_address,
        [action.token_address, action.deposit_address]
      ]);
      return state;

    default:
      return state;
  }
};

export default DepositAddressinfo;
