import env from './env';
const abi_DEX_ADMIN = require('./abi_DEX_ADMIN.json');
const abi_DEXHI = require('./abi_DEXHI.json');
const abi_DW = require('./abi_DW.json');
const abi_Hydro = require('./abi_Hydro.json');

const config = {
  contract: {
    DEX_ADMIN: 1,
    DEXHI: 2,
    DW: 3,
    Hydro: 4
  },
  contractAddress: {
    DEX_ADMIN: env.DEX_ADMIN,
    DEXHI: env.DEXHI,
    DW: env.DW,
    Hydro: env.HYDRO
  },
  abi: {
    DEX_ADMIN: abi_DEX_ADMIN,
    DEXHI: abi_DEXHI,
    DW: abi_DW,
    Hydro: abi_Hydro
  },
  WebsocketProvider: 'ws://172.17.0.1:8552',
  //WebsocketProvider: 'wss://api.baobab.klaytn.net:8652',//'ws://ec2-52-14-113-55.us-east-2.compute.amazonaws.com:8552', //https://mainnet.infura.io/v3/b33eea3e622f4600a6e72a7b8f9219bf
  HttpProvider: env.NODE_URL
};

//module.exports = config;
export default config;
