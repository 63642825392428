import React from 'react';
import { connect } from 'react-redux';
import { loadTokens } from '../../actions/account';
import { toUnitAmount } from '../../lib/utils';
import { stateUtils } from '../../selectors/account';
import { getSelectedAccount } from 'exnomy-wallet';
import styled from 'styled-components';
import ChildDeposit from './childDeposit';
import { BigNumber } from 'bignumber.js';
import { toast } from 'react-toastify';
import TokensConfig from '../../assets/js/tokens';
import { css } from 'glamor';
import { thousands_separators } from '../../lib/helper'
import 'react-toastify/dist/ReactToastify.css';

const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  const Action = state.wallet.Action;
  const decimals = state.wallet.decimals;
  const TotalBalance = state.wallet.TotalBalance;

  const Token = state.wallet.Token;
  const lockedBalance = state.wallet.LockedBalance;
  const Availbalance = state.wallet.AvailableBalance;
  const tokenAddress = state.wallet.tokenAddress;
  const tokenType = state.wallet.tokenType;
  const langJson = state.language.langJson;
  return {
    tokensInfo: stateUtils.getTokensInfo(state, address),
    address,
    ethBalance: toUnitAmount(state.WalletReducer.getIn(['accounts', selectedAccountID, 'balance']), 18),
    Action,
    decimals,
    Token,
    TotalBalance,
    lockedBalance,
    Availbalance,
    tokenAddress,
    tokenType,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class DepositandWithdraw extends React.PureComponent {
  notify1 = TOAST => {
    toast.error(TOAST.DEPOSIT_DISABLED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify2 = TOAST => {
    toast.error(TOAST.WITHDRAW_DISABLED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify3 = DW => {
    toast.error(DW.KLAY_WARNING, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      tab: 'Withdraw'
    };
  }

  componentDidMount() {
    const { address, dispatch } = this.props;
    if (address) {
      dispatch(loadTokens());
    }
  }

  componentDidUpdate(prevProps) {
    const { address, dispatch } = this.props;
    if (address && address !== prevProps.address) {
      dispatch(loadTokens());
    }
  }

  render() {
    const {
      tokensInfo,
      Token,
      Availbalance,
      tokenAddress,
      tokenType,
      decimals,
      langJson,
      isDarkMode
    } = this.props;
    const { DW, TOKENS, TOAST } = langJson;

    let TotalBalanceStr = 0, LockedBalanceStr = 0, AvailableBalanceStr = 0;

    {
      tokensInfo.toArray().map(([token, info]) => {
        const { balance, decimals, lockedBalance, holdBalance } = info.toJS();
        const totalBalance = new BigNumber(balance.toString()).plus(new BigNumber(holdBalance.toString())) / Math.pow(10, decimals);
        const lockedBal = (new BigNumber(lockedBalance.toString()).plus(new BigNumber(holdBalance.toString()))) / Math.pow(10, decimals);
        const availableBalance = toUnitAmount(BigNumber.max(balance.minus(lockedBalance), '0'), decimals).toFixed(8);
        if (!localStorage.getItem("Wallet_CLICKED")) {
          localStorage.setItem("Wallet_CLICKED", "BTC")
        }
        if (localStorage.getItem("Wallet_CLICKED") === token) {
          const numberOfDecimalsToRoundOff = TokensConfig.DECIMALS[Token].UI_DECIMAL;
          TotalBalanceStr = new BigNumber(totalBalance).toFixed(numberOfDecimalsToRoundOff);
          LockedBalanceStr = new BigNumber(lockedBal).toFixed(numberOfDecimalsToRoundOff);
          AvailableBalanceStr = new BigNumber(availableBalance).toFixed(numberOfDecimalsToRoundOff);
        }
        return null
      })
    }


    if (this.state.tab === 'Deposit' && (TokensConfig.DEPOSIT[Token] === '0' ||
      (Token === 'KLAY' && localStorage.getItem(`HydroWallet:lastSelectedWalletType`) !== 'Hydro_Wallet'))) {
      this.setState({ tab: 'Withdraw' });
    }

    if (this.state.tab === 'Withdraw' && TokensConfig.WITHDRAW[Token] === '0') {
      this.setState({ tab: 'History' });
    }

    return (
      <Wrap dark={isDarkMode}>
        <div className="daww-label">
          {TokensConfig.CONSTANT.APPEND}
          {Token}
        </div>
        <Wrap2 dark={isDarkMode}>
          <div className="token-info">
            <div className="token-info-label">{DW.TOTAL}</div>
            <div className="token-info-content">
              <div className="token-info-balance">
                {
                  Number(TotalBalanceStr) === 0 ? 0 :
                    new BigNumber(TotalBalanceStr).gte(new BigNumber('1000')) ? thousands_separators(new BigNumber(TotalBalanceStr).toFixed(0)) :
                      new BigNumber(TotalBalanceStr).gte(new BigNumber('100')) ? new BigNumber(TotalBalanceStr).toFixed(1) :
                        new BigNumber(TotalBalanceStr).gte(new BigNumber('1')) ? new BigNumber(TotalBalanceStr).toFixed(3) :
                          TotalBalanceStr
                }
              </div>
              <div className="token-info-type">
                {TokensConfig.CONSTANT.APPEND}
                {Token}
              </div>
            </div>
          </div>
          <div className="token-info">
            <div className="token-info-label">{DW.HOLD}</div>
            <div className="token-info-content">
              <div className="token-info-balance">
                {
                  Number(LockedBalanceStr) === 0 ? 0 :
                    new BigNumber(LockedBalanceStr).gte(new BigNumber('1000')) ? thousands_separators(new BigNumber(LockedBalanceStr).toFixed(0)) :
                      new BigNumber(LockedBalanceStr).gte(new BigNumber('100')) ? new BigNumber(LockedBalanceStr).toFixed(1) :
                        new BigNumber(LockedBalanceStr).gte(new BigNumber('1')) ? new BigNumber(LockedBalanceStr).toFixed(3) :
                        LockedBalanceStr
                }
              </div>
              <div className="token-info-type">
                {TokensConfig.CONSTANT.APPEND}
                {Token}
              </div>
            </div>
          </div>
          <div className="token-info">
            <div className="token-info-label">{DW.AVBL}</div>
            <div className="token-info-content">
              <div className="token-info-balance">
                {
                  Number(AvailableBalanceStr) === 0 ? 0 :
                    new BigNumber(AvailableBalanceStr).gte(new BigNumber('1000')) ? thousands_separators(new BigNumber(AvailableBalanceStr).toFixed(0)) :
                      new BigNumber(AvailableBalanceStr).gte(new BigNumber('100')) ? new BigNumber(AvailableBalanceStr).toFixed(1) :
                        new BigNumber(AvailableBalanceStr).gte(new BigNumber('1')) ? new BigNumber(AvailableBalanceStr).toFixed(3) :
                        AvailableBalanceStr
                }
              </div>
              <div className="token-info-type">
                {TokensConfig.CONSTANT.APPEND}
                {Token}
              </div>
            </div>
          </div>
        </Wrap2>

        <div className="daww-tab-row">
          {/* <div
            className={`daww-tab ${this.state.tab === 'Deposit' ? 'active deposit' : null}`}
            onClick={() => {
              if (TokensConfig.DEPOSIT[Token] === '0') this.notify1(TOAST);
              else if (Token === 'KLAY' && localStorage.getItem(`HydroWallet:lastSelectedWalletType`) !== 'Hydro_Wallet') this.notify3(DW);
              else this.setState({ tab: 'Deposit' });
            }}>
            {TOKENS.DEPOSIT}
          </div> */}
          <div
            className={`daww-tab ${this.state.tab === 'Withdraw' ? 'active withdraw' : null}`}
            onClick={() => {
              if (TokensConfig.WITHDRAW[Token] === '0') this.notify2(TOAST);
              else if (Token === 'KLAY' && localStorage.getItem(`HydroWallet:lastSelectedWalletType`) !== 'Hydro_Wallet') this.notify3(DW);
              else this.setState({ tab: 'Withdraw' });
            }}>
            {TOKENS.WITHDRAW}
          </div>
          <div
            className={`daww-tab ${this.state.tab === 'History' ? 'active history' : null}`}
            onClick={() => this.setState({ tab: 'History' })}>
            {TOKENS.HISTORY}
          </div>
        </div>
        <ChildDeposit
          Action={this.state.tab}
          Token={Token}
          Balance={Availbalance}
          tokenAddress={tokenAddress}
          tokenType={tokenType}
          decimals={decimals}
        />
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 450px;
  height: 864px;
  vertical-align: top;
  margin-top: 10px;
  border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  margin-left: 10px;

  .daww-label {
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-size: 14px;
    color: #6666ff;
    border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    height: 44px;
  }

  .daww-tab-row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 44px;
    align-items: center;
    padding-left: 30px;
    border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}

    .daww-tab {
      box-sizing: border-box;
      line-height: 44px;
      height: 100%;
      margin-right: 30px;
      cursor: pointer;
      font-size: 14px;
      color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    }

    .daww-tab.active {
      &.deposit {
        color: #5fa56f;
        border-bottom: solid 3px #5fa56f;
      }
      &.withdraw {
        color: #ec6856;
        border-bottom: solid 3px #ec6856;
      }
      &.history {
        color: #6666ff;
        border-bottom: solid 3px #6666ff;
      }
    }
  }
  @media (max-width: 1024px) {
    width:100%;
    margin-left: 0px;
  }
`;

const Wrap2 = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 134px;
  display: flex;
  flex-direction: column;
  padding: 0px 30px 0px 30px;
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}

  .token-info {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 44.5px;
    padding-left: 10px;
    .token-info-label {
      font-size: 12px;
      color: #6666ff;
    }
    .token-info-content {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      .token-info-balance {
        font-size: 16px;
        //color: #364958;
        color: ${({ dark }) => (dark ? '#fff' : '#364958')};
        margin-right: 8px;
      }
      .token-info-type {
        font-size: 12px;
        color: #abb4e5;
      }
    }
    .token-info-sub-content {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      top: 28px;
      left: 0;
      font-size: 12px;
      color: #676767;
    }
  }
`;

export default connect(mapStateToProps)(DepositandWithdraw);
