import React from 'react';
import { connect } from 'react-redux';
import { loadMarkets, loadTradeHistory } from '../actions/markets';
import Wallet from '../components/Wallet';
import env from '../lib/env';
import MediaQuery from 'react-responsive';
//import { Wallet as SDKWallet } from 'exnomy-wallet';
import 'exnomy-wallet/index.css';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Popup from '../components/Popup';

const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  return {
    selectedAccountID,
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
    isDarkMode: state.theme.isDarkMode
  };
};

class WalletPage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      mobileTab: 'trade'
    };
  }

  componentDidMount() {
    const { dispatch, currentMarket } = this.props;
    dispatch(loadMarkets());
    if (parseInt(env.NETWORK_ID) === 66) {
      this.initTestBrowserWallet();
    }
    if (currentMarket) {
      dispatch(loadTradeHistory(currentMarket.id));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentMarket, dispatch } = this.props;
    if (currentMarket !== prevProps.currentMarket) {
      dispatch(loadTradeHistory(currentMarket.id));
    }
  }

  render() {
    // const { currentMarket } = this.props;
    // if (!currentMarket) {
    //   return null;
    // }
    return (
      <div>
        {/* <SDKWallet title="Starter Kit Wallet" nodeUrl={env.NODE_URL} defaultWalletType="Hydro-Wallet" /> */}
        <Popup />
        <MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </div>
    );
  }

  renderMobile() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
      <Contents>
        <Wallet />
      </Contents>
      <Footer />
    </Wrap>)
  }

  renderTablet() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
      <Contents>
        <Wallet />
      </Contents>
      <Footer />
    </Wrap>)
  }

  renderLaptop() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
        <Contents>
          <Wallet />
        </Contents>
        <Footer />
      </Wrap>
    );
  }

  renderDesktop() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
        <Contents dark={isDarkMode}>
          <Wallet />
        </Contents>
        <Footer />
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  width: 100%;
  //background: #f9fbfd;
  background: ${({dark}) => dark ? '#1c2b3a' : '#f9fbfd'};
  padding-top: 20px;
  box-sizing: border-box;
`;

const Contents = styled.div`
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  //background: #f9fbfd;
  background: ${({dark}) => dark ? '#1c2b3a' : '#f9fbfd'};
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    width:100%;
  }
`;

export default connect(mapStateToProps)(WalletPage);
