import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { Row, Col } from 'react-bootstrap';

const mapStateToProps = state => {
  const lang = state.language.lang;
  const langJson = state.language.langJson;
  return {
    lang,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class HowToWork extends React.PureComponent {
  render() {
    const { langJson, isDarkMode } = this.props;
    const { GUIDE } = langJson;
    return (
      <Wrap dark={isDarkMode}>
        <Container textAlign='center' className='HJBDDV'>
          <Grid centered columns={1} className="JBDSJ">
            <Grid.Column>
              <div className="section-heading text-center">
                <h2 className="KJBDS">How Does It Work</h2>
                <p className="text-gray"> Curious to know more about Robo Advisory </p>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
        <Container textAlign='center'>
          <Row>
            <Col md={6}>
              <div className="block-content ">
                {/* <svg className="section-dashed" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="" xmlSpace="preserve">
                  <path className="dashed-line" strokeWidth="1" d="M30 0 v600 400"></path>
                </svg> */}
                {/* <ul>
                  <li className="step"> */}
                    {/* <div className="step-num"> */}
                      {/* <svg viewBox="0 0 103 103" version="1.1" xmlns="http://www.w3.org/2000/svg" className="dashed-border">
                        <circle cx="50%" cy="50%" r="50"></circle>
                      </svg> */}
                      {/* <span>1</span> */}
                    {/* </div> */}
                    {/* <div className="step-body"> */}
                      <h4>Below is the step by step guide to Exnomy Robo Advisory</h4>
                      <p className="btnText">
                        <a className="but" rel="noreferrer" target="_blank" href="https://drive.google.com/file/d/1C2k8Sg4zgjgikV9PYj55fPyVo8Mw2Vqu/view?usp=sharing">
                          <i className="book icon"></i>
                          {GUIDE.CONTENT_04}
                        </a>                      </p>
                    {/* </div> */}
                  {/* </li> */}
                  {/* <li className="step">
                    <div className="step-num">
                      <svg viewBox="0 0 103 103" version="1.1" xmlns="http://www.w3.org/2000/svg" className="dashed-border">
                        <circle cx="50%" cy="50%" r="50"></circle>
                      </svg>
                      <span>2</span>
                    </div>
                    <div className="step-body">
                      <h4>Get Coin to a Wallet</h4>
                      <p>
                        Behind the scenes, the Bitcoin network is sharing a massive public ledger called the "block chain". This ledger contains every transaction ever processed which enables a user's computer to verify the validity of each transaction.
                      </p>
                    </div>
                  </li>
                  <li className="step">
                    <div className="step-num">
                      <svg viewBox="0 0 103 103" version="1.1" xmlns="http://www.w3.org/2000/svg" className="dashed-border">
                        <circle cx="50%" cy="50%" r="50"></circle>
                      </svg>
                      <span>3</span>
                    </div>
                    <div className="step-body">
                      <h4>Send/Receive with Wallet</h4>
                      <p>
                        Many people new to Bitcoin are curious about how to get some. Bitcoin faucets, places where bitcoins are given away for free, have been a part of spreading Bitcoin since the earliest days.
                      </p>
                    </div>
                  </li> */}
                {/* </ul> */}
              </div>
            </Col>
            <Col md={{ span: 6 }}>
              <div className="block-content enhanced-1 block-radius block-shadow ">
                <div className="background-img">
                  <img src={require('../../images/5.jpeg').default} />
                </div>
              </div>
            </Col>
            {/* <Col sm="6" style={{ textAlign: "center" }} >
              <a className="but" rel="noreferrer" target="_blank" href="https://drive.google.com/file/d/1C2k8Sg4zgjgikV9PYj55fPyVo8Mw2Vqu/view?usp=sharing">
                <i className="book icon"></i>
                {GUIDE.CONTENT_04}
              </a>
            </Col> */}
          </Row>
        </Container>
      </Wrap>
    );
  }
}

const Wrap = styled.section`
  width: 100%;
  // height: 900px;
  // padding-top: 7.42857em;
  // padding-bottom: 7.42857em;
  padding-top: 100px;
  padding-bottom: 100px;
  background: ${({ dark }) => dark ? '#1c2b3a' : '#fff'};
  .KJBDS {
    font-size: 30px;
    color: ${({ dark }) => dark ? '#869ab8' : 'inherit'};
  }
  .section-heading p {
    margin-top: 8px;
    color: #869ab8;
    font-size: 16px;
    // padding: 0px 256px;
  }
  .block-content {
    position: relative;
    width: 100%;
  }
  .btnText {
    margin-top:20%;
  }
  .section-dashed {
    left: 0;

    position: absolute;
    top: 0;
    z-index: 0;
    // transform: translateX(-50%);
    // -webkit-transform: translateX(-50%);
    // -moz-transform: translateX(-50%);
    // -ms-transform: translateX(-50%);
    // -o-transform: translateX(-50%);
    // height: 100%;
  }
  .block-steps {
    position: relative;
  }
  ol, ul {
    list-style: none;
  }
  .block-steps li:not(:last-child) {
    margin-bottom: 2em;
  }
  .block-steps li {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  // .block-steps li .step-num {
  //   // float: left;
  //   width: 3.7em;
  //   height: 3.7em;
  //   border-radius: 50%;
  //   -webkit-border-radius: 50%;
  //   -moz-border-radius: 50%;
  //   -ms-border-radius: 50%;
  //   -o-border-radius: 50%;
  //   background: ${({ dark }) => dark ? '#1c2b3a' : '#ffffff'};
  //   // text-align: center;
  //   position: relative;
  // }
  .dashed-border {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    stroke: #ececec;
    stroke-width: 3px;
    stroke-dasharray: 5 5;
    stroke-dashoffset: 8;
    stroke-linecap: square;
    fill: none;
  }
  // .block-steps li .step-num span {
  //   font-size: 1.37em;
  //   font-weight: 600;
  //   color: #525aee;
  //   line-height: 2.6em;
  //   font-family: 'Raleway', sans-serif;
  // } 
  .block-steps li .step-body {
    width: 100%;
    text-align: center;
    // float: right;
  }
  .step-body h4 {
    // text-align: left;
    color:${({ dark }) => dark ? '#869ab8' : '#252525'};
  }
  .step-body p {
    text-align: left;
    line-height: 1.7;
    // font-size: .8125em;
    color: #666666 !important;
    margin-bottom: 1.875em;
  }
  block-content.enhanced-1 {
    min-height: 400px;
  }
  .block-shadow {
    box-shadow: 0 25px 40px rgb(37 37 37 / 30%);
    -webkit-box-shadow: 0 25px 40px rgb(37 37 37 / 30%);
  }
  .block-radius, .block-radius .background-img {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: : 6px;
  }
  .block-content {
    position: relative;
    width: 100%;
  }
  .background-img img{
    // background-image: url(${require('../../images/5.jpeg').default});
    background-attachment: scroll;
    background-clip: border-box;
    background-color: #252525;
    // background-image: none;
    background-origin: padding-box;
    background-position: top center !important;
    background-repeat: repeat;
    background-size: cover !important;
    height: 100%;
    left: 0;
    // position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    border-radius: 6px;
  }
  .HJBDDV {
    padding-bottom: 5.42857em;
  }
  .but {
    display: inline-block;
    margin-bottom:10px;
    position: relative;
    color: #ffffff;
    padding: .7em 2.9567em;
    background: #525aee;
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 700;
    line-height: 2.25em;
    border-radius: 30px;
    // float: left;
  }
  @media (max-width: 1024px) {
    // display: none;
    .block-steps li .step-num {
      display:none;
    }
  }
`;

export default connect(mapStateToProps)(HowToWork);
