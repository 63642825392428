import React from 'react';
import styled from 'styled-components';
import MainCard from './elements/MainCard';
import { connect } from 'react-redux';
import TokensConfig from '../../assets/js/tokens';

const mapStateToProps = state => {
  return {
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    markets: state.market.getIn(['markets', 'data']),
    isDarkMode: state.theme.isDarkMode
  };
};

class Card extends React.PureComponent {
  render() {
    const { markets, isDarkMode } = this.props;
    let count = 0;
    return (
      <Wrap>
        {markets.map(market => {
          if (market.quoteToken === 'BTC') {
            if (count >= 5) return null;
            count++;

            return (
              <MainCard
                isDarkMode={isDarkMode}
                key={`${market.baseToken}/${market.quoteToken}`}
                symbol={`${TokensConfig.CONSTANT.APPEND}${market.baseToken}/${TokensConfig.CONSTANT.APPEND}${market.quoteToken}`}
                currentPrice={market.lastPrice}
                trade={market.baseToken}
                quoteToken={market.quoteToken}
                percent={market.price24h}
                tradeCode={market}
              />
            );
          } else {
            return null
          }
        })}
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  div:last-child {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 6rem;
    overflow: hidden;
    margin-top: 1.5rem;
    div {
      display: none;
    }
    div:first-child {
      display: inline-block;
    }
    div:nth-child(2) {
      display: inline-block;
      margin-right: 0;
    }
  }
`;

export default connect(mapStateToProps)(Card);
