import api from '../lib/api';
import { getSelectedAccountWallet } from 'exnomy-wallet';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import env from '../lib/env';
import { loadingActions } from '../actions/loading'
export const TRADE_FORM_ID = 'TRADE';
export const TRADE_FORM_ID_BUY = 'TRADE_BUY';
export const TRADE_FORM_ID_SELL = 'TRADE_SELL';

export const trade = (side, price, amount, orderType = 'limit', TOAST, expires = 86400 * 365 * 1000) => {
  return async (dispatch) => {
    try {
      const result = await dispatch(createOrder(side, price, amount, orderType, expires));
      if (result.status === 0) {
        //alert('Successfully created order');
        dispatch(loadingActions("TRADE_LOADING", false))
        toast.success(TOAST.ORDER_CREATED, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          className: css({
            background: '#6666ff !important',
            textAlign: 'center',
            width: '280px',
            fontSize: '15px'
          })
        });

        return true;
      } else {
        //alert(result.desc);
        dispatch(loadingActions("TRADE_LOADING", false))
        toast.error(result.desc, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          className: css({
            textAlign: 'center',
            width: '280px',
            fontSize: '15px'
          })
        });
      }
    } catch (e) {
      //alert(e);
      dispatch(loadingActions("TRADE_LOADING", false))
      toast.error(e.toString(), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
    }

    return false;
  };
};

const createOrder = (side, price, amount, orderType, expires) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentMarket = state.market.getIn(['markets', 'currentMarket']);
    const buildOrderResponse = await api.post('/orders/build', {
      amount,
      exchange_address: env.DEXHI,
      admin_address: env.DEX_ADMIN,
      price,
      side,
      expires,
      orderType,
      marketID: currentMarket.id
    });

    if (buildOrderResponse.data.status !== 0) {
      return buildOrderResponse.data;
    }

    const orderParams = buildOrderResponse.data.data.order;
    const { id: orderID } = orderParams;
    try {
      const wallet = getSelectedAccountWallet(state);
      const signature = await wallet.signPersonalMessage(orderID);
      const orderSignature = '0x' + signature.slice(130) + '0'.repeat(62) + signature.slice(2, 130);
      const placeOrderResponse = await api.post('/orders', {
        orderID,
        signature: orderSignature,
        method: 0
      });

      return placeOrderResponse.data;
    } catch (e) {
      //alert(e);
      dispatch(loadingActions("TRADE_LOADING", false))
      toast.error(e.toString(), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
    }
  };
};

export const tradeUpdate = trade => {
  return {
    type: 'TRADE_UPDATE',
    payload: {
      trade
    }
  };
};

export const marketTrade = trade => {
  return {
    type: 'MARKET_TRADE',
    payload: {
      trade
    }
  };
};

export const toDoTrade = (price, amount, side) => {
  return {
    type: 'TRADE',
    price,
    amount,
    side
  };
};
