import BigNumber from 'bignumber.js';
import { watchToken } from '../actions/account';
import { getSContract } from './utils';
import env from './env';
import config from './config';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { loadingActions } from '../actions/loading'
import { loadTokens } from '../actions/account';
import { getSelectedAccountWallet, getTransactionReceipt } from 'exnomy-wallet';
export let web3, Contract;

export const getAccountId = async (accountAddress) => {
  try {

    const accountId = await getSContract(config.contract.DEXHI)
      .methods.GetAccountId(accountAddress)
      .call({ from: accountAddress });

    return accountId;
  } catch (e) {
    return null;
  }
};

export const getTokenBalance = async (tokenAddress, accountAddress) => {
  try {
    const accountId = await getSContract(config.contract.DEXHI)
      .methods.GetAccountId(accountAddress)
      .call({ from: accountAddress });
    const balance = await getSContract(config.contract.DEX_ADMIN)
      .methods.getAvailable(tokenAddress, +accountId)
      .call({ from: accountAddress });
    return new BigNumber(balance);
  } catch (e) {
    return new BigNumber(0);
  }
};

const returnPRCode = (tokenAddress) => {
  let regex = "(0x[0]*)([0-9]*)"
  const condition = new RegExp(regex, 'g');
  return condition.exec(tokenAddress)[2]
}

export const getTokenBalanceNew = async (tokenAddress, accountAddress) => {
  try {
    const accountId = await getSContract(config.contract.DEXHI)
      .methods.GetAccountId(accountAddress)
      .call({ from: accountAddress });
    const balance = await getSContract(config.contract.DEX_ADMIN)
      .methods.getBalance([returnPRCode(tokenAddress)], +accountId)
      .call({ from: accountAddress });
    return [new BigNumber(balance[0]), new BigNumber(balance[1])];
  } catch (e) {
    return [new BigNumber(0), new BigNumber(0)];
  }
};

const recordsColumn = (a, n) => a.map(x => x[n]);

export const getDWRecords = (noOfRecords, accountAddress) => {
  return async (dispatch, getState) => {
    try {
      const accountId = await getSContract(config.contract.DEXHI)
        .methods.GetAccountId(accountAddress)
        .call({ from: accountAddress });
      const records = await getSContract(config.contract.DW)
        .methods.GetDWrecords(noOfRecords, +accountId)
        .call({ from: accountAddress });
      let depositWithdrawArray = [];
      const parsedRecords = Object.values(records).splice(
        Math.ceil(Object.keys(records).length / 2),
        Object.keys(records).length
      );

      //console.log(records)

      for (let i = 0; i < parsedRecords[0].length; i++) {
        depositWithdrawArray.push(recordsColumn(parsedRecords, i));
      }
      return depositWithdrawArray;
    } catch (e) {
      return null;
    }
  };
};

export const depositKLAY = (amount, TOAST) => {
  return async (dispatch, getState) => {
    const state = getState();
    //const KLAY = state.config.get('KLAY');
    const value = new BigNumber(amount).multipliedBy(Math.pow(10, 18)).toString();

    let params = {
      to: env.DEXHI,
      data: '0x4e47165a',
      value
    };

    try {
      const wallet = getSelectedAccountWallet(state);
      const transactionID = await wallet.sendTransaction(params);
      console.log(transactionID)
      //alert(`Depsoit KLAY request submitted`);
      dispatch(loadingActions("DEPOSIT_LOADING", false))
      // calling balance to update balance
      dispatch(loadTokens())
      toast.success(TOAST.DEPOSIT_KLAY_CREATED, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          background: '#6666ff !important',
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
      return;
    } catch (e) {
      //console.log(e)
      //alert(e);
      dispatch(loadingActions("DEPOSIT_LOADING", false))
      toast.error(e.toString(), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        className: css({
          textAlign: 'center',
          width: '280px',
          fontSize: '15px'
        })
      });
    }
    return null;
  };
};

export const getAllowance = async (tokenAddress, accountAddress) => {
  //const contract = getContract(tokenAddress, abi);
  //const allowance = await contract.allowance(accountAddress, env.HYDRO_PROXY_ADDRESS);
  return new BigNumber(0);
};

export const wrapETH = amount => {
  return async (dispatch, getState) => {
    const state = getState();
    const WETH = state.config.get('WETH');
    const value = new BigNumber(amount).multipliedBy(Math.pow(10, WETH.decimals)).toString();

    let params = {
      to: WETH.address,
      data: '0xd0e30db0',
      value
    };

    try {
      const wallet = getSelectedAccountWallet(state);
      const transactionID = await wallet.sendTransaction(params);

      alert(`Wrap ETH request submitted`);
      watchTransactionStatus(transactionID, async success => {
        if (success) {
          dispatch(watchToken(WETH.address, WETH.symbol, WETH.decimals));
          alert('Wrap ETH Successfully');
        } else {
          alert('Wrap ETH Failed');
        }
      });
      return transactionID;
    } catch (e) {
      alert(e);
    }
    return null;
  };
};

export const unwrapWETH = amount => {
  return async (dispatch, getState) => {
    const state = getState();
    const WETH = state.config.get('WETH');
    const value = new BigNumber(amount).multipliedBy(Math.pow(10, WETH.decimals)).toString(16);
    const wallet = getSelectedAccountWallet(state);
    const functionSelector = '2e1a7d4d';
    const valueString = get64BytesString(value);

    let params = {
      to: WETH.address,
      data: `0x${functionSelector}${valueString}`,
      value: 0
    };

    try {
      const transactionID = await wallet.sendTransaction(params);

      alert(`Unwrap WETH request submitted`);
      watchTransactionStatus(transactionID, async success => {
        if (success) {
          dispatch(watchToken(WETH.address, WETH.symbol, WETH.decimals));
          alert('Wrap ETH Successfully');
        } else {
          alert('Wrap ETH Failed');
        }
      });
      return transactionID;
    } catch (e) {
      alert(e);
    }
    return null;
  };
};

export const enable = (address, symbol, decimals) => {
  return async (dispatch, getState) => {
    let transactionID = await dispatch(
      approve(address, symbol, 'f000000000000000000000000000000000000000000000000000000000000000', 'Enable', decimals)
    );
    return transactionID;
  };
};

export const disable = (address, symbol, decimals) => {
  return async (dispatch, getState) => {
    let transactionID = await dispatch(
      approve(address, symbol, '0000000000000000000000000000000000000000000000000000000000000000', 'Disable', decimals)
    );
    return transactionID;
  };
};

export const approve = (tokenAddress, symbol, allowance, action, decimals) => {
  return async (dispatch, getState) => {
    const state = getState();
    const functionSelector = '095ea7b3';
    let spender = get64BytesString(env.HYDRO_PROXY_ADDRESS);
    if (spender.length !== 64) {
      return null;
    }

    let params = {
      to: tokenAddress,
      data: `0x${functionSelector}${spender}${allowance}`,
      value: 0
    };

    try {
      const wallet = getSelectedAccountWallet(state);
      const transactionID = await wallet.sendTransaction(params);

      alert(`${action} ${symbol} request submitted`);
      watchTransactionStatus(transactionID, async success => {
        if (success) {
          dispatch(watchToken(tokenAddress, symbol, decimals));
          alert(`${action} ${symbol} Successfully`);
        } else {
          alert(`${action} ${symbol} Failed`);
        }
      });
      return transactionID;
    } catch (e) {
      alert(e);
    }
    return null;
  };
};

const watchTransactionStatus = (txID, callback) => {
  const getTransactionStatus = async () => {
    const tx = await getTransactionReceipt(txID);
    if (!tx) {
      window.setTimeout(() => getTransactionStatus(txID), 3000);
    } else if (callback) {
      callback(Number(tx.status) === 1);
    } else {
      alert('success');
    }
  };
  window.setTimeout(() => getTransactionStatus(txID), 3000);
};

const get64BytesString = string => {
  string = string.replace('0x', '');
  while (string.length < 64) {
    string = '0'.concat(string);
  }
  return string;
};
