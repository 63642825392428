import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';

const mapStateToProps = state => {
  const langJson = state.language.langJson;
  return {
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class TermsOfUse extends React.PureComponent {


  renderTable = TERMS => {

    return <table>
      <thead>
        <tr>
          <th className="terms_type1">{TERMS.ARTICLE21_TABLE_TITLE1}</th>
          <th className="terms_type2">{TERMS.ARTICLE21_TABLE_TITLE2}</th>
          <th className="terms_type3">{TERMS.ARTICLE21_TABLE_TITLE3}</th>
          <th className="terms_type4">{TERMS.ARTICLE21_TABLE_TITLE4}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{TERMS.ARTICLE21_TABLE_CONT1}</td>
          <td dangerouslySetInnerHTML={{ __html: TERMS.ARTICLE21_TABLE_CONT2 }}></td>
          <td dangerouslySetInnerHTML={{ __html: TERMS.ARTICLE21_TABLE_CONT3 }}></td>
          <td>{TERMS.ARTICLE21_TABLE_CONT4}</td>
        </tr>
        <tr>
          <td>{TERMS.ARTICLE21_TABLE_CONT5}</td>
          <td dangerouslySetInnerHTML={{ __html: TERMS.ARTICLE21_TABLE_CONT6 }}></td>
          <td dangerouslySetInnerHTML={{ __html: TERMS.ARTICLE21_TABLE_CONT7 }}></td>
          <td>{TERMS.ARTICLE21_TABLE_CONT8}</td>
        </tr>
        <tr>
          <td>{TERMS.ARTICLE21_TABLE_CONT9}</td>
          <td>{TERMS.ARTICLE21_TABLE_CONT10}</td>
          <td dangerouslySetInnerHTML={{ __html: TERMS.ARTICLE21_TABLE_CONT11 }}></td>
          <td>{TERMS.ARTICLE21_TABLE_CONT12}</td>
        </tr>
      </tbody>
    </table>

  }

  renderList = (list, dangerouslySet) => {
    if (!dangerouslySet) {
      return <ul> {list.map(article => {
        return <li key={article}>{article}</li>
      })}
      </ul>
    } else return list.map(article => {
      return <li key={article}>{article}</li>
    })
  }

  renderDangerouslySetList = (list) => {
    return <ul> {list.map(article => {
      return <li dangerouslySetInnerHTML={{ __html: article }} key={article}></li>
    })} </ul>
  }

  goTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {

    const { langJson, isDarkMode } = this.props;
    const { TERMS } = langJson;
    return (
      <div style={{  background: `${isDarkMode? '#1c2b3a' : '#f9fbfd'} `, paddingTop: '50px' }}>
        <Terms>
          <Title>Terms &amp; Privacy</Title>

          <section className="terms" role="main">
            <div className="content">

              <h4>{TERMS.CHAPTER1_TITLE}</h4>
              <h5>{TERMS.ARTICLE1_TITLE}</h5>
              <p>{TERMS.ARTICLE1_TXT}</p>

              <h5>{TERMS.ARTICLE2_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE2_LIST)}

              <h5>{TERMS.ARTICLE3_TITLE}</h5>
              <p>{TERMS.ARTICLE3_TXT}</p>

              <h5>{TERMS.ARTICLE4_TITLE}</h5>
              <p>{TERMS.ARTICLE4_TXT}</p>
              {this.renderList(TERMS.ARTICLE4_LIST)}

              <h4>{TERMS.CHAPTER2_TITLE}</h4>
              <h5>{TERMS.ARTICLE5_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE5_LIST)}

              <h5>{TERMS.ARTICLE6_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE6_LIST)}

              <h5>{TERMS.ARTICLE7_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE7_LIST)}

              <h5>{TERMS.ARTICLE8_TITLE}</h5>
              <ul>
                {this.renderList(TERMS.ARTICLE8_LIST1, true)} {this.renderDangerouslySetList(TERMS.ARTICLE8_LIST2)}  <li>{TERMS.ARTICLE8_LIST3}</li> {this.renderDangerouslySetList(TERMS.ARTICLE8_LIST4)}
              </ul>

              <h5>{TERMS.ARTICLE9_TITLE}</h5>
              <ul>
                {this.renderList(TERMS.ARTICLE9_LIST1, true)}{this.renderDangerouslySetList(TERMS.ARTICLE9_LIST2)} <li>{TERMS.ARTICLE9_LIST3}</li> {this.renderDangerouslySetList(TERMS.ARTICLE9_LIST3_1)} <li>{TERMS.ARTICLE9_LIST4}</li> {this.renderDangerouslySetList(TERMS.ARTICLE9_LIST4_1)}
              </ul>

              <h5>{TERMS.ARTICLE10_TITLE}</h5>
              {this.renderDangerouslySetList(TERMS.ARTICLE10_LIST)}

              <h4>{TERMS.CHAPTER3_TITLE}</h4>

              <h5>{TERMS.ARTICLE11_TITLE}</h5>
              {this.renderDangerouslySetList(TERMS.ARTICLE11_LIST)}

              <h5>{TERMS.ARTICLE12_TITLE}</h5>
              <p>{TERMS.ARTICLE12_TXT}</p> {this.renderDangerouslySetList(TERMS.ARTICLE12_LIST)}


              <h5>{TERMS.ARTICLE13_TITLE}</h5>
              <p>{TERMS.ARTICLE13_TXT}</p>

              <h4>{TERMS.CHAPTER4_TITLE}</h4>
              <h5>{TERMS.ARTICLE14_TITLE}</h5>
              {this.renderDangerouslySetList(TERMS.ARTICLE14_LIST)}

              <h5>{TERMS.ARTICLE15_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE15_LIST)}

              <h5>{TERMS.ARTICLE16_TITLE}</h5>
              <ul>
                {this.renderList(TERMS.ARTICLE16_LIST1, true)}{this.renderDangerouslySetList(TERMS.ARTICLE16_LIST2)}{this.renderList(TERMS.ARTICLE16_LIST3, true)}
              </ul>

              <h5>{TERMS.ARTICLE17_TITLE}</h5>
              <ul>
                <li>{TERMS.ARTICLE17_LIST1}</li> {this.renderDangerouslySetList(TERMS.ARTICLE17_LIST2)}{this.renderList(TERMS.ARTICLE17_LIST3, true)}
              </ul>

              <h5>{TERMS.ARTICLE18_TITLE}</h5>
              <ul>
                <li>{TERMS.ARTICLE18_LIST1} </li> {this.renderDangerouslySetList(TERMS.ARTICLE18_LIST2)}{this.renderList(TERMS.ARTICLE18_LIST3, true)}
              </ul>

              <h5>{TERMS.ARTICLE19_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE19_LIST)}

              <h4>{TERMS.CHAPTER5_TITLE}</h4>
              <h5>{TERMS.ARTICLE20_TITLE}</h5>
              <ul>
                {this.renderList(TERMS.ARTICLE20_LIST1, true)}{this.renderDangerouslySetList(TERMS.ARTICLE20_LIST2)} <li>{TERMS.ARTICLE20_LIST3}</li>  {this.renderDangerouslySetList(TERMS.ARTICLE20_LIST4)} <li>{TERMS.ARTICLE20_LIST5}</li>
                <ul>
                  <li dangerouslySetInnerHTML={{ __html: TERMS.ARTICLE20_LIST5_1 }}></li>
                </ul>
              </ul>

              <h5>{TERMS.ARTICLE21_TITLE}</h5>
              {this.renderTable(TERMS)}{this.renderList(TERMS.ARTICLE21_LIST1)}

              <h5>{TERMS.ARTICLE22_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE22_LIST1)}

              <h4>{TERMS.CHAPTER6_TITLE}</h4>
              <h5>{TERMS.ARTICLE23_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE23_LIST1)}

              <h5>{TERMS.ARTICLE24_TITLE}</h5>
              <ul>
                {this.renderList(TERMS.ARTICLE24_LIST, true)}{this.renderDangerouslySetList(TERMS.ARTICLE24_LIST1)}
              </ul>

              <h4>{TERMS.CHAPTER7_TITLE}</h4>
              <h5>{TERMS.ARTICLE25_TITLE}</h5>
              <ul>
                {this.renderList(TERMS.ARTICLE25_LIST1, true)}{this.renderDangerouslySetList(TERMS.ARTICLE25_LIST2)}
              </ul>

              <h4>{TERMS.CHAPTER8_TITLE}</h4>
              <h5>{TERMS.ARTICLE26_TITLE}</h5>
              <ul>
                {this.renderList(TERMS.ARTICLE26_LIST1, true)} {this.renderDangerouslySetList(TERMS.ARTICLE26_LIST2)}{this.renderList(TERMS.ARTICLE26_LIST3, true)}
              </ul>

              <h5>{TERMS.ARTICLE27_TITLE}</h5>
              <ul>
                <li>{TERMS.ARTICLE27_LIST1}</li> {this.renderDangerouslySetList(TERMS.ARTICLE27_LIST2)}{this.renderList(TERMS.ARTICLE27_LIST3, true)}
              </ul>

              <h5>{TERMS.ARTICLE28_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE28_LIST)}

              <h5>{TERMS.ARTICLE29_TITLE}</h5>
              {this.renderList(TERMS.ARTICLE29_LIST)}

              <b>{TERMS.ATTACHMENT}</b>
              <p>{TERMS.ATTACHMENT_TXT}</p>
            </div>
          </section>
          <button className="topAnchor" onClick={this.goTop}>
            <i className="xi-caret-up"></i>
          </button>
        </Terms>
        <Footer />
      </div>
    );
  }
}

const Terms = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
  margin-bottom: 50px;
  background: #ffff;
  border: 1px solid #e2e6fa;
  .topAnchor {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: block;
    width: 40px;
    height: 40px;
    background: #036;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #fff;
  }
  .terms {
    width: 1100px;
    margin: 0 auto;
    .termsTitle {
      width: 100%;
      overflow: hidden;
      margin: 30px 0 30px;
      padding: 0 0 5px;
      h2 {
        font-family: 'Apple SD Gothic Neo', 'Malgun Gothic', sans-serif;
        font-size: 28px;
        font-weight: bold;
        color: #003366;
      }
    }
    .content {
      width: 100%;
      overflow: hidden;
      padding: 0 10px 20px;
      box-sizing: border-box;
      margin: 0 0 50px;
      h4 {
        font-size: 1.4rem;
        font-weight: 700;
        display: table;
        border-bottom: 1px solid #000;
        padding: 20px 0 0;
        margin: 0 0 15px;
      }
      h5 {
        font-size: 1.2rem;
        font-weight: 700;
        margin: 20px 0 10px;
      }
      p {
        font-size: 1rem;
        line-height: 1.6em;
        word-break: keep-all;
        margin: 0 0 8px;
        font-weight: 300;
      }
      ul {
        li {
          font-size: 1rem;
          line-height: 1.6em;
          margin: 0 0 15px;
          word-break: keep-all;
          ul {
            li {
              padding-left: 20px;
              line-height: 1.6em;
              margin: 3px 0;
              font-weight: 300;
            }
          }
        }
      }
      table {
        width: 100%;
        overflow: hidden;
        margin: 0 0 20px;
        tr {
          th {
            padding: 10px 0;
            border-left: 1px solid #e2e6fa !important;
            font-size: 16px;
            background: #f2f4fd;
            border-top: 3px solid #e2e6fa !important;
            border-bottom: 1px solid #e2e6fa !important;
            text-align: center;
            :first-child {
              border-left: none !important;
            }
          }
          td {
            padding: 10px 0;
            border-left: 1px solid #e2e6fa !important;
            font-size: 14px;
            text-align: left;
            border-bottom: 1px solid #e2e6fa !important;
            :first-child {
              border-left: none !important;
            }
          }
        }
      }
      .terms_type1 {
        width: 20%;
      }
      .terms_type2 {
        width: 40%;
      }
      .terms_type3 {
        width: 20%;
      }
      .terms_type4 {
        width: 20%;
      }
    }
  }
  @media (max-width: 1024px) {
    .terms {
      width: 100%;
      padding: 0 0.8rem;
      box-sizing: border-box;
      .termsTitle {
        width: 100%;
        border-bottom: 1px solid #003366;
        h2 {
          font-size: 1.6rem;
          font-weight: normal;
        }
      }
      .content {
        width: 100%;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        h4 {
          font-size: 1.3rem;
        }
        h5 {
          font-size: 1.2rem;
        }
        p {
          font-size: 1rem;
          line-height: 1.6em;
        }
        ul {
          li {
            font-size: 1rem;
            line-height: 1.6em;
            ul {
              li {
                padding-left: 20px;
                line-height: 1.6em;
              }
            }
          }
        }
        table {
          width: 100%;
          overflow: hidden;
          tr {
            th {
              font-size: 1rem;
            }
            td {
              font-size: 0.9rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;

const Title = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 64px;
  padding-top: 16px;
  line-height: 48px;
  color: #6666ff;
  border-bottom: 1px solid #e2e6fa;
`;

export default connect(mapStateToProps)(TermsOfUse);
