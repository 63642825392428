import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import { getSContract } from '../../lib/utils';
import { BigNumber } from 'bignumber.js';
import config from '../../lib/config';

const mapStateToProps = state => {
  const langJson = state.language.langJson;
  return {
    langJson,
    markets: state.market.getIn(['markets', 'data']).toArray(),
    isDarkMode: state.theme.isDarkMode
  };
};

class Fee extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      coinsList: []
    };
  }

  async componentDidMount() {
    const { markets } = this.props;
    window.scrollTo(0, 0);
    let localTokenUnique = {};
    let localTokens = [];
    for (let i = 0; i < markets.length; i++) {
      const market = markets[i];
      if (localTokenUnique[market.quoteToken]) {
        continue;
      }
      localTokenUnique[market.quoteToken] = true
      let prCode = this.returnPRCode(market.quoteTokenAddress)
      let fee = await this.getMinWithdrawFee( market.quoteTokenDecimals, prCode)
      localTokens.push({
        symbol: market.quoteToken,
        prCode: prCode,
        decimal: market.quoteTokenDecimals,
        fee: fee
      })
    }
  
    // then base tokens
    for (let i = 0; i < markets.length; i++) {
      const market = markets[i];
      if (localTokenUnique[market.baseToken]) {
        continue;
      }
      if (market.quoteToken !== 'BTC') {
        continue;
      }
      localTokenUnique[market.baseToken] = true
      let prCode = this.returnPRCode(market.baseTokenAddress)
      let fee = await this.getMinWithdrawFee( market.baseTokenDecimals, prCode)
      localTokens.push({
        symbol: market.baseToken,
        prCode: prCode,
        decimal: market.baseTokenDecimals,
        fee: fee
      })
    }

    this.setState({
      coinsList : localTokens
    })

  }

  returnPRCode = (tokenAddress) => {
    let regex = "(0x[0]*)([0-9]*)"
    const condition = new RegExp(regex, 'g');
    return condition.exec(tokenAddress)[2]
  }

  getMinWithdrawFee = async (decimals, prCode) => {
    const minWithdrawFee = await getSContract(config.contract.DEX_ADMIN).methods.GetMinWithdrawFee(prCode).call();
    return new BigNumber(minWithdrawFee).dividedBy(new BigNumber(Math.pow(10, decimals))).toString()
  }

  render() {
    const { langJson, isDarkMode } = this.props;
    const { FEE } = langJson;

    return (
      <div style={{  background: `${isDarkMode? '#1c2b3a' : '#f9fbfd'} `, paddingTop: '50px' }}>
        <Wrap>
          <Title>Fees</Title>
          <Label>{FEE.TITLE_01}</Label>
          <div className="guide">
            <p>{FEE.CONTENT_01_01}</p>
            <p dangerouslySetInnerHTML={{ __html: FEE.CONTENT_01_02 }} />
            <p dangerouslySetInnerHTML={{ __html: FEE.CONTENT_01_03 }} />
          </div>
          <Label>{FEE.TITLE_02}</Label>
          <div className="table_min">
            <table>
              <thead>
                <tr>
                  <th>Coin</th>
                  <th>Withdrawal Fee</th>
                </tr>
              </thead>

              {this.state.coinsList.map((item,index)=>{
                  return(
                    <tbody key={index}>
                    <tr className={index%2===0? "even" : "odd"}>
                      <td>{item.symbol}</td>
                      <td>{item.fee} {item.symbol}</td>
                    </tr>
                    </tbody>
                  )
              })}
            </table>
          </div>
        </Wrap>
        <Footer />
      </div>
    );
  }
}

const Wrap = styled.div`
  width: 1200px;
  height: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  box-sizing: border-box;
  padding: 0 50px;
  background: #fff;
  border: 1px solid #e2e6fa;
  .guide {
    font-size: 14px;
    margin-bottom: 40px;
    span {
      display: inline-block;
      width: 100px;
      color: #6666ff;
    }
    .left {
      width: 100px;
      display: inline-block;
      vertical-align: top;
    }
    .right {
      width: 800px;
      display: inline-block;
    }
    a {
      border-bottom: 1px solid black;
      height: 22px;
      font-weight: bold;
      display: inline-block;
    }
  }

  .odd {
    background: #f2f4fd;
  }

  .table_min {
    width: 70%;
    overflow-x: auto;
    table {
      width: 100%;
      margin: 20px 0 32px;
      th {
        padding: 10px 0;
        border-left: 1px solid #e2e6fa !important;
        font-size: 16px;
        background: #f2f4fd;
        border-top: 3px solid #e2e6fa !important;
        border-bottom: 1px solid #e2e6fa !important;
        text-align: center;
        :first-child {
          border-left: none !important;
        }
      }
      td {
        padding: 10px 0;
        border-left: 1px solid #e2e6fa !important;
        font-size: 14px;
        width: 50%;
        text-align: center;
        border-bottom: 1px solid #e2e6fa !important;
        :first-child {
          border-left: none !important;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    padding: 0 1rem;
    p {
      margin: 0.3rem 0;
    }
  }
`;

const Title = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 64px;
  padding-top: 16px;
  line-height: 48px;
  color: #6666ff;
  border-bottom: 1px solid #e2e6fa;
`;

const Label = styled.p`
  font-weight: bold;
`;

export default connect(mapStateToProps)(Fee);
