import env from '../../lib/env';

export default {
  EXCHANGE: env.HYDRO,
  BITGO: {
    BTC: 'BTC',
    ETH: 'ETH',
    XRP: 'XRP',
    BCH: 'BCH',
    LNC: 'LNC',
    LTC: 'LTC',
    BSV: 'BSV',
    ETC: 'ETC',
    ZEC: 'ZEC',
    DASH: 'DASH',
    BTG: 'BTG',
    TUSD: 'TUSD'
  },
  KLAY: {
    KLAY: 'KLAY'
  },
  ERC20: {
    KLAY_1: 'KLAY_1'
  },
  TRADE: {
    KLAY: '1',
    BTC: '1',
    ETH: '1',
    XRP: '1',
    BCH: '1',
    LNC: '1',
    LTC: '1',
    BSV: '1',
    ETC: '1',
    ZEC: '1',
    DASH: '1',
    BTG: '1',
    TUSD: '1'
  },
  DEPOSIT: {
    KLAY: '1',
    BTC: '1',
    ETH: '1',
    XRP: '1',
    BCH: '1',
    LNC: '1',
    LTC: '1',
    BSV: '1',
    ETC: '1',
    ZEC: '1',
    DASH: '1',
    BTG: '1',
    TUSD: '1'
  },
  WITHDRAW: {
    KLAY: '1',
    BTC: '1',
    ETH: '1',
    XRP: '1',
    BCH: '1',
    LNC: '1',
    LTC: '1',
    BSV: '1',
    ETC: '1',
    ZEC: '1',
    DASH: '1',
    BTG: '1',
    TUSD: '1'
  },
  IS_HOT: {
    XRP: 0
  },
  IS_NEW: {
    KLAY: 0
  },
  PR_CODE: {
    KLAY: '1',
    BTC: '2',
    ETH: '3',
    XRP: '4',
    BCH: '5',
    LNC: '6',
    LTC: '7',
    BSV: '8',
    ETC: '9',
    DASH: '10',
    ZEC: '11',
    BTG: '12',
    TUSD: '13'
  },
  TXN_ID: {
    KLAY: 'https://etherscan.io/tx/',
    BTC: 'https://btc.com/',
    ETH: 'https://etherscan.io/tx/',
    XRP: 'https://xrpcharts.ripple.com/#/transactions/',
    BCH: 'https://explorer.bitcoin.com/bch/tx/',
    LNC: 'https://etherscan.io/tx/',
    LTC: 'https://chainz.cryptoid.info/ltc/tx.dws?',
    BSV: 'https://blockchair.com/bitcoin-sv/',
    ETC: 'https://etcblockexplorer.com/home/',
    ZEC: 'https://explorer.zcha.in/transactions/',
    DASH: 'https://blockchair.com/dash/transaction/',
    BTG: 'https://btgexplorer.com/tx/',
    TUSD: 'https://etherscan.io/tx/'
  },
  CONSTANT: {
    APPEND: ''
  },
  EXTRA_PARAMS: {
    XRP: {
      SYMBOL: 'dt=',
      FIELD: 'Enter the Destination Tag'
    },
    EOS: {
      SYMBOL: 'memoId=',
      FIELD: 'Enter the Memo Id'
    }
  },
  DECIMALS: {
    KLAY: {
      DECIMAL: 18,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 2
    },
    BTC: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 4
    },
    BCH: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 4
    },
    ETH: {
      DECIMAL: 18,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    },
    XRP: {
      DECIMAL: 6,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    },
    LNC: {
      DECIMAL: 18,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 2
    },
    LTC: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    },
    BSV: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 4
    },
    ETC: {
      DECIMAL: 18,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    },
    ZEC: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    },
    DASH: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    },
    BTG: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    },
    TUSD: {
      DECIMAL: 8,
      UI_DECIMAL: 7,
      AMT_DECIMAL: 3
    }
  },
  FEES: {
    MAKER: '0',
    TAKER: '0.05',
    WITHDRAW: {
      KLAY: '0.01',
      BTC: '0.0001',
      ETH: '0.01',
      XRP: '1',
      BCH: '0.001',
      LNC: '1',
      LTC: '0.1',
      BSV: '0.001',
      ETC: '0.1',
      ZEC: '0.01',
      DASH: '0.01',
      BTG: '0.1',
      TUSD: '0.01'
    }
  },
  MIN_ORDER: {
    PRICE: {
      BTC: '0.0001',
      TUSD: '1'
    },
    AMOUNT: {
      KLAY: '0.01',
      BTC: '0.0001',
      ETH: '0.01',
      XRP: '1',
      BCH: '0.001',
      LNC: '1',
      LTC: '0.1',
      BSV: '0.001',
      ETC: '0.1',
      ZEC: '0.01',
      DASH: '0.01',
      BTG: '0.1',
      TUSD: '0.01'
    }
  },
  TICK: {
    PRICE: {
      BTC: '0.000001',
      TUSD: '0.001'
    },
    AMOUNT: {
      KLAY: '0.01',
      BTC: '0.0001',
      ETH: '0.01',
      XRP: '1',
      BCH: '0.001',
      LNC: '1',
      LTC: '0.1',
      BSV: '0.001',
      ETC: '0.1',
      ZEC: '0.01',
      DASH: '0.01',
      BTG: '0.1',
      TUSD: '0.01'
    }
  },
  MARKET_DECIMALS: {
    BTC: 7,
    TUSD: 3
  },
  TOKEN_DECIMALS: {
    KLAY: 8,
    BTC: 8,
    ETH: 8,
    XRP: 6,
    BCH: 8,
    LNC: 8,
    LTC: 8,
    BSV: 8,
    ETC: 8,
    ZEC: 8,
    DASH: 8,
    BTG: 8,
    TUSD: 8
  }
  // AMOUNT_DECIMALS: {
  //   KLAY: 8,
  //   BTC: 8,
  //   ETH: 8,
  //   XRP: 6,
  //   BCH: 8,
  //   LNC: 0,
  //   LTC: 8,
  //   BSV: 8,
  //   ETC: 8,
  //   ZEC: 8,
  //   DASH: 8,
  //   BTG: 8,
  //   TUSD: 8
  // }
};
