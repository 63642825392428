import React from "react";
import PropTypes from "prop-types";

import Modal from "react-bootstrap/Modal";
import { confirmable, createConfirmation } from "react-confirm";

class Confirmation extends React.Component {
  render() {
    const {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true,
      backgroundColor
    } = this.props;
    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={() => proceed(false)}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
        >
          <Modal.Header style={{ justifyContent: 'center', background:'#F1F4FD', fontWeight: 'bold', color: "blue" }}>
            <Modal.Title >{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{confirmation}</Modal.Body>
          <Modal.Footer>

            <div style={{ width: '20rem' }}>
              <button type="button" style={{ backgroundColor: '#ecf0f1', borderRadius: '0' }} className="btn btn-block" onClick={() => proceed(false)}>{cancelLabel}</button>
            </div>

            <div style={{ width: '20rem' }} >
              <button type="button" className="btn btn-block" style={{ height: '2.5rem', borderRadius: '0', background: backgroundColor ? backgroundColor  : '', color: '#fff' }}  onClick={() => proceed(true)} >{proceedLabel}</button>
            </div>

          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
  backgroundColor: PropTypes.bool
};

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}
