const loading = (
  state = {
    trade_loading: false,
    deposit_loading: false,
    withdraw_loading: false,
  },
  action
) => {
  switch (action.type) {
    case 'DEPOSIT_LOADING':
      return {
        ...state,
        deposit_loading: action.flag
      };
    case 'WITHDRAW_LOADING':
      return {
        ...state,
        withdraw_loading: action.flag
      };
    case 'TRADE_LOADING':
      return {
        ...state,
        trade_loading: action.flag
      };
    default:
      return state;
  }
};

export default loading;
