import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import TokensConfig from '../../assets/js/tokens';

const mapStateToProps = state => {
  const langJson = state.language.langJson;
  return {
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class Guide extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { langJson, isDarkMode } = this.props;
    const { GUIDE } = langJson;
    return (
      <div style={{ background: `${isDarkMode? '#1c2b3a' : '#f9fbfd'} `, paddingTop: '50px' }}>
        <Wrap>
          <Title>Guide</Title>
          <Label>{GUIDE.TITLE_01}</Label>
          <div className="guide">
            <div className="left">{GUIDE.CONTENT_ETH}</div>
            <div className="right" dangerouslySetInnerHTML={{ __html: GUIDE.CONTENT_01_01 }} />
          </div>
          <div className="guide">
            <div className="left">{GUIDE.CONTENT_LNC}</div>
            <div className="right" dangerouslySetInnerHTML={{ __html: GUIDE.CONTENT_01_02 }} />
          </div>
          <Label>{GUIDE.TITLE_02}</Label>
          <div className="guide">
            <div className="table_min">
              <table>
                <thead>
                  <tr>
                    <th>BTC MARKET</th>
                    <th>TUSD MARKET</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{TokensConfig.MIN_ORDER.PRICE.BTC} BTC</td>
                    <td>{TokensConfig.MIN_ORDER.PRICE.TUSD} TUSD</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Label>{GUIDE.TITLE_03}</Label>
          <div className="guide" dangerouslySetInnerHTML={{ __html: GUIDE.CONTENT_02 }} />
          <Label>{GUIDE.TITLE_04}</Label>
          <div className="guide" dangerouslySetInnerHTML={{ __html: GUIDE.CONTENT_03 }} />
          <Label>{GUIDE.TITLE_05}</Label>
          <div className="guide" dangerouslySetInnerHTML={{ __html: GUIDE.CONTENT_05 }} />
        </Wrap>
        <Footer />
      </div>
    );
  }
}

const Wrap = styled.div`
  width: 1200px;
  height: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  box-sizing: border-box;
  padding: 0 50px;
  background: #fff;
  border: 1px solid #e2e6fa;
  .guide {
    font-size: 14px;
    margin-bottom: 40px;
    .left {
      width: 100px;
      display: inline-block;
      vertical-align: top;
    }
    .right {
      width: 800px;
      display: inline-block;
    }
    button {
      border: 0;
      padding: 0;
      outline: 0;
      cursor: pointer;
      font-size: 14px;
      border-bottom: 1px solid black;
      height: 22px;
      font-weight: bold;
      background: #fff;
      display: inline-block;
    }
  }

  .table_min {
    width: 60%;
    overflow-x: auto;
    table {
      width: 100%;
      margin: 20px 0 12px;
      th {
        padding: 10px 0;
        border-left: 1px solid #e2e6fa !important;
        font-size: 16px;
        background: #f2f4fd;
        border-top: 3px solid #e2e6fa !important;
        border-bottom: 1px solid #e2e6fa !important;
        text-align: center;
        :first-child {
          border-left: none !important;
        }
      }
      td {
        padding: 10px 0;
        border-left: 1px solid #e2e6fa !important;
        font-size: 14px;
        text-align: center;
        border-bottom: 1px solid #e2e6fa !important;
        :first-child {
          border-left: none !important;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    .guide {
      .left,
      .right {
        width: 100%;
      }
      .left {
        margin: 0.5rem 0;
      }
      .right {
        br {
          display: none;
        }
      }
    }
  }
`;

const Title = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 64px;
  padding-top: 16px;
  line-height: 48px;
  color: #6666ff;
  border-bottom: 1px solid #e2e6fa;
`;

const Label = styled.p`
  font-weight: bold;
`;

export default connect(mapStateToProps)(Guide);
