// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCM4wU-ITkJHxGVoJVk3GX790a9I76737k",
    authDomain: "robo-75691.firebaseapp.com",
    projectId: "robo-75691",
    storageBucket: "robo-75691.appspot.com",
    messagingSenderId: "876913077896",
    appId: "1:876913077896:web:74fb47894aa7d225dd793d",
    measurementId: "G-VC30WJ8ESV"
  };

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
const { REACT_APP_VAPID_KEY } = process.env
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
    let currentToken = '';
    try {
        currentToken = await messaging.getToken({ vapidKey: publicKey });
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log('An error occurred while retrieving token.', error);
    }
    return currentToken;
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });