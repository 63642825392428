import React from 'react';
import styled from 'styled-components';
import { BigNumber } from 'bignumber.js';
import TokensConfig from '../../../assets/js/tokens';
import { thousands_separators } from '../../../lib/helper'
import { Link } from 'react-router-dom';

const PanelItem = props => {
  const { coin, currentPrice, percent, volume, isHot, isNew, trade, market, quoteToken, isDarkMode } = props;
  let tokenURL = TokensConfig.BITGO[coin]
    ? process.env.PUBLIC_URL + '/images/crypto_icons/' + coin.toLowerCase() + '.svg'
    : TokensConfig.KLAY[coin]
      ? process.env.PUBLIC_URL + '/images/klay.png'
      : process.env.PUBLIC_URL + '/images/garbage.png';

  if (coin === 'BSV') tokenURL = process.env.PUBLIC_URL + '/images/bsv.png';

  if (coin === 'LNC') tokenURL = process.env.PUBLIC_URL + '/images/lnc.png';

  return (
    <Link
    to={'/exchange'}>
    <Wrap
      dark={isDarkMode}
      trade={coin}
      isHot={isHot}
      isNew={isNew}
      onClick={() => {
        window.localStorage.setItem(`currentMarket`, JSON.stringify(market));
      }}>
      
      <div>
        <img className="custom-image" src={tokenURL} alt={trade} width='18px' height='18px' style={{ marginBottom: '6px', marginRight: '6px' }} />
        {TokensConfig.CONSTANT.APPEND}
        {coin}
      </div>

      <div>
        <span
  
          className={`${
            new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).gt(0)
              ? 'up'
              : new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).lt(0)
                ? 'down'
                : ''
            }`}>
          {currentPrice === '0' ? 0 : Number(currentPrice) >= 1000 ? thousands_separators(new BigNumber(currentPrice).toFixed(0)) :
            Number(currentPrice) >= 100 ? new BigNumber(currentPrice).toFixed(1) :
              new BigNumber(currentPrice).toFixed(TokensConfig.MARKET_DECIMALS[quoteToken])}
        </span>
      </div>

      <div>
        <span
          className={`${
            new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).gt(0)
              ? 'up'
              : new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).lt(0)
                ? 'down'
                : ''
            }`}>
          {new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).eq(0)
            ? '0.00'
            : new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).gt(0)
              ? '+' + Number(new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).toString()).toFixed(2)
              : Number(new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).toString()).toFixed(2)}{' '}
          %
        </span>
      </div>
      <div className="volume">
        <span  >{currentPrice === '0' ? 0 : new BigNumber(volume).multipliedBy(new BigNumber(currentPrice)).toFixed(TokensConfig.MARKET_DECIMALS[quoteToken])}</span>
      </div>
  
    </Wrap>
    </Link>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
  text-align: center;
  //border-bottom: 1px solid #d9d9d9;
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  cursor: pointer;
  background-color: ${({ dark }) => dark ? '#263554' : '#ffffff'};
  &:hover {
    background: ${({ dark }) => dark ? '#1c2c3a' : '#f2f4fd'}; //#eeeeee
  }
  .down {
    color: #5fa56f;
  }
  .up {
    color: #ec6856;
  }
  div {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    line-height: 77px;
    font-size: 18px;
    font-weight: 400;
    color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    text-align: center;
    
  }
  @media (max-width: 1024px) {
    height: 3rem;
    div {
      line-height: 3rem;
      font-size: 0.8rem;
      &.volume {
        display: none;
      }
      &.coin {
        background: none;
        display:flex
        justify-content: space-between;
        alignt-items:center
        margin: 0px 9vw 0px 0px;
        font-size: 0.8rem;
        p {
          font-size: 0.8rem;
          background-position-x: 1rem;
          background-size: 1rem;
        }
        .volume {
          display: none;
        }
       
      }
   
  
    }
  }
`;

export default PanelItem;
