import React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';

const InfoItem = props => {
  const { img, title, description, lang, isDarkMode } = props;
  return (
    <Wrap img={img} lang={lang} dark={isDarkMode}>
      <Card className="JHVSD">
        <div className="image" />
        <h5 className="fw-semi-bold  fs-5 mb-3 mt-4">{title}</h5>
        <p className="text-gray"> {description} </p>
      </Card>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 320px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  max-height: 240px;
  min-height: 240px;
  .JHVSD {
    // border-top: 5px solid #10d876!important;
    border-top: 5px solid  #525aee;
    padding: 30px;
    box-shadow: 0 24px 64px rgb(22 28 45 / 5%);
    border-radius: .25rem!important;
    border-left: 0px;
    border-bottom: 0px;
    border-right: 0px;
    text-align: center!important;
    justify-content: center!important;
    // height: 100%!important;
    max-height: 240px;
    min-height: 240px;
    background: ${({ dark }) => dark ? '#1c2b3a' : 'white'};
  }
  .fw-semi-bold {
    font-weight: 600;
    color:${({ dark }) => dark ? '#869ab8' : '#252525'};
  }
  .fs-5 {
    font-size: 1.25rem!important;
  }
  .mb-3 {
    margin-bottom: 1rem!important;
  }
  .mt-4 {
    margin-top: 1.5rem!important;
  }
  .text-gray {
    color: #869ab8;
    font-size: 16px;
  }
  .image {
    height: 70px;
    width: 70px;
    // margin-left: 95px;
    // margin-bottom: 15px;
    background: ${({ img }) => `url(${require(`../../../images/${img}`).default})`} no-repeat;
    background-size: 100%;
    margin: 0px 95px;
  }
  .content {
    height: ${({ lang }) => (lang === 'CH' ? '103px' : lang === 'EN' ? '150px' : '132px')};
    border-radius: 5px;
    //background: #f2f4fd;
    background: ${({ dark }) => dark ? '#15222f' : '#f2f4fd'};
    text-align: left;
    margin-bottom: 50px;
    box-sizing: border-box;
    padding: 12px 12px 12px 15px;
    .infoTitle {
      font-size: 18px;
      color: #6666ff;
      font-weight: 500;
    }
    .infoDescription {
      height: ${({ lang }) => (lang === 'CH' ? '72px' : lang === 'EN' ? '96px' : '84px')};
      margin-top: 10px;
      font-size: 15px;
      //color: #1a1a1a;
      color : ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
      line-height: 22px;
      font-weight: 500;
    }
  }
`;

export default InfoItem;
