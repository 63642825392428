import React from 'react';
import styled from 'styled-components';

const Label = () => {
  return (
    <Wrap>
      <div className="text">
      </div>
    </Wrap>
  );
};

const Wrap = styled.section`
    // width: 1200px;
    // margin: 0 auto;
    // height: 300px;
    background : url(${require('../../images/banner1.jpeg').default}) no-repeat;
    background-size: 100%;
    // background-size: cover;
    // background-position: 50%;
    // background-attachment: fixed;
    background-repeat: no-repeat;
    z-index: 0;
    padding-top: 180px;
    padding-bottom: 180px;
    .text {
        vertical-align: top;
        margin-top : 100px;
        margin-left : 70px;
        color : #ffffff;
        display: inline-block;
        width: 450px;
        box-sizing: border-box;
        .title {
            font-size : 50px;
            font-weight: bold;
            height: 50px;
            padding: 0;
            margin: 0;
        }
        .content {
            font-size: 21px;
            padding: 0;
            padding-top : 20px;
            font-weight: 400;
            margin: 0;
        }

        .button {
          height: 78px;
          width: 288px;
          margin-top : 230px;
          font-size : 24px;
          z-index : 2;
          font-weight: bold;
          margin-left:-16px;
          border-radius : 16px;
          border : 3px solid #AAB0D6;
          background-color : rgba(255, 255, 255, 0);
          color : #1A407D;
          outline: 0;
          cursor: pointer;
      }
    }
    
    .video {
        width: 627px;
        height: 298px;
        box-sizing: border-box;
        margin-right : 35px;
        margin-top : 50px;
        display: inline-block;
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0);
        }
    }
    
    @media(max-width: 1024px){
      width: 100%;
      background: none;
      height: auto;
      padding-top: 0px;
      padding-bottom: 0px;
      .text {
        width: 100%;
        margin-top : 0;
        margin-left : 0;
        padding : 0.7rem;
        height: 10rem;
        background : url(${require('../../images/banner1.jpeg').default}) no-repeat;
        background-size: 100%;
        .button {
            width : 9rem;
            font-size: 0.7rem;
            height: 2rem;
            margin : 6rem 2rem 0 0;
            margin-top : 6.5rem;
            z-index : 2;
            border-radius : 24px;
            border : 1px solid #AAB0D6;
            background-color : rgba(255, 255, 255, 0);
            color : #1A407D;
            outline: 0;
            cursor: pointer;
         
        }
    }
`;

export default Label;
