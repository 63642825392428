import React, { useEffect, useState } from "react";
import { getToken } from "../../firebaseInit";
import PropTypes from 'prop-types';
import axios from "axios";

Notifications.propTypes = {

};

function Notifications(props) {
    const [isTokenFound, setTokenFound] = useState(false);
    console.log("Token found", isTokenFound);
    // useEffect(() => {
    //     let data;
    //     async function tokenFunc() {
    //         data = await getToken(setTokenFound);
    //         if (data) {
    //             console.log("Token is", data);
    //             try {

    //                 let options = {
    //                     method: "POST",
    //                     body: JSON.stringify({
    //                         "token": data
    //                     }),
    //                     // mode: 'no-cors',
    //                     headers: {
    //                         "Content-Type": "application/json"
    //                     }
    //                 };

    //                 const subscribeInfo = await fetch("https://notify.exnomy.com/v1/subscribe", options);

    //                 console.log("subscribeInfo",subscribeInfo)

    //             } catch (err) {
    //                 console.log("subscribeInfoErr", err)

    //             }

    //         }
    //         return data;
    //     }
    //     tokenFunc();
    // }, [setTokenFound]);
    return <></>;
}

export default Notifications;