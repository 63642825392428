import BigNumber from 'bignumber.js';
import Caver from 'caver-js';
import env from './env';
import config from './config';

const ba = require('bitcoinaverage');

export const sleep = time => new Promise(r => setTimeout(r, time));

export const toUnitAmount = (amount, decimals) => {
  return new BigNumber(amount).div(Math.pow(10, decimals));
};

export const isTokenApproved = allowance => {
  return allowance.gt(10 ** 30);
};
export const getRESTclient = () => {
  var restClient = ba.restfulClient(env.PUBLIC_KEY, env.PRIVATE_KEY);
  return restClient;
};
export const getSContract = (contractN,key) => {
  const caver = new Caver(config.HttpProvider);
  const account = key ? caver.klay.accounts.wallet.add(key) : null
  console.log(account)
  let contract;
  switch (contractN) {
    case 1:
      contract = new caver.klay.Contract(config.abi.DEX_ADMIN, config.contractAddress.DEX_ADMIN, {
        gasPrice: '25000000000'
      });
      break;
    case 2:
      contract = new caver.klay.Contract(config.abi.DEXHI, config.contractAddress.DEXHI, { gasPrice: '25000000000' });
      break;
    case 3:
      contract = new caver.klay.Contract(config.abi.DW, config.contractAddress.DW, { gasPrice: '25000000000' });
      break;
    case 4:
      contract = new caver.klay.Contract(config.abi.Hydro, config.contractAddress.Hydro, { gasPrice: '25000000000' });
      break;
    default:
      break;
  }
  return contract;
};

export const getSContractWS = contractN => {
  const caver = new Caver(env.WS_ADDRESS);
  let contract;
  switch (contractN) {
    case 1:
      contract = new caver.klay.Contract(config.abi.DEX_ADMIN, config.contractAddress.DEX_ADMIN, {
        gasPrice: '25000000000'
      });
      break;
    case 2:
      contract = new caver.klay.Contract(config.abi.DEXHI, config.contractAddress.DEXHI, { gasPrice: '25000000000' });
      break;
    case 3:
      contract = new caver.klay.Contract(config.abi.DW, config.contractAddress.DW, { gasPrice: '25000000000' });
      break;
    case 4:
      contract = new caver.klay.Contract(config.abi.Hydro, config.contractAddress.Hydro, { gasPrice: '25000000000' });
      break;
    default:
      break;
  }
  return contract;
};
