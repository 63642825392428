import { dailyBinancePrice } from "./getBinancePrice";
import BigNumber from 'bignumber.js';
import moment from "moment";

export const updateLineChartdata = (price, signals, positions, dates, coinPrice, coinName, isShowSignal) => {

    const arrPrice = price.slice();
    const arrCoinPrice = coinPrice.slice();
    const arrSignal = signals.slice();
    const arrPositions = positions.slice();

    let barPosVal = arrPositions.map((val) => {
        // return val > 0 ? val : 0
        if (val > 0) {
            return val;
        }
    })

    let barNegVal = arrPositions.map((val) => {
        // return val < 0 ? val : 0
        if (val < 0) {
            return val;
        }

    })

    let lineChartData = {
        labels: dates,
        datasets: [
            {
                label: 'Asset Value',
                yAxisID: 'A',
                data: arrPrice,
                borderColor: "#5259ea",
                // pointBackgroundColor: "#5259ea",
                pointBorderColor: "#5259ea",
                pointHoverBackgroundColor: "grey",
                pointHoverBorderColor: "black",
                pointRadius: 2,
                pointBorderWidth: 0.5,
                pointHoverRadius: 8,
                fill: true,
                backgroundColor: "#fafcff"
            },
            {
                label: coinName + ' Price',
                yAxisID: 'A',
                data: arrCoinPrice,
                borderColor: "#b30e97",
                backgroundColor: "#e7b8ff",
                borderWidth: 1,
                pointBorderColor: "#b30e97",
                pointHoverBackgroundColor: "grey",
                pointHoverBorderColor: "black",
                pointRadius: 2,
                pointBorderWidth: 0.5,
                pointHoverRadius: 8,
                fill: false,
            },
            {
                // type: "bar",
                type: 'bubble',
                label: 'Buy Position',
                yAxisID: 'B',
                data: barPosVal,
                borderColor: "teal",
                backgroundColor: "#b4e0dc",
                // borderWidth: 1,
                fill: true,
                radius: 5
            },
            {
                // type: "bar",
                type: 'bubble',
                label: 'Sell Position',
                yAxisID: 'B',
                data: barNegVal,
                borderColor: "red",
                backgroundColor: "#ffd1d1",
                // borderWidth: 1,
                fill: true,
                radius: 5

            },
            {
                // type: "bar",
                label: 'Signal',
                yAxisID: 'B',
                data: arrSignal,
                borderColor: "orange",
                backgroundColor: "#ffead1",
                borderWidth: 1,
                // pointBackgroundColor: "white",
                // pointBorderColor: "orange",
                // pointHoverBackgroundColor: "grey",
                // pointHoverBorderColor: "black",
                // pointRadius: 1,
                // pointBorderWidth: 0.5,
                // pointHoverRadius: 8,
                fill: true,
                hidden: isShowSignal,
            },
        ],
    };

    let chartVals = price.sort((a, b) => a - b);
    let chartCoinVals = coinPrice.sort((a, b) => a - b);

    let checkGreaterProfit = Math.max(chartCoinVals[price.length - 1], chartVals[price.length - 1]);

    let checkGreaterLoss = Math.min(chartCoinVals[0], chartVals[0])

    let linePlotDiff = Math.round(Math.log10(checkGreaterProfit) - 0.5);

    linePlotDiff = linePlotDiff - 1;
    linePlotDiff = Math.pow(10, linePlotDiff);

    checkGreaterProfit = Math.ceil(checkGreaterProfit / linePlotDiff) * linePlotDiff;
    checkGreaterLoss = Math.floor(checkGreaterLoss / linePlotDiff) * linePlotDiff;

    let graphMin = null;

    graphMin = Math.ceil((checkGreaterLoss - (checkGreaterProfit - checkGreaterLoss) * 0.6) / linePlotDiff) * linePlotDiff;

    let newPlotDiff = ((checkGreaterProfit - graphMin) / 11 / linePlotDiff + 0.5) * linePlotDiff;

    newPlotDiff = Math.round(newPlotDiff / linePlotDiff) * linePlotDiff

    let graphMax = newPlotDiff * 11 + graphMin

    return {
        linePlotDiff: newPlotDiff, linePlotMinValue: graphMin, linePlotMaxValue: graphMax, lineChartData, arrPrice
    };
}

export const fetchLineGraphDetails = async (result, coinPair, triggers, isLive) => {

    let profitPercentage = 0, sharpRatio = null, dateArry = [], priceArry = [], signalArry = [], positionArry = [], coinPriceArry = [], tradeAmtArry = [], dateTableArry = [], priceTableArry = [], qtyTableArry = [], signalTableArry = [], positionTableArry = [], coinPriceTableArry = [], tradeAmtTableArry = [], newAssetValArry = null;

    let utcOffset = moment().utcOffset();

    if (result && result.blocktimes && result.directions && result.prices) {
        if (result.blocktimes.length) {
            let newPrice = result.prices;
            let newDirections = result.directions;
            let newBlocktimes = result.blocktimes;

            let revPrice = newPrice.slice().reverse();
            let revDirections = newDirections.slice().reverse();
            let revBlocktimes = newBlocktimes.slice().reverse();

            let newBlockTimes = [], firstTrade = [], lastTrade = [], prevPosInfo = null;
            revBlocktimes.map((val, key) => {
                // newBlockTimes.push({ date: moment(parseInt(val) * 1000).format("ll"), dateTime: moment(parseInt(val) * 1000).format("lll") })
                let dateObj = { date: moment.utc(parseInt(val) * 1000).add(utcOffset, "minutes").format("ll"), dateTime: moment.utc(parseInt(val) * 1000).add(utcOffset, "minutes").format("MM-DD-YYYY") };
              
                let prevDateObj = newBlockTimes[key - 1] ? newBlockTimes[key - 1] : null;

                let nextDateObj = revBlocktimes[key + 1] ? { date: moment.utc(parseInt(revBlocktimes[key + 1]) * 1000).add(utcOffset, "minutes").format("ll"), dateTime: moment.utc(parseInt(revBlocktimes[key + 1]) * 1000).add(utcOffset, "minutes").format("MM-DD-YYYY") } : null;

                newBlockTimes.push(dateObj);

                signalTableArry.push(parseInt(revDirections[key]) - 10);
                dateTableArry.push(moment.utc(parseInt(val) * 1000).add(utcOffset, "minutes").format("lll"))

                let powAmt = revPrice[key] / Math.pow(10, 18);

                tradeAmtTableArry.push(powAmt.toFixed(2));

                if (parseInt(revDirections[key]) >= parseInt(triggers.recBuyDirection)) {
                    positionTableArry.push(1)
                    prevPosInfo = parseInt(triggers.recBuyDirection);
                } else if (parseInt(revDirections[key]) <= parseInt(triggers.recSellDirection)) {
                    positionTableArry.push(-1)
                    prevPosInfo = parseInt(triggers.recSellDirection);
                } else if (positionTableArry[key - 1] == 1 && parseInt(revDirections[key]) <= parseInt(triggers.recBuyClearDirection)) {
                    positionTableArry.push(0)
                } else if (positionTableArry[key - 1] == -1 && parseInt(revDirections[key]) >= parseInt(triggers.recSellClearDirection)) {

                    positionTableArry.push(0)
                }
                else {

                    positionTableArry.push(positionTableArry[key - 1])
                }


                if (nextDateObj === null) {
                    lastTrade.push(0)
                } else if (dateObj.date === nextDateObj.date) {
                    lastTrade.push(0)
                } else {
                    lastTrade.push(1)
                }

                if (prevDateObj === null) {
                    firstTrade.push(1)
                } else if (dateObj.date === prevDateObj.date) {
                    firstTrade.push(0)
                } else {
                    firstTrade.push(1)
                }


            });
            let startingDate = moment.utc(parseInt(revBlocktimes[0]) * 1000).add(utcOffset, "minutes").format("ll");

            let endingDate = moment.utc((parseInt(revBlocktimes[revBlocktimes.length - 1]) + 1000000)* 1000).format("ll")
            // let endingDate = moment.utc(parseInt(revBlocktimes[revBlocktimes.length - 1]) * 1000).format("ll");

            const binancePrices = await dailyBinancePrice(coinPair, moment.utc(startingDate).startOf('day').unix(), moment.utc(endingDate).endOf('day').unix())

            let diffDay = moment.utc(endingDate).diff(startingDate, "days");

            let newPositionArry = positionTableArry;

            let newNavArry = [], intraDayProfit = [], pMult = 0;
            revPrice.map((val, key) => {
                let nVal = val / Math.pow(10, 18);

                if (key == 0) {
                    newNavArry.push(parseFloat(nVal.toFixed(2)));
                }

                else {
                    pMult = positionTableArry[key - 1] ? positionTableArry[key - 1] !== "-" ? positionTableArry[key - 1] : pMult : 0;
                    let pVal = newNavArry[key - 1];

                    let valCalc = pVal + pMult * (nVal - revPrice[key - 1] / Math.pow(10, 18));

                    newNavArry.push(parseFloat(valCalc.toFixed(2)));
                }

                if (newBlockTimes[key - 1] && newBlockTimes[key].date === newBlockTimes[key - 1].date) {
                    let pVal = newNavArry[key - 1] ? newNavArry[key - 1] : 0;
                    let pIntdayPrft = intraDayProfit[key - 1] ? intraDayProfit[key - 1] : 0;

                    intraDayProfit.push(newNavArry[key] - pVal + pIntdayPrft);
                } else {
                    intraDayProfit.push(0);
                }

            });

            newAssetValArry = newNavArry;

            let prevPosition = null, prevSignal = null, prevPrice = null, returnArry = [], lastTradePrice = [], lastNav = [], closeNav = [], pOccur = 0, prevPushedPos = null;

            for (let i = 0; i < diffDay; i++) {

                let nDate = moment.utc(startingDate).add(i, "days").add(utcOffset, "minutes").format("ll");

                let bPrice = binancePrices[i] ? binancePrices[i][4] : binancePrices[i - 1][4];

                dateArry.push(nDate);

                let timeOccur = [], dirOccur = [], priceOccur = [], tradeOccur = [], positionOccur = [], navArry = [];

                newBlockTimes.map((val, key) => {
                    if (val.date == nDate) {
                        timeOccur.push(val);
                        dirOccur.push(revDirections[key])
                        priceOccur.push(revPrice[key])
                        positionOccur.push(newPositionArry[key])
                        navArry.push(newNavArry[key])
                        tradeOccur.push(tradeAmtTableArry[key]);
                    }
                })

                if (timeOccur.length > 0) {
                    if (timeOccur.length > 1) {
                        const [lastTime] = timeOccur.slice(-1);
                        const [lastDir] = dirOccur.slice(-1);
                        const [lastPrice] = priceOccur.slice(-1);
                        const [lastPosition] = positionOccur.slice(-1);
                        const [lastNavAmt] = navArry.slice(-1);
                        const [lastTradeAmt] = tradeOccur.slice(-1);

                        let qty = 1
                        if (prevPosition == lastPosition) {
                            qty = qtyTableArry.slice(-1)
                        } else if (prevPosition == -1 && lastPosition == 1 || prevPosition == 1 && lastPosition == -1) {
                            qty = 2
                        }

                        prevPosition = lastPosition




                        prevSignal = parseInt(lastDir - 10);

                        let prevPowAmt = lastPrice / Math.pow(10, 18);

                        let profitCalc = newNavArry[pOccur + timeOccur.length - 1];
                        pOccur = pOccur + timeOccur.length;


                        prevPrice = profitCalc;

                        lastTradePrice.push(lastTradeAmt);
                        lastNav.push(lastNavAmt);

                        priceArry.push(profitCalc.toFixed(2));
                        signalArry.push(prevSignal);
                        positionArry.push(prevPosition);
                        coinPriceArry.push(parseFloat(bPrice).toFixed(2));
                        tradeAmtArry.push(prevPowAmt.toFixed(2))

                        qtyTableArry.push(qty);
                        priceTableArry.push(profitCalc.toFixed(2));
                        coinPriceTableArry.push(parseFloat(bPrice).toFixed(2));
                    }
                    else {

                        let qty = 1
                        if (prevPosition == positionOccur[0]) {
                            qty = qtyTableArry.slice(-1)
                        } else
                            if (prevPosition == -1 && positionOccur[0] == 1 || prevPosition == 1 && positionOccur[0] == -1) {
                                qty = 2
                            }


                        // if (positionOccur[0] == prevPosition) {
                        //     positionArry.push(0)
                        //     // prevPushedPos = 0

                        // } else {
                        //     positionArry.push(positionOccur[0])
                        //     prevPosition = positionOccur[0]

                        //     prevPushedPos = positionOccur[0]

                        // }
                        prevPosition = positionOccur[0]


                        // prevPosition = positionOccur[0] == prevPosition ? 0 : positionOccur[0];
                        // console.log("k1sk", prevPosition);

                        // prevPosition = positionOccur[0] == "-" ? prevPosition : positionOccur[0];
                        prevSignal = parseInt(dirOccur[0] - 10);

                        let prevPowAmt = priceOccur[0] / Math.pow(10, 18);

                        let profitCalc = newNavArry[pOccur];
                        pOccur++;


                        prevPrice = profitCalc;


                        lastTradePrice.push(tradeOccur[0]);
                        lastNav.push(navArry[0]);

                        priceArry.push(profitCalc.toFixed(2));
                        priceTableArry.push(profitCalc.toFixed(2));
                        signalArry.push(prevSignal);
                        positionArry.push(prevPosition);
                        coinPriceArry.push(parseFloat(bPrice).toFixed(2));
                        tradeAmtArry.push(prevPowAmt.toFixed(2))

                        qtyTableArry.push(qty);
                        coinPriceTableArry.push(parseFloat(bPrice).toFixed(2));

                    }
                } else if (isLive !== "live" && moment(newBlockTimes[newBlockTimes.length - 1].date).diff(moment(nDate), "days") < 1) {
                    priceArry.push(prevPrice.toFixed(2));
                    signalArry.push(0);
                    positionArry.push(0)

                    lastTradePrice.push(0);
                    lastNav.push(0);
                    coinPriceArry.push(parseFloat(bPrice).toFixed(2));
                }
                else {
                    tradeAmtArry.push("-")
                    let prevPowAmt = prevPrice;

                    let diff = parseFloat(bPrice) - binancePrices[i - 1][4]

                    let profitCalc = prevPowAmt + diff;


                    prevPrice = profitCalc;

                    // if (prevPushedPos == prevPosition) {
                    //     positionArry.push(0)
                    //     // prevPushedPos = 0

                    // } else {
                    //     positionArry.push(prevPosition)

                    //     prevPushedPos = prevPosition

                    // }

                    // if (moment(nDate).diff(moment.utc(parseInt(revBlocktimes[revBlocktimes.length - 1]) * 1000).format("ll"), 'days') <= 0) {
                    //     priceArry.push(profitCalc.toFixed(2));
                    // }

                    priceArry.push(profitCalc.toFixed(2));
                    signalArry.push(prevSignal);
                    positionArry.push(prevPosition)
                    lastTradePrice.push(0);
                    lastNav.push(0);
                    coinPriceArry.push(parseFloat(bPrice).toFixed(2));
                }

                if (parseFloat(lastTradePrice[i]) == 0) {
                    let prevBinancePrice = i == 0 ? 0 : parseFloat(binancePrices[i - 1][4]);
                    let currBinancePrice = parseFloat(bPrice);
                    let prevClosePrice = i == 0 ? 0 : parseFloat(closeNav[i - 1]);

                    let pA = positionArry[i] ? positionArry[i] : 0;
                    closeNav.push((prevClosePrice + pA * (currBinancePrice - prevBinancePrice)).toFixed(2))

                } else {

                    let currBinancePrice = parseFloat(bPrice);
                    let pA = positionArry[i] ? positionArry[i] : 0;

                    closeNav.push((lastNav[i] + pA * (currBinancePrice - lastTradePrice[i])).toFixed(2))


                }

                if (i > 0) {
                    returnArry.push(closeNav[i] / closeNav[i - 1] - 1);
                }


            }
            if (returnArry.length > 0) {

                const average = returnArry.reduce((p, c) => p + c, 0) / returnArry.length;

                const stdDev = getStandardDeviation(returnArry);

                sharpRatio = average / stdDev * Math.sqrt(365);
            }
            priceArry = closeNav;

            if (priceArry.length && newAssetValArry.length) {
                profitPercentage = calculatePercentage(parseFloat(newAssetValArry[0]), parseFloat(priceArry[priceArry.length - 1]))
            }
        }
    }

    return {
        profitPercentage: -profitPercentage, sharpRatio, dateArry: dateArry, priceArry: priceArry, signalArry: signalArry, positionArry: positionArry, coinPriceArry, tradeAmtArry, dateTableArry, priceTableArry, signalTableArry, positionTableArry, coinPriceTableArry, tradeAmtTableArry, qtyTableArry, newAssetValArry
    }
}

function getStandardDeviation(array) {
    const n = array.length
    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

function calculatePercentage(oldFigure, newFigure) {
    // let percentChange = ((oldFigure - newFigure) / oldFigure) * 100;
    // return Math.round(Math.abs(percentChange));
    // return 100 * Math.abs((oldFigure - newFigure) / ((oldFigure + newFigure) / 2));
    return 100 - (100 * Math.abs((newFigure / oldFigure)));
}