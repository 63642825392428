const trade = (
  state = {
    amount: '',
    price: '',
    side: ''
  },
  action
) => {
  switch (action.type) {
    case 'TRADE':
      return {
        amount: action.amount,
        price: action.price,
        side: action.side
      };
    default:
      return state;
  }
};

export default trade;
