import React from "react";
import klay from "./images_coins/klay.png";
import eth from "./images_coins/eth.png";
import etc from "./images_coins/etc.png";
import bch from "./images_coins/bch.png";
import lnc from "./images_coins/lnc.png";
import ltc from "./images_coins/ltc.png";
import bsv from "./images_coins/bsv.png";
import dash from "./images_coins/dash.png";
import btg from "./images_coins/btg.png";
import xrp from "./images_coins/xrp.png";
import zec from "./images_coins/zec.png";
import btc from "./images_coins/btc.png";
import tusd from "./images_coins/tusd.png";

const obj = {
  klay: klay,
  eth: eth,
  bch: bch,
  lnc: lnc,
  ltc: ltc,
  bsv: bsv,
  dash: dash,
  btg: btg,
  xrp: xrp,
  zec: zec,
  btc: btc,
  tusd: tusd,
};

const GetImage = ({ coin, width, height }) => {
  return (
    <img
      src={obj[coin]}
      width={width}
      height={height}
      style={{ marginRight: "1vh" }}
    />
  );
};

export default GetImage;
