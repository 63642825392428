import { List, Map } from "immutable";

export const initialState = Map({
  listData: Map(),
  expandedRows: List(),
  open: false,
  currentRow: List(),
  currentMarket: "",
});

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      state = state.set("open", true);
      state = state.set("currentRow", action.payload);
      return state;
    case "CLOSE_MODAL":
      state = state.set("open", false);
      state = state.set("currentRow", List());
      return state;
    case "UPDATE_EXTENDED_ROWS":
      state = state.set("expandedRows", List(action.payload));
      return state;
    case "UPDATE_LIST_DATA":
      state = state.set("listData", List(action.payload));
      return state;
    case "SET_CURRENT_MARKET":
      state = state.set("currentMarket", action.payload);
      return state;
    default:
      return state;
  }
};

export default infoReducer;
