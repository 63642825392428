import { getSContract } from "../smartcontract/utils";
import config from "./config";
import { getSelectedAccount, getSelectedAccountWallet } from "exnomy-wallet";
import Axios from "axios";
import qs from "qs";
import Web3 from "web3";
import Bignumber from "bignumber.js";
import getBinancePrice from "../../components/For_RoboAdvisoryDetails/getBinancePrice";
import { toast } from 'react-toastify';

export let web3, Contract;

// import { retrieveData } from '../phonestorage';

const recordsColumn = (a, n) => a.map((x) => x[n]);

export const getSignature = async (message) => {
  let auth;
  try {
    var data = qs.stringify({
      token: auth.signInUserSession.idToken.jwtToken,
      message: message,
    });

    var requestOptions = {
      url: "https://sign.exnomy.com/v1/SignMessage",
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      // redirect: 'follow'
    };

    const sign = await Axios(requestOptions);
    return sign.data;
  } catch (error) {
    return "Please Login";
  }
};

const sendTx = async (tx_builder, getState) => {
  try {
    const caver = new Web3(config.HttpProvider);
    const state = getState();
    console.log(state.WalletReducer);
    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get("address") : null;
    const wallet = getSelectedAccountWallet(state);

    let encoded_tx = tx_builder.encodeABI();
    let transactionObject;
    // if(getSelectedAccountWallet(state).ethereum){
    //   transactionObject= {
    //      gas: "50000",
    //     data: encoded_tx,
    //     to: config.contractAddress.DEXHI,
    //      from:address
    //   };
    // }
    // else{
    //   transactionObject= {
    //     // gas: "3000000",
    //    data: encoded_tx,
    //    to: config.contractAddress.DEXHI,
    //     // from:address
    //  };

    // }

    //ledgerr
    // transactionObject= { chainId: 8217, nonce:0, gasPrice: 25000000000, gasLimit: 300000, from: address, to: config.contractAddress.Robo, value: 0, data: encoded_tx}

    transactionObject = {
      gasPrice: 25000000000,
      gasLimit: 3000000,
      data: encoded_tx,
      to: config.contractAddress.Robo,
      value: 0,
    };

    // const sign = await wallet.sign(transactionObject)
    // console.log(sign)

    // const res = await caver.eth.sendSignedTransaction(sign)



    let res = null;

    if (selectedAccount) {
      res = await selectedAccount
        .get("wallet")
        .sendTransaction(transactionObject);

      // toast.info("Succesfully! saved");
    } else {
      // toast.error("Failed! Invalid wallet amount or login");
      // window.location.reload();
    }

    toast.info("Succesfully! saved");



    if (res) {
      // window.location.reload();
      console.log("signature", res);
    }

    return;
  } catch (error) {
    console.log("signature", error);
    throw error;
  }
};

export const addStratergy = (depositData) => {
  return async (dispatch, getState) => {
    // try {
      console.log(depositData);
      const { stCode, prCode1, prCode2 } = depositData;
      let tx_builder = getSContract(config.contract.Robo).methods.AddStrategy(
        stCode,
        prCode1,
        prCode2
      );
      await sendTx(tx_builder, getState);
      return "Deposit Successfull";
    // } catch (e) {
    //   throw e;
    // }
  };
};

export const getAllStrategyByPrCode = (data) => {
  return async (dispatch, getState) => {
  //   try {
      const accountAddress = "";
      const { minRate, idFrom, idTo, onlySubsribed, prCode1, prCode2 } = data;
      const result = await getSContract(config.contract.Robo)
        .methods.GetAllStrategyByPrCode(
          minRate,
          idFrom,
          idTo,
          onlySubsribed,
          prCode1,
          prCode2
        )
        .call({ from: accountAddress });
      console.log(result);
      return result;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getlastStrategyId = () => {
  return async (dispatch, getState) => {
  //   try {
      const result = await getSContract(config.contract.Robo)
        .methods.lastStrategyId()
        .call();
      return result;
  //   } catch (error) {
  //     throw error;
  //   }
  };
};

export const getDWRecords = (noOfRecords) => {
  return async (dispatch, getState) => {
    // try {
      const accountAddress = "";

      console.log("addres", accountAddress);
      const accountId = await getSContract(config.contract.DEXHI)
        .methods.GetAccountId(accountAddress)
        .call({ from: accountAddress });
      const records = await getSContract(config.contract.DW)
        .methods.GetDWrecords(noOfRecords, +accountId)
        .call({ from: accountAddress });

      let depositWithdrawArray = [];
      const parsedRecords = Object.values(records).splice(
        Math.ceil(Object.keys(records).length / 2),
        Object.keys(records).length
      );

      for (let i = 0; i < parsedRecords[0].length; i++) {
        depositWithdrawArray.push(recordsColumn(parsedRecords, i));
      }

      return depositWithdrawArray;
    // } catch (e) {
    //   throw e;
    // }
  };
};

export const getPrCode = (index) => {
  return async (dispatch, getState) => {
    // try {
      const prCode = await getSContract(config.contract.Robo)
        .methods.prCode_prName(index)
        .call();
      return prCode;
    // } catch (error) {
    //   throw error;
    // }
  };
};
export const getLastProdcutId = () => {
  return async (dispatch, getState) => {
    // try {
      const lastProduct = await getSContract(config.contract.Robo)
        .methods.lastProductId()
        .call();
      return lastProduct;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getStrategyName = (data) => {
  return async (dispatch, getState) => {
    const { id } = data
    // try {
      const recData = await getSContract(config.contract.Robo)
        .methods.id_stCode(id)
        .call();
      return recData;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getStrategyOwner = (data) => {
  return async (dispatch, getState) => {
    const { id } = data
    // try {
      const recData = await getSContract(config.contract.Robo)
        .methods.id_operator(id)
        .call();
      return recData;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getOperatorLevel = (data) => {
  return async (dispatch, getState) => {
    const { address } = data
    // try {
      const recData = await getSContract(config.contract.Robo)
        .methods.Operator_Level(address)
        .call();
      console.log("recData", recData)
      return recData;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getRecDirection = (data) => {
  return async (dispatch, getState) => {
    const { id } = data
    // try {
      const recDirection = await getSContract(config.contract.Robo)
        .methods.GetRecDirection(id)
        .call();
      return recDirection;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getAdvisorList = () => {
  return async (dispatch, getState) => {
    // try {
      const recAdvisor = await getSContract(config.contract.DEX_ADMIN)
        .methods.GetOperatorList()
        .call();
      return recAdvisor;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getProductList = () => {
  return async (dispatch, getState) => {
    // try {
      console.log(Date.now())
      const productlist = []
      const lastProduct = await dispatch(getLastProdcutId())
      console.log("last", lastProduct)
      const quoteToken1 = await dispatch(getPrCode(1))
      const quoteToken2 = await dispatch(getPrCode(2))


      for (let index = 2; index <= lastProduct; index++) {
        const baseToken = await dispatch(getPrCode(index))
        const obj = {
          baseToken: baseToken,
          baseTokenCode: index,
          quoteToken: quoteToken1,
          quoteTokenCode: 1
        }
        if (index > 2) {
          const obj = {
            baseToken: baseToken,
            baseTokenCode: index,
            quoteToken: quoteToken2,
            quoteTokenCode: 2
          }
          productlist.push(obj)
        }
        productlist.push(obj)
      }
      return productlist;
    // } catch (e) {
    //   throw e;
    // }
  };
};

export const setPrice = (data) => {
  return async (dispatch, getState) => {
    let { id, blocktime, price } = data;
    price = new Bignumber(price).multipliedBy(Math.pow(10, 18));
    // try {
      let tx_builder = getSContract(config.contract.Robo).methods.SetPrice(
        id,
        blocktime,
        price
      );
      await sendTx(tx_builder, getState);
      return;
    // } catch (e) {
    //   throw e;
    // }
  };
};

export const updateAllPrice = () => {
  return async (dispatch, getState) => {
    const lastId = await dispatch(getlastStrategyId());
    // try {
      const allStartergy = await dispatch(
        getAllStrategy({
          minRate: 0,
          idFrom: 0,
          idTo: lastId,
          onlySubsribed: false,
        })
      );
      console.log(allStartergy[0]);
      allStartergy.ids.map(async (id) => {
        const res = await dispatch(getDecision({ id: id }));
        console.log(res);
        res.blocktimes.map(async (block) => {
          const price = await getBinancePrice("", block - 60, block);

          console.log(
            id,
            ":",
            block,
            ":",
            price[0] ? returnPrice(price[0][4]).toString(10) : null
          );
          // if(id!==5){
          //  const res = await dispatch(setPrice({id:id,blocktime:block,price:price[0][4]}))
          //  console.log(res)

          // }

          // dispatch(setPrice({id:id,blocktime:block,price:57000}))
        });
      });
      return;
    // } catch (e) {
    //   throw e;
    // }
  };
};

const returnPrice = (price) => {
  return new Bignumber(price).multipliedBy(Math.pow(10, 18));
};

export const setRecDirection = (data) => {
  return async (dispatch, getState) => {
    // try {
      const {
        id,
        recBuyDirection,
        recBuyClearDirection,
        recSellDirection,
        recSellClearDirection,
      } = data;
      const tx_builder = getSContract(
        config.contract.Robo
      ).methods.SetRecDirection(
        id,
        recBuyDirection,
        recBuyClearDirection,
        recSellDirection,
        recSellClearDirection
      );
      await sendTx(tx_builder, getState);
      return "Success";
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const addDecision = (data) => {
  return async (dispatch, getState) => {
    // try {
      const blocktime = parseInt(Date.now() / 1000);
      const { id, direction, coinPair } = data;

      const tx_builder = getSContract(config.contract.Robo).methods.AddDecision(
        id,
        direction,
        blocktime
      );

      const price = await getBinancePrice(coinPair, blocktime, blocktime);

      const priceData = { id, blocktime, price: price[0] ? returnPrice(price[0][4]).toString(10) : null };

      dispatch(setPrice(priceData))

      await sendTx(tx_builder, getState);

      // alert("Done")
      return "decision successfully submitted!";
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const setStrategyType = (data) => {
  return async (dispatch, getState) => {
    // try {
      const { id, isRestricted } = data;
      const tx_builder = getSContract(
        config.contract.Robo
      ).methods.SetStrategyType(id, isRestricted);
      await sendTx(tx_builder, getState);
      return "set strategy type success!";
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const setRate = (data) => {
  return async (dispatch, getState) => {
    // try {
      let { id, rate } = data;
      const tx_builder = getSContract(
        config.contract.Robo
      ).methods.SetRate(id, Number(rate));
      console.log(id, Number(rate))
      await sendTx(tx_builder, getState);
      return "set rate success!";
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getLastDecision = (data) => async (dispatch, getState) => {
  // try {
    const accountAddress = "";
    const { id } = data;
    const result = await getSContract(config.contract.Robo)
      .methods.GetLastDecision(id)
      .call({ from: accountAddress });
    return result;
  // } catch (error) {
  //   throw error;
  // }
};

export const getAllStrategyByPrCodeAndOwner = (data) => {
  return async (dispatch, getState) => {
    // try {
      const accountAddress = "";
      const {
        minRate,
        idFrom,
        idTo,
        onlySubsribed,
        prCode1,
        prCode2,
        owner,
      } = data;
      const res = await getSContract(config.contract.Robo)
        .methods.GetAllStrategyByPrCodeAndOwner(
          minRate,
          idFrom,
          idTo,
          onlySubsribed,
          prCode1,
          prCode2,
          owner
        )
        .call({ from: accountAddress });
      return res;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getDecision = (data) => {
  return async (dispatch, getState) => {
    // try {
      const accountAddress = "";
      const { id } = data;
      const result = await getSContract(config.contract.Robo)
        .methods.GetDecisions(id)
        .call({ from: accountAddress });
      return result;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getTestDecision = (data) => {
  return async (dispatch, getState) => {
    // try {
      const accountAddress = "";
      const { id } = data;
      const result = await getSContract(config.contract.Robo)
        .methods.GetTestDecisions(id)
        .call({ from: accountAddress });
      return result;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getSharpRatioRange = (data) => {
  return async (dispatch, getState) => {
    // try {
      const accountAddress = "";
      const result = await getSContract(config.contract.Robo)
        .methods.sharpRatioRange()
        .call();
        
      return result;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getAllClients = (data) => {
  return async (dispatch, getState) => {
    // try {
      const accountAddress = "";
      const { id, idFrom, idTo, onlySubsribed } = data;
      const res = await getSContract(config.contract.Robo)
        .methods.GetAllClients(id, idFrom, idTo, onlySubsribed)
        .call({ from: accountAddress });
      return res;
    // } catch (error) {
    //   throw error;
    // }
  };
};

export const getAllStrategy = (data) => {
  return async (dispatch, getState) => {
    // try {
      const accountAddress = "";
      const { minRate, idFrom, idTo, onlySubsribed } = data;
      const result = await getSContract(config.contract.Robo)
        .methods.GetAllStrategy(minRate, idFrom, idTo, onlySubsribed)
        .call({ from: accountAddress });
      return result;
    // } catch (error) {
    //   throw error;
    // }
  };
};
