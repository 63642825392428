import React from 'react';
import { connect } from 'react-redux';
import { toUnitAmount } from '../../lib/utils';
import { stateUtils } from '../../selectors/account';
import { getSelectedAccount } from 'exnomy-wallet';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { getDWRecords } from '../../lib/wallet';
import TokensConfig from '../../assets/js/tokens';
import HistoryItem from './HistoryItem';

const mapStateToProps = state => {
  const DepositAddressInfo = state.DepositAddress;
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  const langJson = state.language.langJson;
  return {
    tokensInfo: stateUtils.getTokensInfo(state, address),
    address,
    DepositAddressInfo,
    ethBalance: toUnitAmount(state.WalletReducer.getIn(['accounts', selectedAccountID, 'balance']), 18),
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class DepositWithdrawHistory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      history: []
    };
  }

  componentDidMount() {
    const { address, dispatch, Token } = this.props;
    if (address) {
      dispatch(getDWRecords(100, address)).then(res => {
        let historyLocal = [];
        res.map(resItem => {
          if (TokensConfig.PR_CODE[Token] === resItem[0]) {
            //if (resItem[6] !== '0x') 
              historyLocal.push(resItem);
          }
          return null
        });
        this.setState({
          history: historyLocal
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { address, dispatch, Token } = this.props;

    if ((Token && Token !== prevProps.Token) || (address && address !== prevProps.address)) {
      this.setState({
        history: []
      });
      if (address) {
        dispatch(getDWRecords(100, address)).then(res => {
          let historyLocal = [];
          res.map(resItem => {
            if (TokensConfig.PR_CODE[Token] === resItem[0]) {
              //if (resItem[6] !== '0x') 
              historyLocal.push(resItem);
            }
            return null
          });
          this.setState({
            history: historyLocal
          });
        });
      }
    }
  }

  render() {
    const { Token, decimals, langJson, isDarkMode } = this.props;
    const { history } = this.state;
    const { DW, HISTORY } = langJson;
    console.log(history)
    return (
      <Wrap1>
        <Header dark={isDarkMode}>
          <div className="history-type">{DW.TYPE}</div>
          <div className="history-amount">{`${DW.AMOUNT}(${Token})`}</div>
          <div className="history-time">{DW.TIME}</div>
          <div className="history-status">{HISTORY.STATUS}</div>
          <div className="history-txn">TXID</div>
        </Header>
        <Content>
          {history.length > 0 ? (
            history.map((historyItem, index) => {
              return (
                <HistoryItem key={index} data={historyItem} decimals={decimals} langJson={langJson} Token={Token} index={index} isDarkMode={isDarkMode} />
              );
            })
          ) : (
            <div className="empty">
              <span>{DW.NO_DATA}</span>
            </div>
          )}
        </Content>
      </Wrap1>
    );
  }
}

const Wrap1 = styled.div`
  width: 450px;
    @media (max-width: 1024px) {
    width:100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 44px;
  width: 100%;
  color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
  border-bottom: 1px solid ${({dark}) => dark ? '#52576f' : '#e2e6fa'}

  div {
    font-size: 12px;
  }

  .history-type {
    width: 15%;
    text-align: center;
  }
  .history-amount {
    width: 25%;
    text-align: center;
  }
  .history-time {
    width: 25%;
    text-align: center;
  }
  .history-status {
    width: 20%;
    text-align: center;
  }
  .history-txn {
    width: 15%;
    text-align: center;
  }
`;

const Content = styled.div`
  height: 100%;
  max-height: 596px
  overflow-y: auto;

  .empty {
    width: 100%;
    height: 372px;
    line-height: 428px;
    text-align: center;
    background: url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/empty_icon.png) no-repeat center;
    span {
      top: 60%;
      font-size: 10.5px;
      color: #9e9e9e;
    }
  }
`;

export default connect(mapStateToProps)(DepositWithdrawHistory);
