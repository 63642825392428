import React from 'react';
import { connect } from 'react-redux';
import { loadMarkets, loadTradeHistory } from '../actions/markets';
import Header from '../components/Header';
import WebsocketConnector from '../components/WebsocketConnector';
import env from '../lib/env';
import { Wallet as SDKWallet, signOut } from 'exnomy-wallet';
import 'exnomy-wallet/index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { AppRoute } from './Routes'
import { hot } from 'react-hot-loader/root';
// import Notice from '../components/For_Dashboard/Notice';

import { onMessageListener } from "../firebaseInit"
import Notifications from './../components/Notifications/Notifications';
import ReactNotificationComponent from './../components/Notifications/ReactNotification';


const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  return {
    selectedAccountID,
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
  };
};

//remove all consoles from site
// console.log = function () { };

class App extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      mobileTab: 'trade',
      show: false,
      notification: {}
    };
  }

  // Things to do before unloading/closing the tab
  doSomethingBeforeUnload() {
    // Do something
    localStorage.setItem("loginData-PreviousSession", Date.now());
  }

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener() {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.doSomethingBeforeUnload();
    });
  }

  componentDidMount() {

    onMessageListener()
      .then((payload) => {
        this.setState({
          show: !this.state.show,
          notification: {
            title: payload.notification.title,
            body: payload.notification.body,
          }
        });
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));

    const { dispatch, currentMarket } = this.props;

    if (!localStorage.getItem("loginData-PreviousSession")) {
      signOut();
    } else {
      const millis = Date.now() - localStorage.getItem("loginData-PreviousSession");
      const minutesElapsed = Math.floor(millis / (1000 * 60))
      if (minutesElapsed >= 15) {
        signOut();
      }
    }

    // dispatch(loadMarkets());
    if (parseInt(env.NETWORK_ID) === 66) {
      this.initTestBrowserWallet();
    }
    if (currentMarket) {
      // dispatch(loadTradeHistory(currentMarket.id));
    }

    this.setupBeforeUnloadListener();
  }

  componentDidUpdate(prevProps) {
    const { currentMarket, dispatch } = this.props;
    if (currentMarket !== prevProps.currentMarket) {
      // dispatch(loadTradeHistory(currentMarket.id));
    }
  }

  render() {
    return (
      <div className='app'>
        {/* {this.state.show ? (
          <ReactNotificationComponent
            title={this.state.notification.title}
            body={this.state.notification.body}
          />
        ) : (
          <></>
        )}
        <Notifications /> */}
        <MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </div>
    );
  }

  renderMobile() {
    const { currentMarket } = this.props;
    let search = window.location.search;
    let params = new URLSearchParams(search)
    let platform = params.get('platform')
    // if (!currentMarket) {
    //   return null;
    // }
    return (
      <>
        <Router>
          <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css" />
          <SDKWallet title="Starter Kit Wallet" nodeUrl={env.NODE_URL} defaultWalletType="Hydro-Wallet" />
          <WebsocketConnector />
          {/* {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
            <span className="network-warning bg-warning text-white text-center" style={{ padding: 4 }}>
              Network Error: Switch Metamask's network to - {this.getNetworkName()}.
            </span>
          )} */}
          {platform !== 'mobile' && <Header />}

          <ContentWrap>
            <AppRoute />
          </ContentWrap>
          <ToastContainer />
        </Router>
      </>
    );
    //return <img class="responsive" src={require('../images/mobile.png')} alt="exnomy" style={{ maxWidth: '400px' }} />;
  }

  renderTablet() {
    const { currentMarket } = this.props;
    let search = window.location.search;
    let params = new URLSearchParams(search)
    let platform = params.get('platform')
    // if (!currentMarket) {
    //   return null;
    // }
    return (
      <>
        <Router>
          <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css" />
          <SDKWallet title="Starter Kit Wallet" nodeUrl={env.NODE_URL} defaultWalletType="Hydro-Wallet" />
          <WebsocketConnector />
          {/* {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
            <span className="network-warning bg-warning text-white text-center" style={{ padding: 4 }}>
              Network Error: Switch Metamask's network to - {this.getNetworkName()}.
            </span>
          )} */}
          {platform !== 'mobile' && <Header />}

          <ContentWrap>
            <AppRoute />
          </ContentWrap>
          <ToastContainer />
        </Router>
      </>
    );
    //return <img src={require('../images/mobile.png')} alt="exnomy" style={{ maxWidth: '600px' }} />;
  }

  renderLaptop() {
    // const { currentMarket } = this.props;
    // if (!currentMarket) {
    //   return null;
    // }
    return (
      <>
        <Router>
          <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css" />
          <SDKWallet title="Starter Kit Wallet" nodeUrl={env.NODE_URL} defaultWalletType="Hydro-Wallet" />
          <WebsocketConnector />
          {/* {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
            <span className="network-warning bg-warning text-white text-center" style={{ padding: 4 }}>
              Network Error: Switch Metamask's network to - {this.getNetworkName()}.
            </span>
          )} */}
          <Header />
          {/* <Notice/> */}
          <ContentWrap>
            <AppRoute />
          </ContentWrap>
          <ToastContainer />
        </Router>
      </>
    );
  }

  renderDesktop() {
    // const { currentMarket } = this.props;
    // if (!currentMarket) {
    //   return null;
    // }
    return (
      <>
        <Router>
          <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css" />
          <SDKWallet title="Starter Kit Wallet" nodeUrl={env.NODE_URL} defaultWalletType="Hydro-Wallet" />
          <WebsocketConnector />
          {/* {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
            <span className="network-warning bg-warning text-white text-center" style={{ padding: 4 }}>
              Network Error: Switch Metamask's network to - {this.getNetworkName()}.
            </span>
          )} */}
          <Header />
          {/* <Notice/> */}
          <ContentWrap>
            <AppRoute />
          </ContentWrap>
          <ToastContainer />
        </Router>
      </>
    );
  }

  getNetworkName() {
    switch (parseInt(env.NETWORK_ID, 10)) {
      case 1:
        return 'Mainnet';
      case 3:
        return 'Ropsten';
      case 4:
        return 'Rinkeby';
      case 1001:
        return 'Baobab';
      case 9287:
        return 'Cypress';
      case 66:
        return 'localhost:8545';
      default:
        return 'id: ' + env.NETWORK_ID;
    }
  }
}

const ContentWrap = styled.div``;

const HotApp = hot(App)

export default connect(mapStateToProps)(HotApp);
