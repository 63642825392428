import React from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import TokensConfig from '../../assets/js/tokens';
import format from 'date-format';
import KR from '../../assets/json/kr.json';
import EN from '../../assets/json/en.json';
import CH from '../../assets/json/ch.json';

const parseTransactionHash = (hash, Token) => {
  if(hash === '0x')
    return '-NA-'

  if(Token === 'ETH' || Token === 'ETC' || Token === 'LNC' || Token === 'TUSD' || TokensConfig.KLAY[Token] || TokensConfig.ERC20[Token])
    return hash
  else
    return hash.replace('0x', '')
 
}

const thousands_separators = (num) => {
  var num_parts = num.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num_parts.join('.');
}

const ColoredLine = ({ color }) => (
  <hr
      style={{
          backgroundColor: color,
          marginLeft:'15%',
          height: 0,
          borderTop: `0.5px solid ${({dark}) => dark ? '#52576f' : '#e2e6fa'}`
      }}
  />
);

const HistoryItemMobile = props => {
  const { data, decimals, lang, Token, LTPValue, USDValue} = props;
  const numberOfDecimalsToRoundOff = TokensConfig.TOKEN_DECIMALS[Token];
  const amount = new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).toFixed(numberOfDecimalsToRoundOff)
  const equiAmt = Token === 'TUSD'? amount.toFixed(2) : new BigNumber(amount).multipliedBy(new BigNumber(LTPValue)).multipliedBy(new BigNumber(USDValue)).toFixed(2)
  const langJson = lang === 'en'? EN : lang === 'ko'? KR : CH
  const { DW } = langJson;
  return (
    <Wrap style={{ background: '#ffffff' }}>

      <div className="item">
        {data[1] ? (
          <div className="type deposit">{DW.DEPOSIT}</div>
        ) : (
          <div className="type withdrawal">{DW.WITHDRAW}</div>
        )}
        <div className="amount">
          {amount} {Token}
        </div>
      </div>
      
      <div className="item1">
        {data[5] >= 10?
        (data[5] >= 20 && data[5] <= 29) ? <div className="time">Rejected</div> : <div className="time">{format('yy.MM.dd hh:mm', new Date(data[3] * 1000))}</div> 
          : <div className="time">Pending</div>}

          <div className="amountEqui">&#8776; {thousands_separators(equiAmt)} USD</div>
      </div>

      <ColoredLine color={'#bdbdbd'} style={{align:'end'}}/>
      
      <div className="item2">
        <div className="txnLbl">TXID</div>
        <div
          className={`txID ${data[6] === '0x'? '':'select'}` }
          onClick={() => {
            if(data[6] === '0x')
            return;
            window.open(`${TokensConfig.TXN_ID[Token]}${parseTransactionHash(data[6], Token)}`, '_blank');
          }}>
          {parseTransactionHash(data[6], Token)}
        </div>
      </div>
      
    </Wrap>
  );
};

const Wrap = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({dark}) => dark ? '#52576f' : '#e2e6fa'}

  .item {
    box-sizing: border-box;
    display: flex;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    flex-direction: row;

    .type {
      font-size: 16px;
      width: 50%;
      text-align: left;
      font-family: D-DIN;
    }

    .type.deposit {
      color: #6e93fe;
    }
    .type.withdrawal {
      color: #E06E4B;
    }

    .amount {
      font-size: 16px;
      color: #212121;
      width: 50%;
      font-family: D-DIN;
      text-align: right;
    }
  }

  .item1 {
    box-sizing: border-box;
    display: flex;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    flex-direction: row;

    .time {
      width: 50%;
      font-size: 12px;
      color: #bdbdbd;
      font-family: D-DIN;
      text-align: left;
    }

    .amountEqui {
      font-size: 12px;
      color: #bdbdbd;
      width: 50%;
      font-family: D-DIN;
      text-align: right;
    }
  }

  .item2 {
    box-sizing: border-box;
    display: flex;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 8px;
    width: 100%;
    flex-direction: row;

    .txnLbl {
      font-size: 15px;
      width: 10%;
      color: #212121;
      font-family: D-DIN;
      text-align: left;
    }

    .txID {
      width: 90%;
      text-align: left;
      font-size: 15px;
      overflow: hidden;
      font-family: D-DIN;
      font-style: normal;
      text-overflow: ellipsis;
      margin-right: 16px;
      padding-left: 12px;
      cursor: pointer;
    }
  }

  .select {
    color: #6666ff;
  }
`;

export default HistoryItemMobile;
