import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import { toast } from 'react-toastify';
import { signIn, verifyOTP } from 'exnomy-wallet';
import { css } from 'glamor';
import { confirmable, createConfirmation } from "react-confirm";

let myInterval;
class Confirmation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      minutes: 0,
      seconds: 60,
      loading: false,
      session: this.props.session
    };
  }

  componentDidMount() {
    this.timer()
  }

  timer() {
    myInterval = setInterval(() => {
      const { seconds, minutes } = this.state

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1
        }))
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59
          }))
        }
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(myInterval)
  }

  render() {
    const {
      proceedLabel,
      cancelLabel,
      title,
      phoneNumber,
      show,
      proceed,
      msg,
      enableEscape = false
    } = this.props;
    const { minutes, seconds, loading, session, otp } = this.state
    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={() => proceed(false)}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Warp>
              <div className="daw-otp" style={{ marginTop: '8px' }}>
                <div className="daw-otp-label" style={{ color: '#505d6f' }}>{msg} {phoneNumber}</div>
                <div className="daw-otp-content">
                  <input
                    className="otp_input"
                    type="text"
                    maxLength="6"
                    value={otp}
                    placeholder="OTP"
                    onChange={e => {
                      this.setState({
                        otp: e.target.value
                      })
                    }}></input>
                </div>

                {(minutes === 0 && seconds === 0
                  ? <div className="daw-otp-label" style={{ color: '#0000ee', textDecoration: 'underline', cursor: 'pointer', marginTop: '8px' }} onClick={() => {
                    this.setState({
                      loading: true
                    })
                    signIn(phoneNumber)
                      .then(async (res) => {
                        this.setState({
                          seconds: 60,
                          otp: "",
                          session: res,
                          loading: false
                        })
                        this.timer()
                      }).catch((err) => {
                        toast.error(err.message, {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 2000,
                          className: css({
                            textAlign: 'center',
                            width: '280px',
                            fontSize: '12px'
                          })
                        });
                        this.setState({
                          loading: false
                        })
                      })
                  }}>Resend OTP?</div>
                  : <div className="daw-otp-label" style={{ marginTop: '8px' }}>Time Remaining: {`0${minutes}`}:{seconds < 10 ? `0${seconds}` : seconds}</div>
                )}
              </div>
            </Warp>
          </Modal.Body>
          <Modal.Footer>

            <div style={{ width: '20rem' }}>
              {loading ? <button type="button" style={{ backgroundColor: '#ecf0f1' }} className="btn btn-block" >{cancelLabel}</button> :
                <button type="button" style={{ backgroundColor: '#ecf0f1' }} className="btn btn-block" onClick={() => proceed(false)}>{cancelLabel}</button>
              }
            </div>

            <div style={{ width: '20rem' }} >
              {loading ? <button type="button" className="btn btn-danger btn-block" >{proceedLabel}</button> :
                <button type="button" className="btn btn-danger btn-block" onClick={() => {
                  this.setState({
                    loading: true
                  })
                  verifyOTP(session, otp)
                    .then(() => {
                      this.setState({
                        loading: false
                      })
                      proceed(true)
                    }).catch((err) => {
                      toast.error(err.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                        className: css({
                          textAlign: 'center',
                          width: '280px',
                          fontSize: '12px'
                        })
                      });
                      this.setState({
                        loading: false
                      })
                    })
                }} >{proceedLabel}</button>
              }
            </div>

          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  phoneNumber: PropTypes.string,
  session: PropTypes.object,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function verify(
  phoneNumber,
  session,
  proceedLabel = "Verify",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    phoneNumber,
    session,
    proceedLabel,
    cancelLabel,
    ...options
  });
}

const Warp = styled.div`
min-height: 96px;
.daw-otp {
  position: relative;

  .daw-otp-label {
    display: flex;
    font-size: 13px;
    margin-bottom: 8px;
    color: #abb4e5;
  }

  .daw-otp-content {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #e2e6fa;
    padding: 0 8px 0 8px;
    height: 40px;

    .otp_input{
      width: 100%;
      height:40px;
      border: none;
      color: black;
      background-color:transparent;
      border-radius: 8px;
      padding: 10px 10px;
      text-align: left;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      cursor: text;
      &:hover,
      &:focus,
      &:active,
      &:visited{
        box-shadow: none;
        border: transparent;
        outline:none !important;
      }
    
    }
  }
}
`
