import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

//하단 네비게이션 리스트
const footNavi = [
  {
    key: 'GUIDE',
    href: '/guide'
  },
  {
    key: 'FEE',
    href: '/fee'
  },
  {
    key: 'TERMS',
    href: '/terms-and-privacy'
  }
];

//커뮤니티 팝업 함수
const openPopup = type => {
  switch (type) {
    case 'twitter':
      //새로운 창에 페이지를 띄우는 명령어
      window.open('https://twitter.com/exnomy');
      break;
    case 'telegram':
      window.open('https://t.me/exnomy');
      break;
    default:
      break;
  }
};

const mapStateToProps = state => {
  const langJson = state.language.langJson;
  return {
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class Footer extends React.PureComponent {
  render() {
    const { langJson, isDarkMode } = this.props;
    const { FOOTER } = langJson;

    return (
      <Wrap dark={isDarkMode}>
        <div className="content">
          <div className="top">
            {footNavi.map(obj => {
              return (
                //disabled라는 키값이 true면 이동기능 없는 네비게이션으로 출력
                obj.disabled ? (
                  <div className="navi" key={obj.key}>
                    <span>{FOOTER[obj.key]}</span>
                  </div>
                ) : (
                  <div className="navi" key={obj.key}>
                    <NavLink to={obj.href}>{FOOTER[obj.key]}</NavLink>
                  </div>
                )
              );
            })}
            <div className="icon">
              {/*온클릭 이벤트의 e 는 event객체임*/}
              <div className="twitter" onClick={e => openPopup(e.target.className)} />
              <div className="telegram" onClick={e => openPopup(e.target.className)} />
              {/* <div className="blog" onClick={(e) => openPopup(e.target.className)}/> */}
            </div>
          </div>
          <div className="bottom">
            <div className="rights">
              LinkerGlobal Ltd. Copyright 2020 All Rights Reserved.
            </div>
            <div className="partners">
              DexHigh Services Private Limited (Technology Partners)
            </div>
            <div className="partners">
              Support: info@dexhigh.com
            </div>
            <div className="email">Version : 1.2.5</div>
          </div>


        </div>
      </Wrap>
    );
  }
}

const Wrap = styled.div`
    width: 100%;
    height: 150px;
    //background : #f9fbfd;
    background : ${({ dark }) => (dark ? '#1c2b3a' : '#f9fbfd')};
    
    .content {
        width: 1200px;
        box-sizing: border-box;
        padding-top : 30px;
        margin : 0 auto;
        .top {
            width: 100%;
            //border-bottom: 1px solid #d9d9d9;
            border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#d9d9d9'}
            padding-bottom : 15px;
            .navi {
                a, span {
                    text-decoration: none;
                    font-size : 17px;
                    color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
                    //color : #676767;
                }
                display : inline-block;
                margin-right : 50px;
            }
            .icon {
                height: 20px;
                width: 200px;
                float : right;
                text-align: right;
                i {
                    color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
                    //color : #676767;
                }
                .twitter {
                    width: 20px;
                    height: 20px;
                    margin-right : 30px;
                    display: inline-block;
                    background : ${({ dark }) => (dark ? 'url(' + require('../images/twitter.png') + ')' : 'url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/twitter.png)')} no-repeat;
                    background-size: 100%;
                    cursor: pointer;
                }
                .telegram {
                    width: 20px;
                    height: 20px;
                    margin-right : 30px;
                    display: inline-block;
                    background : ${({ dark }) => (dark ? 'url(' + require('../images/telegram.png') + ')' : 'url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/telegram.png)')} no-repeat;
                    background-size: 100%;
                    cursor: pointer;
                }
                .blog {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    background : url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/naver_blog_black.png) no-repeat;
                    background-size: 100%;
                    background-position-y : center;
                    cursor: pointer;
                }
            }
        }
        .bottom {
            padding-top : 15px;
            div {
                font-size: 14px;
                color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
                //color : #676767;
            }
            .rights {
                padding-right : 20px;
                height: 15px;
                border-right : 1.5px solid #d9d9d9;
                display: inline-block;
            }
            .partners {
              padding-left : 20px;
              padding-right : 20px;
              height: 15px;
              border-right : 1.5px solid #d9d9d9;
              display: inline-block;
          }
            .email {
                padding-left : 20px;
                display: inline-block;
            }
        }
    }
    
    @media(max-width: 1024px) {
        width: 95%;
        margin: 0 auto;
        .content {
            width: 100%;
            .top {
                text-align: center;
                .navi {
                    margin-right : 1rem;
                    a, span {
                        font-size: 0.7rem;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .icon {
                    display: none;
                }
            }
            .bottom {
                text-align: center;
                div {
                    font-size: 0.6rem;;
                }
                .rights {
                    border: 0;
                    padding :0;
                }
                .partners {
                  border: 0;
                  padding :0;
              }
                .email {
                    padding :0;
                    margin-left: 2px;
                }
            }
        }
    }
`;

export default connect(mapStateToProps)(Footer);
