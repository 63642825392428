import React from 'react';
import PanelItem from './elements/PanelItem';
import styled from 'styled-components';
import { Container, Form, Select, Grid, Icon } from "semantic-ui-react";

import {
  getProductList,
  getAdvisorList,
  getlastStrategyId,
  getAllStrategyByPrCode,
  getAllStrategyByPrCodeAndOwner,
  getLastDecision
} from "../../lib/smartcontract/wallet";

import { setAccountsDetails } from "../../actions/accountsInfo";

import { useDispatch, useSelector } from "react-redux";

const Panel = () => {
  const langJson = useSelector((state) => state.language.langJson);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const { ADVISORY_PANEL } = langJson;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [pairs, setPairs] = React.useState([]);
  const [pairInfoList, setPairInfoList] = React.useState([]);
  const [advisors, setAdvisors] = React.useState([]);

  const [selectedPair, setSelectedPair] = React.useState(null);
  const [selectedFormPair, setSelectedFormPair] = React.useState(null);
  const [selectedAdvisor, setSelectedAdvisor] = React.useState(null);
  const [selectedFormAdvisor, setSelectedFormAdvisor] = React.useState(null);
  const [lastId, setLastStrategyId] = React.useState(null);
  const [tableData, setTableData] = React.useState(null);


  React.useEffect(() => {
    async function fetchmarketData() {

      let resultLastStrategyId = await dispatch(getlastStrategyId());
      let resultProduct = await dispatch(getProductList());
      let resultAdvisor = await dispatch(getAdvisorList());


      if (resultAdvisor && resultAdvisor.operators && resultAdvisor.operators.length) {
        let ownerArry = [];
        resultAdvisor.operators.map((val, key) => {
          let newObj = { key: key }
          newObj.value = val;
          newObj.text = val.substr(0, 5) + "..." + val.substr(val.length - 3, val.length + 1);
          ownerArry.push(newObj)
        })
        setAdvisors(ownerArry)
      }

      if (resultProduct.length) {
        let prodArry = [];
        prodArry.push({ value: "all", text: "ALL" })
        resultProduct.map((val, key) => {
          let newObj = { key: key }
          newObj.value = val.baseToken + ":" + val.baseTokenCode + ":" + val.quoteToken + ":" + val.quoteTokenCode;
          newObj.text = val.baseToken + "/" + val.quoteToken;
          prodArry.push(newObj)
        })
        setLastStrategyId(parseInt(resultLastStrategyId))
        setSelectedPair(prodArry[0].text)
        setPairs(prodArry);

        let pInfoList = [];

        for (let pInfo of prodArry) {
          if (pInfo.value !== "all") {
            let newPInfo = pInfo.value.split(":");

            const prCode1 = parseInt(newPInfo[1]);
            const prCode2 = parseInt(newPInfo[3]);

            const prVal1 = newPInfo[0];
            const prVal2 = newPInfo[2];

            if (!pInfoList.includes(prVal1)) {

              pInfoList[prCode1] = prVal1
            } else if (!pInfoList.includes(prVal2)) {
              pInfoList[prCode2] = prVal2

            }
          }
        }

        setPairInfoList(pInfoList);
        handleStrategyResult(pInfoList, prodArry[0].value)
      }

      if (resultProduct) {
        dispatch(
          setAccountsDetails(
            resultProduct
          )
        )
        setLoading(false)
      }

    }

    fetchmarketData()

  }, []);

  function handleStrategyResult(pairList, coinPair, owner) {
    setTableData(null);
    if (coinPair == "all") {

      const data = {
        minRate: 0,
        idFrom: 0,
        idTo: lastId ? lastId : 999999,
        onlySubsribed: false,
        prCode1: 0,
        prCode2: 0,
        owner: owner
      };

      if (owner) {
        dispatch(getAllStrategyByPrCodeAndOwner(data)).then(async (res) => {

          if (res.firstBlocktimes.length) {
            let ownerList = [];
            for (let fBlocktimes of res.firstBlocktimes) {
              ownerList.push(owner)
            }
            res.owners = ownerList;
          }
          if (res.firstBlocktimes.length) {
            let sList = [];
            let nPairList = [];
            let i = 0;
            for (let pCode1 of res.prCode1) {

              let sId = await dispatch(getLastDecision({ id: res.ids[i] }))

              let p1 = pairList[parseInt(pCode1)];
              let p2 = pairList[parseInt(res.prCode2[i])];

              nPairList.push(p1 + "/" + p2);
              let lSignal = parseInt(sId.direction) - 10;
              let lSignalName = "";
              if (lSignal == 0) {
                lSignalName = "Neutral"
              } else if (lSignal == 1) {
                lSignalName = "Weak Positive"
              } else if (lSignal == 2) {
                lSignalName = "Positive"
              } else if (lSignal == -1) {
                lSignalName = "Weak Negative"
              } else {
                lSignalName = "Negative"
              }
              sList.push(lSignalName)
              i++;

            }
            res.coinPair = nPairList;
            res.lastSignal = sList;
          }

          // res.owners = 
          setTableData(res)
        });
      } else {
        dispatch(getAllStrategyByPrCode(data)).then(async (res) => {

          if (res.firstBlocktimes.length) {
            let sList = [];
            let nPairList = [];
            let i = 0;
            for (let pCode1 of res.prCode1) {

              let sId = await dispatch(getLastDecision({ id: res.ids[i] }))

              let p1 = pairList[parseInt(pCode1)];
              let p2 = pairList[parseInt(res.prCode2[i])];

              nPairList.push(p1 + "/" + p2);
              let lSignal = parseInt(sId.direction) - 10;
              let lSignalName = "";
              if (lSignal == 0) {
                lSignalName = "Neutral"
              } else if (lSignal == 1) {
                lSignalName = "Weak Positive"
              } else if (lSignal == 2) {
                lSignalName = "Positive"
              } else if (lSignal == -1) {
                lSignalName = "Weak Negative"
              } else {
                lSignalName = "Negative"
              }
              sList.push(lSignalName)
              i++;

            }
            res.coinPair = nPairList;
            res.lastSignal = sList;
          }

          setTableData(res)
        });
      }



      return;
    }


    let pairData = coinPair.split(":");

    const prCode1 = pairData[1];
    const prCode2 = pairData[3];

    const data = {
      minRate: 0,
      idFrom: 0,
      idTo: lastId ? lastId : 999999,
      onlySubsribed: false,
      prCode1: parseInt(prCode1),
      prCode2: parseInt(prCode2),
      owner: owner
    };

    if (owner) {
      dispatch(getAllStrategyByPrCodeAndOwner(data)).then(async (res) => {

        if (res.firstBlocktimes.length) {
          let ownerList = [];
          for (let fBlocktimes of res.firstBlocktimes) {
            ownerList.push(owner)
          }
          res.owners = ownerList;
        }
        if (res.firstBlocktimes.length) {
          let sList = [];
          let nPairList = [];
          let i = 0;
          for (let pCode1 of res.prCode1) {

            let sId = await dispatch(getLastDecision({ id: res.ids[i] }))

            let p1 = pairList[parseInt(pCode1)];
            let p2 = pairList[parseInt(res.prCode2[i])];

            nPairList.push(p1 + "/" + p2);
            let lSignal = parseInt(sId.direction) - 10;
            let lSignalName = "";
            if (lSignal == 0) {
              lSignalName = "Neutral"
            } else if (lSignal == 1) {
              lSignalName = "Weak Positive"
            } else if (lSignal == 2) {
              lSignalName = "Positive"
            } else if (lSignal == -1) {
              lSignalName = "Weak Negative"
            } else {
              lSignalName = "Negative"
            }
            sList.push(lSignalName)
            i++;

          }
          res.coinPair = nPairList;
          res.lastSignal = sList;
        }

        // res.owners = 
        setTableData(res)
      });
    } else {

      dispatch(getAllStrategyByPrCode(data)).then(async (res) => {
        // console.log("tablData", res)
        if (res.firstBlocktimes.length) {
          let sList = [];
          let nPairList = [];
          let i = 0;
          for (let pCode1 of res.prCode1) {

            let sId = await dispatch(getLastDecision({ id: res.ids[i] }))

            let p1 = pairList[parseInt(pCode1)];
            let p2 = pairList[parseInt(res.prCode2[i])];

            nPairList.push(p1 + "/" + p2);
            let lSignal = parseInt(sId.direction) - 10;
            let lSignalName = "";
            if (lSignal == 0) {
              lSignalName = "Neutral"
            } else if (lSignal == 1) {
              lSignalName = "Weak Positive"
            } else if (lSignal == 2) {
              lSignalName = "Positive"
            } else if (lSignal == -1) {
              lSignalName = "Weak Negative"
            } else {
              lSignalName = "Negative"
            }
            sList.push(lSignalName)
            i++;

          }
          res.coinPair = nPairList;
          res.lastSignal = sList;
        }

        setTableData(res)
      });
    }
  }

  const handleSubmit = () => {
    let pairInfo = null;
    if (selectedFormPair != null) {
      pairInfo = selectedFormPair;
      setSelectedPair(selectedFormPair.split(":")[0] + "/" + selectedFormPair.split(":")[2])
    } else {
      pairInfo = pairs[0].value;
      setSelectedPair(pairs[0].text)

    }

    setSelectedAdvisor(selectedFormAdvisor)
    handleStrategyResult(pairInfoList, pairInfo, selectedFormAdvisor)
  }

  const handlePairOnChange = (e, data) => {
    setSelectedFormPair(data.value)
  }

  const handleAdvisorOnChange = (e, data) => {
    setSelectedFormAdvisor(data.value)

  }

  return (
    <Wrap dark={isDarkMode}>

      <div className="items">
        {loading ? (
          <Container textAlign='center'>
            Loading...
          </Container>
        ) : (
          <Container textAlign='center'>
            <Grid centered columns={2}>
              <Grid.Column className="HJGSD text-center">
                <a href="https://t.me/exnomyrobo" target="_blank" rel="noreferrer" className="btn btn-outline-primary text-center"> <i className="telegram"></i> Click here to join our telegram channel and get latest action updates</a>
                <br />
                <br />
                <Form onSubmit={handleSubmit}>
                  <label className="DSDFSDF">{ADVISORY_PANEL.PAIR_LABEL}</label>
                  <Form.Field
                    fluid
                    big
                    control={Select}
                    options={pairs}
                    placeholder={ADVISORY_PANEL.PAIR_LABEL}
                    search
                    name='pair'
                    onChange={handlePairOnChange}
                  />
                  <label className="DSDFSDF">{ADVISORY_PANEL.ADVISOR_LABEL}</label>
                  <Form.Field
                    fluid
                    big
                    control={Select}
                    options={advisors}
                    clearable
                    placeholder={ADVISORY_PANEL.ADVISOR_LABEL}
                    search
                    name='advisor'
                    onChange={handleAdvisorOnChange}
                  />
                  <Form.Button color="blue" fluid>
                    {ADVISORY_PANEL.SEARCH}
                    <Icon name='right arrow' style={{ float: "right" }} />
                  </Form.Button>
                </Form>
              </Grid.Column>
            </Grid>
            <Grid centered columns={1} className="JBDSJ">
              {/* <Grid.Column>
                <div className="section-heading text-center">
                  <h2 className="KJBDS">The World's Leading Cryptocurrency Exchange</h2>
                  <p className="text-gray"> Trade Bitcoin, ETH, and hundreds of other cryptocurrencies in minutes. </p>
                </div>
              </Grid.Column> */}
            </Grid>
            {
              tableData &&
              <PanelItem isDarkMode={isDarkMode} expandedRow={tableData} coin1={selectedPair.split("/")[0]} coin2={selectedPair.split("/")[1]} onlySubsribed={"nsubscribe"} advisorSearch={selectedAdvisor} />
            }
          </Container>)}
      </div>
    </Wrap >
  );
}

//   setRef(ref) {
//     if (ref) {
//       this.ps = new PerfectScrollbar(ref, {
//         suppressScrollX: true,
//         maxScrollbarLength: 20
//       });
//     }
//   }
// }

const Wrap = styled.section`
  // width: 1200px;
  // margin: 0 auto;
  // height: 542px;
  // //border: 1px solid #d9d9d9;
  // background-color: ${({ dark }) => dark ? '#263554' : '#ffffff'};
  // border:1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  // box-sizing: border-box;
  // margin-top: 42px;
  // border-radius: 10px;
  position: relative;
  padding: 1.5rem!important;
  
  // background: #fff;
  // top: -100px;
  // margin-bottom: -100px;

  .telegram {
    width: 13px;
    height: 13px;
    margin-right : 10px;
    display: inline-block;
    background : ${({ dark }) => (dark ? 'url(' + require('../../images/telegram.png') + ')' : 'url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/telegram.png)')} no-repeat;
    background-size: 100%;
    cursor: pointer;
  }
  .guide {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    .left {
      width: 100px;
      display: inline-block;
      vertical-align: top;
    }
    .right {
      width: 800px;
      display: inline-block;
    }
    button {
      border: 0;
      padding: 0;
      outline: 0;
      cursor: pointer;
      font-size: 14px;
      border-bottom: 1px solid black;
      height: 22px;
      font-weight: bold;
      background: #fff;
      display: inline-block;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
    }
    .button: after {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0;
      z-index: -1;
      border-radius: 5px;
      content: "";
      background: rgba(0,0,0,.09);
      transition: all .4s ease-in-out;
    }

  }
  .baseTab {
    width: 100%;
    height: 61px;
    line-height: 61px;
    //border-bottom: 1px solid #d9d9d9;
    border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    box-sizing: border-box;
    .base {
      display: inline-block;
      margin: 0 20px;
      padding: 0 10px;
      font-size: 19px;
      //color: #364958;
      color: ${({ dark }) => dark ? '#8d94af' : '#364958'}
      cursor: pointer;
      box-sizing: border-box;
      height: 60px;
      &.select {
        color: #6666ff;
        border-bottom: 3px solid #6666ff;
      }
    }
    .search {
      width: 300px;
      float: right;
      margin-right: 32px;
      margin-top: 10px;
      height: 42px;
      line-height: 42px;
      box-sizing: border-box;
      font-size: 15px;
      color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
      //border: 1px solid #d9d9d9;
      border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
      border-radius: 10px;
      text-indent: 5px;
      .searchInput {
        height: 38px;
        line-height: 38px;
        text-indent: 10px;
        text-align: left;
        border: 0;
        width: 259px;
        color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
        background: 0;
        outline: 0;
        vertical-align: top;
      }
      i {
        height: 36px;
        line-height: 35px;
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
  .header {
    width: 100%;
    height: 77px;
    display: flex;
    flex-direction: row;
    //border-bottom: 1px solid #d9d9d9;
    border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    align-items: center;
    div {
      flex: 1;
      text-align: center;
      font-size: 17px;
      //color: #364958;
      color : ${({ dark }) => (dark ? '#8d94af' : '#364958')};
      font-weight: 600;
      cursor: pointer;
      &.price {
        flex: 1;
      }
      &.sort {
        width: 14px;
        height: 28px;
        vertical-align: middle;
        display: inline-block;
        margin-left: 2px;
        i {
          display: block;
          height: 9px;
          font-size: 15px;
          &.off {
            opacity: 0.2;
          }
        }
      }
    }
  }
  .items {
    width: 100%;
    height: 526px;
    box-sizing: border-box;
    // overflow-y: auto;
    .HJGSD {
      // box-shadow: 0 24px 64px rgb(22 28 45 / 5%) !important;
      border-radius: .25rem!important;
      background-color: ${({ dark }) => dark ? '#869ab8' : '#fff'};
      padding: 1.5rem!important;
      position: relative;
      top: -100px;
      margin-bottom: -100px;
    }
    .buoVwv .items .ui.selection.dropdown {
      border: 1px solid #f2f6fe !important;
      border-radius: .28571429rem !important;
      // background-color: #f2f6fe !important;
      // background-color: ${({ dark }) => dark ? '#1c2b3a' : '#f2f6fe'};
      color: #506690 !important;
    }
    .ui.selection.dropdown {
      border: 1px solid #f2f6fe !important;
      border-radius: .28571429rem !important;
      background-color: #f2f6fe !important;
      color: #506690 !important;
    }
    .DSDFSDF {
      font-size: 16px !important;
      font-weight: 600 !important;
      color: #161c2d !important;
      margin-bottom: 15px !important;
    }
    .JBDSJ {
      padding-top: 50px;
      padding-bottom: 50;
    }
    .KJBDS {
          font-size: 30px;
          color: ${({ dark }) => dark ? '#869ab8' : 'inherit'};
    }
    .section-heading p {
      margin-top: 8px;
      color: #869ab8;
      font-size: 16px;
    }
    .rdt_TableHeadRow {
      // background-color: #f2f6fe !important;
      background-color : ${({ dark }) => (dark ? 'inherit' : '#f2f6fe')};
      color: #869ab8 !important;
      border-bottom-style: none !important;
      border-radius: .25rem!important;
      margin-bottom: 15px;
    }
    .rdt_TableRow {
      // background-color: #f2f6fe !important;
      background-color : ${({ dark }) => (dark ? 'inherit' : '#f2f6fe')} !important;
      color: #869ab8 !important;
      border-bottom-style: none !important;
      border-radius: .25rem!important;
      margin-bottom: 15px;
    }
    .rdt_Pagination {
      background-color: ${({ dark }) => (dark ? 'inherit' : '#f2f6fe')};
      color: #869ab8 !important;
      // border-bottom-style: none !important;
      border-top-style: none;
      border-radius: .25rem!important;
      margin-bottom: 15px;
    }
    .rdt_Table {
      background-color: inherit;
    }
  }
  //div:last-child {
  //    border-bottom : 0;
  //}

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    margin-top: 1.5rem;
    .baseTab {
      height: 3rem;
      line-height: 3rem;
      .base {
        display: inline-block;
        margin: 0 0.5rem;
        padding: 0 0.5rem;
        font-size: 0.9rem;
        box-sizing: border-box;
        height: 3rem;
      }
      .search {
        display: none;
      }
    }
    .header {
      width: 100%;
      height: 3rem;
      align-items: center;
      div {
        font-size: 0.8rem;
        &.volume {
          display: none;
        }
        &.sort {
          width: 1rem;
          i {
            font-size: 1rem;
          }
        }
      }
    }
    .items {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      overflow-y: auto;
    }
    .ui.grid {
      margin-top: 8rem;
      margin-bottom: -1rem;
    }
    .HJGSD {
      width: 100% !important;
    }
    
  }
`;

export default Panel;
