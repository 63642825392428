import axios from 'axios';
import env from './env';
import { store } from '../index';
import { loadAccountHydroAuthentication } from './session';
import { getSelectedAccount } from 'exnomy-wallet';

const getAxiosInstance = () => {
  const state = store.getState();
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  const hydroAuthentication = loadAccountHydroAuthentication(address);
  let instance;

  //if (hydroAuthentication) {
  instance = axios.create({
    headers: {
      'DEX-EXONOMY-AUTH': hydroAuthentication
    }
  });

  return instance;
};

const _request = (method, url, ...args) => {
  return getAxiosInstance()[method](`${env.API_ADDRESS}${url}`, ...args);
};

const api = {
  get: (url, ...args) => _request('get', url, ...args),
  delete: (url, ...args) => _request('delete', url, ...args),
  head: (url, ...args) => _request('head', url, ...args),
  post: (url, ...args) => _request('post', url, ...args),
  put: (url, ...args) => _request('put', url, ...args),
  patch: (url, ...args) => _request('patch', url, ...args)
};

export default api;
