export default async (pair, startTime, endTime) => {

    const res = await fetch(`https://api.binance.com/api/v3/klines?symbol=BTCUSDT&startTime=${(startTime - 60) * 1000}&interval=1m&limit=1000&endTime=${endTime * 1000}`)
    const resJson = await res.json()
    return resJson

    //Please make an object and return data. =
    // [
    //     1499040000000,      // Open time
    //     "0.01634790",       // Open
    //     "0.80000000",       // High
    //     "0.01575800",       // Low
    //     "0.01577100",       // Close
    //     "148976.11427815",  // Volume
    //     1499644799999,      // Close time
    //     "2434.19055334",    // Quote asset volume
    //     308,                // Number of trades
    //     "1756.87402397",    // Taker buy base asset volume
    //     "28.46694368",      // Taker buy quote asset volume
    //     "17928899.62484339" // Ignore.
    //   ]

}

export const dailyBinancePrice = async (pair, startTime, endTime) => {

    const res = await fetch(`https://api.binance.com/api/v3/klines?symbol=${pair}&startTime=${startTime * 1000}&interval=1d&limit=1000&endTime=${endTime * 1000}`)
    let resJson = null;
    if (res) {
        resJson = await res.json()
    }
    return resJson

}