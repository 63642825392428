import React, { useEffect, useState } from "react";
import "./styles.css";
import { Segment, Checkbox, Select, Icon, Button, Label, Modal, Grid, Popup } from "semantic-ui-react";
import { Card } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateLineChartdata, fetchLineGraphDetails } from "./functions";
import { LineChart } from "../../lib/charts";

import styled from 'styled-components';

import { getDecision, getRecDirection, getStrategyName, getStrategyOwner, getTestDecision } from "../../lib/smartcontract/wallet";
import { useParams } from "react-router";
import moment from "moment";
import { toast } from "react-toastify";
import DataTable from 'react-data-table-component';

import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      fontSize: "14px"
    },
  },
};

const columnsWithDecision = [
  {
    name: "Trade date (UTC + " + moment().utcOffset() / 60 + ")",
    selector: "tradeDate",
    // minWidth: "170px",
    sortable: false,
    wrap: true
    // compact: true
  },
  {
    name: "Trade price",
    selector: "tradePrice",
    sortable: false,
    compact: true
  },
  {
    name: "Signal",
    selector: "signal",
    sortable: false,
    hide: ("sm")
    // wrap: true,
    // compact: true
  },
  {
    name: "Position",
    selector: "position",
    sortable: false,
    hide: ("sm")
  },
  {
    name: "Decision",
    selector: "decision",
    sortable: false,
    compact: true
  },
  {
    name: "Asset Value",
    selector: "assetValue",
    sortable: false,
    compact: true
  },
];

const triggerOptions = [
  { key: 0, value: 0, text: '0 (Neutral)' },
  { key: 1, value: 1, text: '1 (Weak Positive)' },
  { key: 2, value: -1, text: '-1 (Weak Negative)' },
  { key: 3, value: 2, text: '2 (Positive)' },
  { key: 4, value: -2, text: '-2 (Negative)' },
]

const Panel = () => {

  const langJson = useSelector((state) => state.language.langJson);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const { ADVISORY_PANEL } = langJson;

  const dispatch = useDispatch();

  const { id, coin1, coin2, isSubscribed, isLive, isNative } = useParams();

  const [loading, setLoading] = useState(true);
  const [isLineGraphLoading, setLineGraphLoading] = useState(true);
  const [isFree, setFree] = useState("0");
  const [isShowCalc, setShowCalc] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [detailCalcData, setDetailCalcData] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [strategyName, setStrategyName] = useState(null);
  const [sharpRatioRange, setSharpRatioRange] = useState(2);
  const [strategyRating, setStrategyRating] = useState(1);
  const [profitPercentage, setProfitPercentage] = useState(null);
  const [toggleShowDecision, setToggleShowDecision] = useState(false);

  const [triggerData, setTriggerData] = useState(null);

  const [lineChartData, setLineChartData] = useState(null);
  const [lineGraphPlotVal, setLineChartVal] = useState(null);
  const [lineGraphPlotDiff, setLineChartDiff] = useState(null);

  const [linePriceVals, setlinePriceVals] = useState(null);

  // const [lineSignalChartData, setLineSignalChartData] = useState(null);
  // const [lineSignalGraphPlotVal, setLineSignalChartVal] = useState(null);
  // const [lineSignalGraphPlotDiff, setLineSignalChartDiff] = useState(null);

  const [dailyTableData, setDailyTableData] = useState(null);

  const [newTriggerVal, setNewTriggerVal] = useState(null);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    async function fetchDetails() {
      let result = null, resultTrigger = null, resultStrategyName = null, resultOwner = null;

      let decId = parseInt(id);

      if (isLive == "live") {

        result = await dispatch(getDecision({ id: decId }));


      } else {
        result = await dispatch(getTestDecision({ id: decId }));

      }

      resultTrigger = await dispatch(getRecDirection({ id: decId }));

      resultOwner = await dispatch(getStrategyOwner({ id: decId }));
      resultStrategyName = await dispatch(getStrategyName({ id: decId }));

      let nTriggerVal = {
        recBuyDirection: resultTrigger.recBuyDirection - 10,
        recBuyClearDirection: resultTrigger.recBuyClearDirection - 10,
        recSellDirection: resultTrigger.recSellDirection - 10,
        recSellClearDirection: resultTrigger.recSellClearDirection - 10
      };
      // let resultStrategyType = await dispatch(getStrategyType({ id: parseInt(id) }));
      setNewTriggerVal(nTriggerVal);
      setTriggerData(resultTrigger);
      setCreatedBy(resultOwner);
      setStrategyName(resultStrategyName);

      setLoading(false);

      if (result.blocktimes.length || result.directions.length || result.prices.length) {

        setDetailData(result);
        let lineDetails = await fetchLineGraphDetails(result, coin1 + "T" + coin2, resultTrigger, isLive);

        if (lineDetails) {
          setDetailCalcData(lineDetails);
          let lineData = updateLineChartdata(lineDetails.priceArry, lineDetails.signalArry, lineDetails.positionArry, lineDetails.dateArry, lineDetails.coinPriceArry, coin1 + "/" + coin2, isLive == "live" ? false : true);
          setlinePriceVals(lineDetails.positionArry);
          setLineChartDiff(lineData.linePlotDiff);
          setLineChartVal({ minVal: lineData.linePlotMinValue, maxVal: lineData.linePlotMaxValue });
          setLineChartData(lineData.lineChartData)
          setDailyTableData({ dateArry: lineDetails.dateTableArry.reverse(), priceArry: lineDetails.priceTableArry.reverse(), signalArry: lineDetails.signalTableArry.reverse(), positionArry: lineDetails.positionTableArry.reverse(), coinPriceArry: lineDetails.coinPriceTableArry.reverse(), tradeAmtArry: lineDetails.tradeAmtTableArry.reverse(), qtyTableArry: lineDetails.qtyTableArry.reverse(), assetVal: lineDetails.newAssetValArry.reverse() });
          setProfitPercentage(lineDetails.profitPercentage);

          if (lineDetails.sharpRatio >= -sharpRatioRange && lineDetails.sharpRatio <= sharpRatioRange) {
            // console.log("lev3")
            setStrategyRating(3)
          } else if (lineDetails.sharpRatio > sharpRatioRange && lineDetails.sharpRatio <= sharpRatioRange + 1) {
            // console.log("lev4")
            setStrategyRating(4)

          } else if (lineDetails.sharpRatio > sharpRatioRange + 1) {
            // console.log("lev5")
            setStrategyRating(5)

          } else if (lineDetails.sharpRatio > -sharpRatioRange + 1 && lineDetails.sharpRatio <= -sharpRatioRange) {
            // console.log("lev2")
            setStrategyRating(2)

          }

          // setStrategyRating(4)

          let rowVal = { dateArry: lineDetails.dateTableArry, priceArry: lineDetails.priceTableArry, signalArry: lineDetails.signalTableArry, positionArry: lineDetails.positionTableArry, coinPriceArry: lineDetails.coinPriceTableArry, tradeAmtArry: lineDetails.tradeAmtTableArry, qtyTableArry: lineDetails.qtyTableArry, assetVal: lineDetails.newAssetValArry }

          await updateRowData(rowVal)
        }
        setLineGraphLoading(false);
      }


    }

    fetchDetails()
  }, []);

  const updateRowData = async (rowVal) => {
    let newAllRowData = [], newDecRowData = [];
    if (rowVal && rowVal.dateArry && rowVal.dateArry.length) {
      rowVal.dateArry.map((val, key) => {
        let posInfo = "-", sigInfo = "-";

        if (rowVal.positionArry[key + 1] == rowVal.positionArry[key]) {
          posInfo = "-"
        } else
          if (rowVal.positionArry[key] == -1) {
            posInfo = "SELL"
          } else if (rowVal.positionArry[key] == 1) {
            posInfo = "BUY"
          }
          else if (rowVal.positionArry[key] == 0) {
            posInfo = "CLEAR"
          }

        if (rowVal.signalArry[key] == 0) {
          sigInfo = rowVal.signalArry[key] + " (Neutral)"
        } else if (rowVal.signalArry[key] == 1) {
          sigInfo = rowVal.signalArry[key] + " (Weak Positive)"
        } else if (rowVal.signalArry[key] == 2) {
          sigInfo = rowVal.signalArry[key] + " (Positive)"
        } else if (rowVal.signalArry[key] == -1) {
          sigInfo = rowVal.signalArry[key] + " (Weak Negative)"
        } else {
          sigInfo = rowVal.signalArry[key] + " (Negative)"
        }

        let blockDataObj = {
          tradeDate: val,
          tradePrice: rowVal.tradeAmtArry[key],
          signal: sigInfo,
          position: rowVal.positionArry[key],
          decision: posInfo,
          assetValue: rowVal.assetVal[key]
        }
        newAllRowData.push(blockDataObj);
        if (posInfo !== "-") {
          newDecRowData.push(blockDataObj);
        }
      })

      if (newDecRowData.length) {
        setShowCalc(true);
      } else {
        setShowCalc(false)
      }

      if (newAllRowData.length > 0) {
        // console.log("lls2", newAllRowData)
        let rObj = {
          allData: newAllRowData,
          decData: newDecRowData
        }

        setRowData(rObj)
      }
    }
    return
  }

  const displayRowData = (rowVal) => {
    // let colToggle = toggleShowDecision ? columnsWithDecision : columnsWithoutDecision
    let colToggle = columnsWithDecision;
    let rowToggle = toggleShowDecision ? rowVal.allData : rowVal.decData;

    return <DataTable
      columns={colToggle}
      data={rowToggle}
      paginationPerPage={10}
      striped={true}
      pagination={true}
      noHeader={true}
      highlightOnHover={true}
      customStyles={customStyles}
      theme={isDarkMode ? "dark" : "light"}
    />
  }

  const saveTriggers = async (trigVal) => {

    // let decId = isLive == "live" ? parseInt(id) : 4;

    let nTriggerVal = {
      recBuyDirection: parseInt(trigVal.recBuyDirection) + 10,
      recBuyClearDirection: parseInt(trigVal.recBuyClearDirection) + 10,
      recSellDirection: parseInt(trigVal.recSellDirection) + 10,
      recSellClearDirection: parseInt(trigVal.recSellClearDirection) + 10
    }

    // dispatch(setRecDirection({ id: decId, recBuyDirection: parseInt(nTriggerVal.recBuyDirection), recBuyClearDirection: parseInt(nTriggerVal.recBuyClearDirection), recSellDirection: parseInt(nTriggerVal.recSellDirection), recSellClearDirection: parseInt(nTriggerVal.recSellClearDirection) }))
    setNewTriggerVal({ recBuyDirection: parseInt(trigVal.recBuyDirection), recBuyClearDirection: parseInt(trigVal.recBuyClearDirection), recSellDirection: parseInt(trigVal.recSellDirection), recSellClearDirection: parseInt(trigVal.recSellClearDirection) })
    // setOpen(false)
    setTriggerData({ recBuyDirection: parseInt(nTriggerVal.recBuyDirection), recBuyClearDirection: parseInt(nTriggerVal.recBuyClearDirection), recSellDirection: parseInt(nTriggerVal.recSellDirection), recSellClearDirection: parseInt(nTriggerVal.recSellClearDirection) })

    setLineGraphLoading(true);

    let lineDetails = await fetchLineGraphDetails(detailData, coin1 + "T" + coin2, nTriggerVal, isLive);

    if (lineDetails) {
      setDetailCalcData(lineDetails);
      let lineData = updateLineChartdata(lineDetails.priceArry, lineDetails.signalArry, lineDetails.positionArry, lineDetails.dateArry, lineDetails.coinPriceArry, coin1 + "/" + coin2, isLive == "live" ? false : true);
      setlinePriceVals(lineDetails.positionArry);
      setLineChartDiff(lineData.linePlotDiff);
      setLineChartVal({ minVal: lineData.linePlotMinValue, maxVal: lineData.linePlotMaxValue });
      setLineChartData(lineData.lineChartData)
      setDailyTableData({ dateArry: lineDetails.dateTableArry.reverse(), priceArry: lineDetails.priceTableArry.reverse(), signalArry: lineDetails.signalTableArry.reverse(), positionArry: lineDetails.positionTableArry.reverse(), coinPriceArry: lineDetails.coinPriceTableArry.reverse(), tradeAmtArry: lineDetails.tradeAmtTableArry.reverse(), qtyTableArry: lineDetails.qtyTableArry.reverse(), assetVal: lineDetails.newAssetValArry.reverse() });
      setProfitPercentage(lineDetails.profitPercentage);


      if (lineDetails.sharpRatio >= -sharpRatioRange && lineDetails.sharpRatio <= sharpRatioRange) {
        // console.log("lev3")
        setStrategyRating(3)
      } else if (lineDetails.sharpRatio > sharpRatioRange && lineDetails.sharpRatio <= sharpRatioRange + 1) {
        // console.log("lev4")
        setStrategyRating(4)

      } else if (lineDetails.sharpRatio > sharpRatioRange + 1) {
        // console.log("lev5")
        setStrategyRating(5)

      } else if (lineDetails.sharpRatio > -sharpRatioRange + 1 && lineDetails.sharpRatio <= -sharpRatioRange) {
        // console.log("lev2")
        setStrategyRating(2)

      }
      // setStrategyRating(4)

      let rowVal = { dateArry: lineDetails.dateTableArry, priceArry: lineDetails.priceTableArry, signalArry: lineDetails.signalTableArry, positionArry: lineDetails.positionTableArry, coinPriceArry: lineDetails.coinPriceTableArry, tradeAmtArry: lineDetails.tradeAmtTableArry, qtyTableArry: lineDetails.qtyTableArry, assetVal: lineDetails.newAssetValArry }

      await updateRowData(rowVal)
    }
    setLineGraphLoading(false);
    toast.info("Succesfully! saved");
  }

  const ratingDisplay = () => {
    let rateStars = [];
    for (let i = 0; i < strategyRating; i++) {
      rateStars.push(<Icon name="star" color="yellow" />)
    }
    return <div>
      {rateStars}
    </div>;
  }

  const handleToggleDecision = () => {
    setToggleShowDecision(!toggleShowDecision)
    displayRowData(rowData);
  }

  if (isFree !== "0" && isSubscribed == "nsubscribe") {
    return (
      <Wrap dark={isDarkMode}>

        <div className="items">
          <Segment.Group
          >

            <Segment raised>

              <div style={{ textAlign: "center" }}>
                Invalid access <Link to="/robo">Go back</Link>
              </div>

            </Segment>

          </Segment.Group>
        </div></Wrap>
    )
  } else {
    return (
      isNative === 'isnative' ?
        <div
          style={{
            backgroundColor: "#F6F8FB",
            minHeight: "100vh",
            overflow: "hidden",
          }}
        >
          {
            !isLineGraphLoading ? <LineChart data={lineChartData} plotDiff={lineGraphPlotDiff} plotValue={lineGraphPlotVal} isShowYBAxis={false} /> :
              <div style={{ alignContent: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '50vh', fontSize: '5vh' }}>
                Loading...
              </div>
          }
        </div>
        :
        <Wrap dark={isDarkMode}>
          <MediaContextProvider>
            <Media greaterThan="mobile">
              <div className="items">
                {/* <Segment.Group> */}

                <Segment vertical>
                  {
                    loading && (
                      <div style={{ textAlign: "center" }}>Loading...</div>
                    )
                  }
                  {
                    detailData !== null &&
                    (<div>
                      <Card.Group itemsPerRow={1}>
                        <Card color="red">
                          <Card.Content style={{ backgroundColor: isDarkMode ? "#263554" : "#ffffff" }}>
                            <Card.Header>
                              {strategyName} {
                                detailCalcData && detailCalcData.sharpRatio !== null && <div>
                                  {/* <Label size="tiny" color="blue">
                          SharpRatio {isNaN(detailCalcData.sharpRatio) ? -0.6 : detailCalcData.sharpRatio.toFixed(1)}
                        </Label> */}
                                  {ratingDisplay()}
                                </div>

                              }
                            </Card.Header>
                            <Card.Meta>
                              {/* <span style={{ color: "teal" }}>Buy</span> | <span style={{ color: "red" }}>Sell</span> */}
                            </Card.Meta>
                            <br />
                            {
                              // !isLineGraphLoading && linePriceVals !== null && isShowCalc ?

                              !isLineGraphLoading && linePriceVals !== null ?
                                (<div>
                                  <Card.Description style={{ height: "400px" }}>
                                    {
                                      !isLineGraphLoading ? <LineChart data={lineChartData} plotDiff={lineGraphPlotDiff} plotValue={lineGraphPlotVal} isShowYBAxis={true} /> : "Loading..."
                                    }

                                  </Card.Description>
                                  <br />
                                  <div style={{ "textAlign": "center" }}>Note: Exnomy does not guarantee any profit and loss. Previous records does not guarantee any future profits.
                                  </div>
                                  <br />
                                </div>) : <div>
                                  <br />
                                  <div style={{ "textAlign": "center" }}>Not enough data to Plot graph
                                  </div>
                                  <br />
                                </div>
                            }


                          </Card.Content>
                        </Card>
                        <Segment raised>
                          <Grid columns={2} stackable textAlign='center'>

                            <Grid.Row verticalAlign='middle' textAlign='center' style={{ backgroundColor: isDarkMode ? "#263554" : "#ffffff" }}>
                              {triggerData && detailData && (
                                <Grid.Column width={10}>
                                  {ADVISORY_PANEL.CREATED_BY} <span style={{ color: "grey" }}> {createdBy.substr(0, 5) + "..." + createdBy.substr(createdBy.length - 3, createdBy.length + 1)} </span> {ADVISORY_PANEL.CREATED_ON} <span style={{ color: "grey" }}>  {dailyTableData && moment(dailyTableData.dateArry[dailyTableData.dateArry.length - 1]).format("ll")}</span>
                                  <br />
                                  <br />
                                  {
                                    detailCalcData && detailCalcData.sharpRatio !== null && linePriceVals !== null && isShowCalc && <Label size="tiny" color="blue">
                                      SharpRatio {isNaN(detailCalcData.sharpRatio) ? -0.6 : detailCalcData.sharpRatio.toFixed(1)}
                                    </Label>
                                  }

                                  {
                                    detailCalcData && profitPercentage !== null && <Label size="tiny" color="blue">
                                      Profit {profitPercentage.toFixed(1)}%
                                    </Label>
                                  }
                                  {
                                    detailData && linePriceVals && isShowCalc && <Label size="tiny" color='blue'>
                                      {ADVISORY_PANEL.CURRENT} {dailyTableData && dailyTableData.positionArry[0] == 1 ? ADVISORY_PANEL.BUY : dailyTableData && dailyTableData.positionArry[0] == 0 ? ADVISORY_PANEL.CLEAR : ADVISORY_PANEL.SELL} {ADVISORY_PANEL.POSITION}

                                    </Label>
                                  }
                                  <br />
                                  <br />
                                  <Label basic color={isSubscribed == "subscribe" ? 'blue' : "grey"} size="tiny">
                                    <Icon name={isSubscribed == "subscribe" ? 'check' : "dot circle"} />
                                    {ADVISORY_PANEL.SUBSCRIBED}
                                  </Label>
                                  <Label color={isFree == "0" ? 'green' : "grey"} basic size="tiny">
                                    <Icon name={isFree == "0" ? 'check' : "dot circle"} />
                                    {ADVISORY_PANEL.FREE}
                                  </Label>

                                  <br />
                                  <br />
                                  <Checkbox label='Toggle Decision' onChange={handleToggleDecision} slider />
                                </Grid.Column>
                              )}
                              {
                                triggerData && detailData && (<Grid.Column width={6} textAlign='center'>
                                  <Card.Group itemsPerRow={1}>
                                    <Card>
                                      <Card.Content extra style={{ backgroundColor: isDarkMode ? "#263554" : "#ffffff" }}>
                                        <h3>{ADVISORY_PANEL.ALGO_TRADE} {"     "}
                                          <Popup content={ADVISORY_PANEL.VIEW_CONTENT} position={"below"} trigger={<Label color='orange' basic size="mini" onClick={() => setOpen(true)}>
                                            <Icon name='edit' /> {ADVISORY_PANEL.VIEW_AND_CHANGE}
                                          </Label>} />
                                          <Modal
                                            closeIcon
                                            open={open}
                                            onClose={() => setOpen(false)}
                                            // onOpen={() => setOpen(true)}
                                            size="mini"
                                            style={{ backgroundColor: isDarkMode ? "#263554" : "#ffffff" }}
                                          >
                                            <Modal.Header style={{ backgroundColor: isDarkMode ? "#263554" : "#ffffff" }}>{ADVISORY_PANEL.ALGO_TRADE}</Modal.Header>
                                            <Modal.Content image style={{ backgroundColor: isDarkMode ? "#263554" : "#ffffff" }}>
                                              <Modal.Description>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.BUY }}
                                                    labelPosition='right'
                                                    placeholder='Enter buy'
                                                    // onChange={(e) => setValue(e.target.value)}
                                                    value={newTriggerVal.recBuyDirection}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recBuyDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Buy Trigger ' + newTriggerVal.recBuyDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recBuyDirection: val.value })}
                                                  />
                                                </p>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.BUY_CLEAR }}
                                                    labelPosition='right'
                                                    placeholder='Enter clear buy'
                                                    value={newTriggerVal.recBuyClearDirection}
                                                    // onChange={(e) => setValue(e.target.value)}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recBuyClearDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Buy Clear Trigger ' + newTriggerVal.recBuyClearDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recBuyClearDirection: val.value })}
                                                  />
                                                </p>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.SELL }}
                                                    labelPosition='right'
                                                    placeholder='Enter sell'
                                                    value={newTriggerVal.recSellDirection}
                                                    // onChange={(e) => setValue(e.target.value)}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recSellDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Buy Trigger ' + newTriggerVal.recSellDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recSellDirection: val.value })}
                                                  />
                                                </p>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.SELL_CLEAR }}
                                                    labelPosition='right'
                                                    placeholder='Enter clear sell'
                                                    value={newTriggerVal.recSellClearDirection}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recSellClearDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Sell Clear Trigger ' + newTriggerVal.recSellClearDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recSellClearDirection: val.value })}
                                                  />
                                                </p>
                                                {/* <p>Is it okay to use this photo?</p> */}
                                              </Modal.Description>
                                            </Modal.Content>
                                            <Modal.Actions style={{ backgroundColor: isDarkMode ? "#263554" : "#ffffff" }}>
                                              <Button
                                                content={ADVISORY_PANEL.SAVE}
                                                labelPosition='right'
                                                icon='checkmark'
                                                onClick={() => saveTriggers(newTriggerVal)}
                                                positive
                                              />
                                            </Modal.Actions>
                                          </Modal>
                                        </h3>

                                        <Button as='div' labelPosition='right'>
                                          <Button color='red' basic={isLive == "live" ? false : true}>
                                            <a href={"/robo/details/" + id + "/" + coin1 + "/" + coin2 + "/nsubscribe/" + "live/" + isNative} style={{ color: isLive == "live" ? "white" : "black", }}>

                                              {ADVISORY_PANEL.LIVE_MODE}
                                            </a>
                                          </Button>
                                          <Label as='a' basic={isLive == "test" ? false : true} color='red' pointing='left'>
                                            <a href={"/robo/details/" + id + "/" + coin1 + "/" + coin2 + "/nsubscribe/" + "test/" + isNative} style={{ color: isLive != "live" ? "white" : "black", }}>

                                              {ADVISORY_PANEL.TEST_MODE}
                                            </a>
                                          </Label>
                                        </Button>
                                        {/* </div> */}
                                      </Card.Content>

                                    </Card>
                                  </Card.Group>
                                </Grid.Column>)
                              }

                            </Grid.Row>
                          </Grid>
                        </Segment>
                        {/* <div style={{flex: 1,textAlign:"center"}}> */}

                        {
                          rowData !== null && displayRowData(rowData)
                        }
                        {/* </div> */}



                        {/* <div style={{ flex: 1, textAlign: "center", marginBottom: 10 }}>
                    <Button content='Primary' primary><Link style={{ color: "white" }} to="/">Go back</Link></Button>
                  </div> */}
                      </Card.Group>
                    </div>)
                  }

                  {
                    !loading && detailData == null && (<div style={{ textAlign: "center" }}>
                      No record found <Link to="/robo">Go back</Link>
                    </div>)
                  }
                </Segment>
                {/* </Segment.Group> */}


              </div>
            </Media>
            <Media at="mobile">
              <div className="items">
                {/* <Segment.Group> */}

                <Segment vertical>
                  {
                    loading && (
                      <div style={{ textAlign: "center" }}>Loading...</div>
                    )
                  }
                  {
                    detailData !== null &&
                    (<div>
                      <Card.Group itemsPerRow={1}>
                        <Card color="red">
                          <Card.Content>
                            <Card.Header>
                              {strategyName} {
                                detailCalcData && detailCalcData.sharpRatio !== null && <div>
                                  {/* <Label size="tiny" color="blue">
                          SharpRatio {isNaN(detailCalcData.sharpRatio) ? -0.6 : detailCalcData.sharpRatio.toFixed(1)}
                        </Label> */}
                                  {ratingDisplay()}
                                </div>

                              }
                            </Card.Header>
                            <Card.Meta>
                              {/* <span style={{ color: "teal" }}>Buy</span> | <span style={{ color: "red" }}>Sell</span> */}
                            </Card.Meta>
                            <br />

                            {
                              !isLineGraphLoading && linePriceVals !== null && isShowCalc ?
                                (<div>
                                  <Card.Description style={{ height: "400px" }}>
                                    {
                                      !isLineGraphLoading ? <LineChart data={lineChartData} plotDiff={lineGraphPlotDiff} plotValue={lineGraphPlotVal} isShowYBAxis={true} /> : "Loading..."
                                    }

                                  </Card.Description>
                                  <br />
                                  <div style={{ "textAlign": "center" }}>Note: Exnomy does not guarantee any profit and loss. Previous records does not guarantee any future profits.
                                  </div>
                                  <br />
                                </div>) : <div>
                                  <br />
                                  <div style={{ "textAlign": "center" }}>Not enough data to Plot graph
                                  </div>
                                  <br />
                                </div>
                            }
                          </Card.Content>
                        </Card>
                        <Segment raised>
                          <Grid columns={2} stackable textAlign='center'>

                            <Grid.Row verticalAlign='middle' textAlign='center'>
                              {triggerData && detailData && (
                                <Grid.Column width={10}>
                                  {ADVISORY_PANEL.CREATED_BY} <span style={{ color: "grey" }}> {createdBy.substr(0, 5) + "..." + createdBy.substr(createdBy.length - 3, createdBy.length + 1)} </span> {ADVISORY_PANEL.CREATED_ON} <span style={{ color: "grey" }}>  {dailyTableData && moment(dailyTableData.dateArry[dailyTableData.dateArry.length - 1]).format("ll")}</span>
                                  <br />
                                  <br />
                                  {
                                    detailCalcData && detailCalcData.sharpRatio !== null && linePriceVals !== null && isShowCalc && <Label size="tiny" color="blue">
                                      SharpRatio {isNaN(detailCalcData.sharpRatio) ? -0.6 : detailCalcData.sharpRatio.toFixed(1)}
                                    </Label>
                                  }

                                  {
                                    detailCalcData && profitPercentage !== null && <Label size="tiny" color="blue">
                                      Profit {profitPercentage.toFixed(1)}%
                                    </Label>
                                  }


                                  {
                                    detailData && linePriceVals !== null && isShowCalc && <Label size="tiny" color='blue'>
                                      {ADVISORY_PANEL.CURRENT} {dailyTableData && dailyTableData.positionArry[0] == 1 ? ADVISORY_PANEL.BUY : dailyTableData && dailyTableData.positionArry[0] == 0 ? ADVISORY_PANEL.CLEAR : ADVISORY_PANEL.SELL} {ADVISORY_PANEL.POSITION}

                                    </Label>
                                  }
                                  <br />
                                  <br />
                                  <Label basic color={isSubscribed == "subscribe" ? 'blue' : "grey"} size="tiny">
                                    <Icon name={isSubscribed == "subscribe" ? 'check' : "dot circle"} />
                                    {ADVISORY_PANEL.SUBSCRIBED}
                                  </Label>
                                  <Label color={isFree == "0" ? 'green' : "grey"} basic size="tiny">
                                    <Icon name={isFree == "0" ? 'check' : "dot circle"} />
                                    {ADVISORY_PANEL.FREE}
                                  </Label>
                                  <br />
                                  <br />
                                  <Checkbox label='Toggle Decision' onChange={handleToggleDecision} slider />

                                </Grid.Column>
                              )}
                              {
                                triggerData && detailData && (<Grid.Column width={6} textAlign='center'>
                                  <Card.Group itemsPerRow={1}>
                                    <Card>
                                      <Card.Content extra>
                                        <h3>{ADVISORY_PANEL.ALGO_TRADE} {"     "}
                                          <Popup content={ADVISORY_PANEL.VIEW_CONTENT} position={"below"} trigger={<Label color='orange' basic size="mini" onClick={() => setOpen(true)}>
                                            <Icon name='edit' /> {ADVISORY_PANEL.VIEW_AND_CHANGE}
                                          </Label>} />
                                          <Modal
                                            closeIcon
                                            open={open}
                                            onClose={() => setOpen(false)}
                                            // onOpen={() => setOpen(true)}
                                            size="mini"
                                          >
                                            <Modal.Header>{ADVISORY_PANEL.ALGO_TRADE}</Modal.Header>
                                            <Modal.Content image>
                                              <Modal.Description>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.BUY }}
                                                    labelPosition='right'
                                                    placeholder='Enter buy'
                                                    // onChange={(e) => setValue(e.target.value)}
                                                    value={newTriggerVal.recBuyDirection}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recBuyDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Buy Trigger ' + newTriggerVal.recBuyDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recBuyDirection: val.value })}
                                                  />
                                                </p>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.BUY_CLEAR }}
                                                    labelPosition='right'
                                                    placeholder='Enter clear buy'
                                                    value={newTriggerVal.recBuyClearDirection}
                                                    // onChange={(e) => setValue(e.target.value)}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recBuyClearDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Buy Clear Trigger ' + newTriggerVal.recBuyClearDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recBuyClearDirection: val.value })}
                                                  />
                                                </p>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.SELL }}
                                                    labelPosition='right'
                                                    placeholder='Enter sell'
                                                    value={newTriggerVal.recSellDirection}
                                                    // onChange={(e) => setValue(e.target.value)}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recSellDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Buy Trigger ' + newTriggerVal.recSellDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recSellDirection: val.value })}
                                                  />
                                                </p>
                                                <p>
                                                  {/* <Input
                                                    icon='pencil alternate'
                                                    iconPosition='left'
                                                    label={{ tag: true, content: ADVISORY_PANEL.SELL_CLEAR }}
                                                    labelPosition='right'
                                                    placeholder='Enter clear sell'
                                                    value={newTriggerVal.recSellClearDirection}
                                                    onChange={(e) => setNewTriggerVal({ ...newTriggerVal, recSellClearDirection: e.target.value })}
                                                  /> */}
                                                  <Select
                                                    placeholder={'Sell Clear Trigger ' + newTriggerVal.recSellClearDirection}
                                                    options={triggerOptions}
                                                    onChange={(e, val) => setNewTriggerVal({ ...newTriggerVal, recSellClearDirection: val.value })}
                                                  />
                                                </p>
                                                {/* <p>Is it okay to use this photo?</p> */}
                                              </Modal.Description>
                                            </Modal.Content>
                                            <Modal.Actions>
                                              <Button
                                                content={ADVISORY_PANEL.SAVE}
                                                labelPosition='right'
                                                icon='checkmark'
                                                onClick={() => saveTriggers(newTriggerVal)}
                                                positive
                                              />
                                            </Modal.Actions>
                                          </Modal>
                                        </h3>

                                        <Button as='div' labelPosition='right'>
                                          <Button color='red' basic={isLive == "live" ? false : true}>
                                            <a href={"/robo/details/" + id + "/" + coin1 + "/" + coin2 + "/nsubscribe/" + "live/" + isNative} style={{ color: isLive == "live" ? "white" : "black", }}>

                                              {ADVISORY_PANEL.LIVE_MODE}
                                            </a>
                                          </Button>
                                          <Label as='a' basic={isLive == "test" ? false : true} color='red' pointing='left'>
                                            <a href={"/robo/details/" + id + "/" + coin1 + "/" + coin2 + "/nsubscribe/" + "test/" + isNative} style={{ color: isLive != "live" ? "white" : "black", }}>

                                              {ADVISORY_PANEL.TEST_MODE}
                                            </a>
                                          </Label>
                                        </Button>
                                        {/* </div> */}
                                      </Card.Content>

                                    </Card>
                                  </Card.Group>
                                </Grid.Column>)
                              }

                            </Grid.Row>
                          </Grid>
                        </Segment>
                        {/* <div style={{flex: 1,textAlign:"center"}}> */}

                        {
                          rowData !== null && displayRowData(rowData)
                        }
                        {/* </div> */}



                        {/* <div style={{ flex: 1, textAlign: "center", marginBottom: 10 }}>
                    <Button content='Primary' primary><Link style={{ color: "white" }} to="/">Go back</Link></Button>
                  </div> */}
                      </Card.Group>
                    </div>)
                  }

                  {
                    !loading && detailData == null && (<div style={{ textAlign: "center" }}>
                      No record found <Link to="/robo">Go back</Link>
                    </div>)
                  }
                </Segment>
                {/* </Segment.Group> */}


              </div>
            </Media>
          </MediaContextProvider>

        </Wrap>
    );
  }
};

const Wrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  //border: 1px solid #d9d9d9;
  background-color: ${({ dark }) => dark ? '#263554' : '#ffffff'};
  border:1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  box-sizing: border-box;
  border-radius: 10px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    margin-top: 1.5rem;
  }
`;

export default Panel;