import BigNumber from 'bignumber.js';
import api from '../lib/api';
import TokensConfig from '../assets/js/tokens';

export const updateCurrentMarket = currentMarket => {
  return async dispatch => {
    return dispatch({
      type: 'UPDATE_CURRENT_MARKET',
      payload: { currentMarket }
    });
  };
};

export const updateMarketDuration = duration => {
  return async dispatch => {
    return dispatch({
      type: 'UPDATE_MARKET_DURATION',
      payload: duration 
    });
  };
};

export const loadMarkets = () => {
  return async (dispatch) => {
    const res = await api.get(`/markets`);
    if (res.data.status === 0) {
      const markets = res.data.data.markets.filter(market => market.id.split('-')[0] === TokensConfig.EXCHANGE);
      markets.forEach(formatMarket);
      return dispatch({
        type: 'LOAD_MARKETS',
        payload: { markets }
      });
    }
  };
};

// load current market candle
export const loadMarketCandle = (marketID, startDate, endDate, duration) => {
  return async (dispatch, getState) => {
    const res = await api.get(`/markets/${marketID}/candles?from=${startDate}&to=${endDate}&granularity=${duration}`);
    const currentMarket = getState().market.getIn(['markets', 'currentMarket']);
    if (currentMarket.id === marketID && res.data.status === 0) {
      return dispatch({
        type: 'LOAD_MARKET_CANDLE',
        payload: res.data.data.candles
      });
    }
  };
};

// load current market trade history
export const loadTradeHistory = marketID => {
  return async (dispatch, getState) => {
    const res = await api.get(`/markets/${marketID}/tradeslimit`); //trades
    const currentMarket = getState().market.getIn(['markets', 'currentMarket']);
    if (currentMarket.id === marketID) {
      return dispatch({
        type: 'LOAD_TRADE_HISTORY',
        payload: res.data.data.trades
      });
    }
  };
};

const formatMarket = market => {
  market.gasFeeAmount = new BigNumber(market.gasFeeAmount);
  market.asMakerFeeRate = new BigNumber(market.asMakerFeeRate);
  market.asTakerFeeRate = new BigNumber(market.asTakerFeeRate);
  market.marketOrderMaxSlippage = new BigNumber(market.marketOrderMaxSlippage);
};
