import React from 'react';
import styled from 'styled-components';
import { BigNumber } from 'bignumber.js';
import TokensConfig from '../../../assets/js/tokens';

const MainCard = props => {
  const { symbol, currentPrice, trade, percent, tradeCode, quoteToken, isDarkMode } = props;
  let tokenURL = TokensConfig.BITGO[trade]
    ? process.env.PUBLIC_URL + '/images/crypto_icons/' + trade.toLowerCase() + '.svg'
    : TokensConfig.KLAY[trade]
      ? process.env.PUBLIC_URL + '/images/klay.png'
      : process.env.PUBLIC_URL + '/images/garbage.png';

  if (trade === 'BSV') tokenURL = process.env.PUBLIC_URL + '/images/bsv.png';

  if (trade === 'LNC') tokenURL = process.env.PUBLIC_URL + '/images/lnc.png';

  return (
    <Wrap
      dark={isDarkMode}
      trade={trade}
      onClick={() => {
        window.localStorage.setItem(`currentMarket`, JSON.stringify(tradeCode));
        window.location.href = '/exchange';
      }}>
      <div className="left">
        <div className="coin">
          <img src={tokenURL} alt={trade} style={{ marginBottom: '6px' }} />
          <p className="market">{symbol}</p>
        </div>
        <p className="price">
          <span
            className={`${
              new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).gt(0)
                ? 'up'
                : new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).lt(0)
                  ? 'down'
                  : ''
              }`}>
            {currentPrice === '0' ? 0 : new BigNumber(currentPrice).toFixed(TokensConfig.MARKET_DECIMALS[quoteToken])}
          </span></p>
      </div>
      <div className="right">
        <span
          className={`${
            new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).gt(0)
              ? 'up'
              : new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).lt(0)
                ? 'down'
                : ''
            }`}>
          {new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).eq(0)
            ? '0.00'
            : new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).gt(0)
              ? '+' + Number(new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).toString()).toFixed(2)
              : Number(new BigNumber(percent).multipliedBy(100).dp(2, BigNumber.ROUND_HALF_DOWN).toString()).toFixed(2)}{' '}
          %
        </span>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 219px;
  height: 120px;
  margin-right: 26px;
  //border: 1px solid #d9d9d9;
  border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background-color: ${({ dark }) => dark ? '#263554' : '#ffffff'};
  cursor: pointer;
  .left {
    width: 132px;
    height: 100%;
    padding-left: 15px;
    box-sizing: border-box;
    display: inline-block;
    img {
      width:18px;
      height:18px;
    }
    .market {
      display: inline-block;
      font-size: 15px;
      color: #6666ff;
      margin: 15px 0 0;
      padding-left: 10px;
      background-size: 18px;
      cursor: pointer;
    }
    .price {
      font-size: 20px;
      color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
      margin: 15px 0 0;
    }
    .krw {
      font-size: 13px;
      margin: 6px 0 0;
    }
  }
  .right {
    width: 84px;
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    margin-top: 6px;
    padding-right: 15px;
    line-height: 130px;
    text-align: right;
    font-size: 13px;
    .rate {
      display: inline;
      margin: 0;
      color: #1a1a1a;
      padding-right: 20px;
    }
  }
  .down {
    color: #5fa56f;
  }
  .up {
    color: #ec6856;
  }

  @media (max-width: 1024px) {
    width: 48%;
    height: 6rem;
    margin-right: 4%;
    .left {
      width: 70%;
      height: 100%;
      padding-left: 0.5rem;
      box-sizing: border-box;
      display: inline-block;
      img {
        width:12px;
        height:12px;
        margin-top:6px;
      }
      .market {
        font-size: 0.8rem;
        color: #6666ff;
        margin: 15px 0 0;
        padding-left: 0.5rem;
        background-size: contain;
        cursor: pointer;
      }
      .price {
        font-size: 0.9rem;
        color: #1a1a1a;
        margin: 0.6rem 0 0 0.2rem;
      }
      .krw {
        font-size: 0.6rem;
        margin: 6px 0 0;
      }
    }
    .right {
      width: 30%;
      line-height: 3rem;
      margin-top: 4px;
      font-size: 0.6rem;
      .rate {
        display: inline;
        margin: 0;
        color: #1a1a1a;
        padding-right: 0.5rem;
      }
    }
  }
`;

export default MainCard;
