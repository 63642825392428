export const setAccountsDetails = (data) => {
  return {
    type: "UPDATE_LIST_DATA",
    payload: data,
  };
};

export const setModalOpen = (data) => {
  return {
    type: "OPEN_MODAL",
    payload: data,
  };
};

export const setModalClosed = () => {
  return {
    type: "CLOSE_MODAL",
  };
};

export const updateExtendedRows = (data) => {
  return {
    type: "UPDATE_EXTENDED_ROWS",
    payload: data,
  };
};

export const setCurrentMarket = (data) => {
  return {
    type: "SET_CURRENT_MARKET",
    payload: data,
  };
};
