const getTick = (price, n, isUp) => {
  if (n < 3) {
    n = 10 * n;
  } else if (n < 5) {
    n = 100 * (n - 2);
  } else {
    n = 1000;
  }

  let tick = 10000;
  let priceTenPercent = price / 10 / n;

  while (priceTenPercent > tick) {
    tick *= 10;
  }

  while (priceTenPercent < tick) {
    tick /= 10;
  }

  if (isUp && price >= 50 * tick * n) {
    tick *= 5;
  } else if (price > 50 * tick * n) {
    tick *= 5;
  }

  if (!isUp && priceTenPercent === tick) {
    tick /= 2;
  }
  return tick;
};

export const getNextPrice = (price, n, isUp) => {
  let tick = getTick(price, n, isUp);

  if (isUp === true) {
    price = Math.round(price / tick - 0.4999999999) * tick;
  } else {
    price = Math.round(price / tick + 0.4999999999) * tick;
  }

  return Math.round((tick * (isUp ? 1 : -1) + price) * 100000000000000) / 100000000000000;
};

export const fixedPrice = (price, bestbid, lastprice, n) => {
  let min = price;
  console.log(min)

  if (bestbid !== 0 && bestbid < price) min = bestbid;

  if (lastprice !== 0 && lastprice < price) min = lastprice;

  return Number.parseFloat(price).toFixed(Math.max(0, 1 - Math.log10(getTick(price, n, false))));
};

/**
 * @return {boolean}
 */
export const IsValidOrderPrice = (price, n) => {
  let downPrice = getNextPrice(price * 0.99999999, n, true);
  let upPrice = getNextPrice(price * 1.00000001, n, false);

  return downPrice === upPrice;
  //return getNextPrice(price - 0.00001, n, true) === getNextPrice(price + 0.00001, n, false);
};

const tickQty = (position, d) => {
  let digit = Math.round(Math.log10(position) - d - 0.4999999999);
  return Math.pow(10, digit);
};

export const getNextQty = (qty, balance, d, isUp) => {
  return (
    Math.round(Math.min(balance, Math.max(0, tickQty(balance, d) * (isUp ? 1 : -1) + qty)) * 100000000000000) /
    100000000000000
  );
};

export const thousands_separators = (num) => {
  var num_parts = num.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num_parts.join('.');
}
