import React from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import TokensConfig from '../../assets/js/tokens';
import format from 'date-format';
import {thousands_separators} from '../../lib/helper'

const parseTransactionHash = (hash, Token) => {
  if (hash === '0x')
    return '-NA-'

  if (Token === 'ETH' || Token === 'ETC' || Token === 'LNC' || Token === 'TUSD' || TokensConfig.KLAY[Token] || TokensConfig.ERC20[Token])
    return hash
  else
    return hash.replace('0x', '')

}

const HistoryItem = props => {
  const { data, decimals, langJson, Token, index, isDarkMode } = props;
  const { DW } = langJson;
  const numberOfDecimalsToRoundOff = TokensConfig.DECIMALS[Token].UI_DECIMAL;

  return (
    <Wrap style={{ background: `${isDarkMode ? index % 2 === 0 ? '' : '' : index % 2 === 0 ? '#f2f4fd' : ''}` }} dark={isDarkMode}>
      {data[1] ? (
        <div className="type deposit">{DW.DEPOSIT}</div>
      ) : (
          <div className="type withdrawal">{DW.WITHDRAW}</div>
        )}
      <div className="amount">
        {new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).gte(new BigNumber('1000')) ? thousands_separators(new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).toFixed(0)) :
          new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).gte(new BigNumber('100')) ? new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).toFixed(1) :
          new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).gte(new BigNumber('1')) ? new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).toFixed(3) :
            new BigNumber(new BigNumber(data[2].toString()) / Math.pow(10, decimals)).toFixed(numberOfDecimalsToRoundOff)
        }
      </div>
      <div className="time">{format('yy.MM.dd hh:mm', new Date(data[3] * 1000))}</div>
      <div
        className="status">
        {data[5] >= 10 ? (data[5] >= 20 && data[5] <= 29) ? DW.REJECTED : DW.COMPLETED : DW.PENDING}
      </div>
      <div
        className={`txID ${data[6] === '0x' ? '' : 'select'}`}
        onClick={() => {
          if (data[6] === '0x')
            return;
          window.open(`${TokensConfig.TXN_ID[Token]}${parseTransactionHash(data[6], Token)}`, '_blank');
        }}>
        {parseTransactionHash(data[6], Token)}
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 100%;
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}

  .type {
    font-size: 12px;
    width: 15%;
    text-align: center;
  }
  .type.deposit {
    color: #5fa56f;
  }
  .type.withdrawal {
    color: #ec6859;
  }
  .amount {
    font-size: 12px;
    color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
    width: 25%;
    text-align: center;
  }
  .time {
    width: 25%;
    font-size: 12px;
    color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
    text-align: center;
  }
  .status {
    width: 20%;
    text-align: center;
    font-size: 12px;
    color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    cursor: pointer;
  }
  .txID {
    width: 15%;
    text-align: center;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
  }
  .select {
    color: #6666ff;
  }
`;

export default HistoryItem;
