import React from 'react'
import ExchangePage from './ExchangePage';
import WalletPage from './WalletPage';
import Dashboard from './Dashboard';
import RoboAdvisory from './RoboAdvisory';
import Guide from './Static/guide';
import Fee from './Static/fee';
import TermsOfUse from './Static/terms';
import DepositWithdrawHistory from '../components/Wallet/DepositWithdrawHistoryMobile';
import RoboAccountDetails from './RoboAdvisoryDetailsPage';

// import MyAdvisoryPage from "./MyAdvisoryPage";

import { Switch, Route } from 'react-router-dom';
import ServiceDiscontinuation from './Static/serviceDiscontinuation';

export const AppRoute = () => {

    return (
        <Switch>
            {/* <Route exact path="/" component={Dashboard} /> */}
            {/* <Route exact path="/exchange" component={ExchangePage} /> */}
            <Route exact path="/" component={WalletPage} />
            <Route exact path="/guide" component={Guide} />
            <Route exact path="/fee" component={Fee} />
            <Route exact path="/terms-and-privacy" component={TermsOfUse} />
            {/* <Route exact path="/deposit-withdraw-records" component={DepositWithdrawHistory} />
            <Route exact path="/robo" component={RoboAdvisory} />
            <Route exact path="/robo/details/:id/:coin1/:coin2/:isSubscribed/:isLive/:isNative" component={RoboAccountDetails} /> */}
            <Route exact path="/service-discontinuation" component={ServiceDiscontinuation} />
            {/* <Route exact path="/robo/myadvisory"  component={MyAdvisoryPage} /> */}
            <Route path="*" component={WalletPage} />
        </Switch>
    )

}