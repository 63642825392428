import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  isDarkMode: state.theme.isDarkMode
});

class ExchangePage extends React.PureComponent {
  render() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
        <Notice>
          <h1>Service Discontinued</h1>
          <p>We regret to inform you that this service has been discontinued on July 8, 2024. Thank you for your support.</p>
        </Notice>
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ dark }) => dark ? '#1c2b3a' : '#f9fbfd'};
`;

const Notice = styled.div`
  text-align: center;
  color: ${({ dark }) => dark ? '#fff' : '#000'};
`;

export default connect(mapStateToProps)(ExchangePage);
