import React from 'react';
import PanelItem from './elements/PanelItem';
import PerfectScrollbar from 'perfect-scrollbar';
import styled from 'styled-components';
import { BigNumber } from 'bignumber.js';
import { connect } from 'react-redux';
import TokensConfig from '../../assets/js/tokens';

const mapStateToProps = state => {
  const langJson = state.language.langJson;
  return {
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    markets: state.market.getIn(['markets', 'data']),
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

const sortPanel = (array, sortType, orderBy = 1) => {
  if (sortType === 'coin') {
    return array.sort((a, b) => {
      if (a.baseToken > b.baseToken) {
        return orderBy;
      } else {
        return orderBy * -1;
      }
    });
  } else if (sortType === 'price') {
    return array.sort((a, b) => {
      if (a.lastPrice > b.lastPrice) {
        return orderBy;
      } else {
        return orderBy * -1;
      }
    });
  } else if (sortType === 'percent') {
    return array.sort((a, b) => {
      if (new BigNumber(a.price24h).toFixed(2) > new BigNumber(b.price24h).toFixed(2)) {
        return orderBy;
      } else {
        return orderBy * -1;
      }
    });
  } else if (sortType === 'volume') {
    return array.sort((a, b) => {
      if (new BigNumber(a.quoteTokenVolume24h) > new BigNumber(b.quoteTokenVolume24h)) {
        return orderBy;
      } else {
        return orderBy * -1;
      }
    });
  } else {
    return array;
  }
};

const getSortIcon = (currentType, currentOrder, type) => {
  return (
    <div className="sort">
      <i className={`xi-caret-up-min ${currentType === type && currentOrder === -1 ? 'on' : 'off'}`} />
      <i className={`xi-caret-down-min ${currentType === type && currentOrder === 1 ? 'on' : 'off'}`} />
    </div>
  );
};

class Panel extends React.PureComponent {
  constructor(props) {
    super(props);
    //const { currentMarket } = this.props;
    this.state = {
      tokenList: [],
      selectedToken: 'BTC',
      search: '',
      sortType: null,
      order: 1
    };
  }

  componentDidMount() {
    const { markets } = this.props;

    let tokenList = [];
    tokenList = markets.map(obj => obj.quoteToken.toString());
    this.setState({ tokenList: tokenList.filter((v, i) => tokenList.indexOf(v) === i) });
  }

  componentDidUpdate(prevProps) {
    const { currentMarket } = this.props;

    if (currentMarket !== prevProps.currentMarket) this.setState({ selectedToken: currentMarket.quoteToken });
  }

  render() {
    const { markets, langJson, isDarkMode } = this.props;
    const { PANEL, SELECTOR } = langJson;
    const exp = new RegExp(this.state.search, 'i');

    return (
      <Wrap dark={isDarkMode}>
        {/* <Wrap dark={isDarkMode}> */}
        <div className="baseTab">
          {this.state.tokenList.reverse().map(obj => {
            return (
              <div
                className={`base ${obj === this.state.selectedToken ? 'select' : ''}`}
                key={obj}
                onClick={() => this.setState({ selectedToken: obj })}>
                {TokensConfig.CONSTANT.APPEND}
                {obj} Market
              </div>
            );
          })}
          <div className="search">
            <input
              type="text"
              placeholder={SELECTOR.SEARCH}
              className="searchInput"
              value={this.state.search}
              onChange={e => this.setState({ search: e.target.value })}
            />
            <i className="xi-search" />
          </div>
        </div>
        <div className="header">
          <div
            onClick={() => {
              const type = 'coin';
              if (this.state.sortType === type) {
                this.setState({ order: this.state.order * -1 });
              } else {
                this.setState({
                  sortType: type,
                  order: -1
                });
              }
            }}>
            {PANEL.COIN} {getSortIcon(this.state.sortType, this.state.order, 'coin')}
          </div>
          <div
            className="price"
            onClick={() => {
              const type = 'price';
              if (this.state.sortType === type) {
                this.setState({ order: this.state.order * -1 });
              } else {
                this.setState({
                  sortType: type,
                  order: -1
                });
              }
            }}>
            {PANEL.PRICE} ({TokensConfig.CONSTANT.APPEND}
            {this.state.selectedToken}) {getSortIcon(this.state.sortType, this.state.order, 'price')}
          </div>
          <div
            onClick={() => {
              const type = 'percent';
              if (this.state.sortType === type) {
                this.setState({ order: this.state.order * -1 });
              } else {
                this.setState({
                  sortType: type,
                  order: -1
                });
              }
            }}>
            {PANEL.CHANGE} {getSortIcon(this.state.sortType, this.state.order, 'percent')}
          </div>
          <div
            className="volume"
            onClick={() => {
              const type = 'volume';
              if (this.state.sortType === type) {
                this.setState({ order: this.state.order * -1 });
              } else {
                this.setState({
                  sortType: type,
                  order: -1
                });
              }
            }}>
            {PANEL.VOLUME} ({TokensConfig.CONSTANT.APPEND}
            {this.state.selectedToken}) {getSortIcon(this.state.sortType, this.state.order, 'volume')}
          </div>
        </div>

        <div className="items">
          {sortPanel(markets, this.state.sortType, this.state.order)
            .filter(item => (this.state.search ? item.baseToken.search(exp) >= 0 : true))
            .map(market => {
              if (market.quoteToken === this.state.selectedToken) {
                return (
                  <PanelItem
                    key={`${market.baseToken}/${market.quoteToken}`}
                    coin={market.baseToken}
                    quoteToken={market.quoteToken}
                    currentPrice={market.lastPrice}
                    volume={market.quoteTokenVolume24h}
                    percent={market.price24h}
                    isHot={TokensConfig.IS_HOT[market.baseToken]}
                    isNew={TokensConfig.IS_NEW[market.baseToken]}
                    trade={TokensConfig.PR_CODE[market.quoteToken]}
                    market={market}
                    isDarkMode={isDarkMode}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </Wrap>
    );
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}

const Wrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  height: 542px;
  //border: 1px solid #d9d9d9;
  background-color: ${({ dark }) => dark ? '#263554' : '#ffffff'};
  border:1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  box-sizing: border-box;
  margin-top: 42px;
  border-radius: 10px;
  .baseTab {
    width: 100%;
    height: 61px;
    line-height: 61px;
    //border-bottom: 1px solid #d9d9d9;
    border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    box-sizing: border-box;
    .base {
      display: inline-block;
      margin: 0 20px;
      padding: 0 10px;
      font-size: 19px;
      //color: #364958;
      color: ${({ dark }) => dark ? '#8d94af' : '#364958'}
      cursor: pointer;
      box-sizing: border-box;
      height: 60px;
      &.select {
        color: #6666ff;
        border-bottom: 3px solid #6666ff;
      }
    }
    .search {
      width: 300px;
      float: right;
      margin-right: 32px;
      margin-top: 10px;
      height: 42px;
      line-height: 42px;
      box-sizing: border-box;
      font-size: 15px;
      color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
      //border: 1px solid #d9d9d9;
      border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
      border-radius: 10px;
      text-indent: 5px;
      .searchInput {
        height: 38px;
        line-height: 38px;
        text-indent: 10px;
        text-align: left;
        border: 0;
        width: 259px;
        color : ${({ dark }) => (dark ? '#8d94af' : '#676767')};
        background: 0;
        outline: 0;
        vertical-align: top;
      }
      i {
        height: 36px;
        line-height: 35px;
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
  .header {
    width: 100%;
    height: 77px;
    display: flex;
    flex-direction: row;
    //border-bottom: 1px solid #d9d9d9;
    border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    align-items: center;
    div {
      flex: 1;
      text-align: center;
      font-size: 17px;
      //color: #364958;
      color : ${({ dark }) => (dark ? '#8d94af' : '#364958')};
      font-weight: 600;
      cursor: pointer;
      &.price {
        flex: 1;
      }
      &.sort {
        width: 14px;
        height: 28px;
        vertical-align: middle;
        display: inline-block;
        margin-left: 2px;
        i {
          display: block;
          height: 9px;
          font-size: 15px;
          &.off {
            opacity: 0.2;
          }
        }
      }
    }
  }
  .items {
    width: 100%;
    height: 396px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  //div:last-child {
  //    border-bottom : 0;
  //}

  @media (max-width: 1024px) {
    width: 100%;
    height: 20rem;
    margin-top: 1.5rem;
    .baseTab {
      height: 3rem;
      line-height: 3rem;
      .base {
        display: inline-block;
        margin: 0 0.5rem;
        padding: 0 0.5rem;
        font-size: 0.9rem;
        box-sizing: border-box;
        height: 3rem;
      }
      .search {
        display: none;
      }
    }
    .header {
      width: 100%;
      height: 3rem;
      align-items: center;
      div {
        font-size: 0.8rem;
        &.volume {
          display: none;
        }
        &.sort {
          width: 1rem;
          i {
            font-size: 1rem;
          }
        }
      }
    }
    .items {
      width: 100%;
      height: 14rem;
      box-sizing: border-box;
      overflow-y: auto;
    }
  }
`;

export default connect(mapStateToProps)(Panel);
