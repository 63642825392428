import env from './env';
const abi_DEX_ADMIN = require('./abi_DEX_ADMIN.json');
const abi_DEXHI = require('./abi_DEXHI.json');
const abi_DW = require('./abi_DW.json');
const abi_Hydro = require('./abi_Hydro.json');
const abi_Robo = require('./abi_Robo.json');

const config = {
  contract: {
    DEX_ADMIN: 1,
    DEXHI: 2,
    DW: 3,
    Hydro: 4,
    Robo:5,
  },
  contractAddress: {
    DEX_ADMIN: env.DEX_ADMIN,
    DEXHI: env.DEXHI,
    DW: env.DW,
    Hydro: env.HYDRO,
    Robo:env.ROBO
  },
  abi: {
    DEX_ADMIN: abi_DEX_ADMIN,
    DEXHI: abi_DEXHI,
    DW: abi_DW,
    Hydro: abi_Hydro,
    Robo:abi_Robo
  },
  WebsocketProvider: 'ws://172.17.0.1:8552',
  HttpProvider: env.NODE_URL
};

//module.exports = config;
export default config;
