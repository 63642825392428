export const toDoOperation = (
  Action,
  Token,
  AvailableBalance,
  TotalBalance,
  LockedBalance,
  tokenAddress,
  tokenType,
  decimals
) => ({
  type: 'WALLET_OPERATION',
  Action,
  Token,
  AvailableBalance,
  TotalBalance,
  LockedBalance,
  tokenAddress,
  tokenType,
  decimals
});
