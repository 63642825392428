const theme = (
  state = {
    isDarkMode: localStorage.getItem('displayMode') ? localStorage.getItem('displayMode') === 'true'? true : false : false,
  },
  action
) => {
  switch (action.type) {
    case 'CHANGE_MODE':
      localStorage.setItem('displayMode', action.displayMode);
      return {
        isDarkMode: action.displayMode,
      };
    default:
      return state;
  }
};

export default theme;
