/* eslint-disable prefer-rest-params */
import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import 'chartjs-plugin-zoom';
// import { lineChartOptions } from './config';

const Line = ({ data, shadow = false, plotValue, plotDiff, isShowYBAxis }) => {
  // console.log("dkdl", data.labels.length >60)
  const chartContainer = useRef(null);
  const [, setChartInstance] = useState(null);

  const chartTooltip = {
    backgroundColor: "grey",
    titleFontColor: "black",
    borderColor: "grey",
    borderWidth: 0,
    bodyFontColor: "black",
    bodySpacing: 10,
    xPadding: 15,
    yPadding: 15,
    cornerRadius: 0.15,
  };

  const lineChartOptions = {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontColor: '#333',
        usePointStyle:true,
    }
      
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: chartTooltip,
    zoom: {
      enabled: true,
      mode: 'x',
    },
    pan: {
      enabled: true,
      mode: 'x',
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          gridLines: {
            display: true,
            lineWidth: 1,
            color: 'rgba(0,0,0,0.1)',
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: plotDiff,
            min: plotValue.minVal,
            max: plotValue.maxVal,
            padding: 20,
            fontColor: "#5259ea",
          },
        },
        {
          id: 'B',
          display:isShowYBAxis,
          gridLines: {
            display: false,
            lineWidth: 1,
            color: 'rgba(0,0,0,0.1)',
            drawBorder: false,
          },
          position: 'right',
          ticks: {
            // beginAtZero: true,
            stepSize: 1,
            // min: -10,
            max: 10,
            padding: 20,
            fontColor: "orange",
          },
        },
      ],
      xAxes: [
      {
        type: 'time',
        time:
        {
          unit: "day",
        }
        // {
        //   unit: data.labels.length >= 90 ? 'month' : data.labels.length >= 30 ?"week":"day",
        // }
      },
      // {
      //   gridLines: {
      //     display: false,
      //   },
      // },
      ],
    },
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (shadow) {
        Chart.controllers.lineWithShadow = Chart.controllers.line;
        Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
          draw(ease) {
            Chart.controllers.line.prototype.draw.call(this, ease);
            const {
              chart: { ctx },
            } = this;
            ctx.save();
            ctx.shadowColor = 'rgba(0,0,0,0.15)';
            ctx.shadowBlur = 10;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 10;
            ctx.responsive = true;
            ctx.stroke();
            Chart.controllers.line.prototype.draw.apply(this, arguments);
            ctx.restore();
          },
        });
      }
      const context = chartContainer.current.getContext('2d');
      const newChartInstance = new Chart(context, {
        type: shadow ? 'lineWithShadow' : 'line',
        options: lineChartOptions,
        data,
      });
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, data, shadow]);

  return <canvas ref={chartContainer} />;
};

export default Line;
