import React from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import { loadTokens } from '../../actions/account';
import { toUnitAmount } from '../../lib/utils';
import { stateUtils } from '../../selectors/account';
import { depositKLAY } from '../../lib/wallet';
import { getSelectedAccount, getUserName, signIn } from 'exnomy-wallet';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NewDepositAddress, NewWithdrawRequest, withdrawKlay } from '../../lib/bitGoActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import coinAddressValidator from 'coin-address-validator';
import TokensConfig from '../../assets/js/tokens';
import { css } from 'glamor';
import DepositWithdrawHistory from './DepositWithdrawHistory';
import { BigNumber } from 'bignumber.js';
import { confirm } from '../Common/CommonAlert';
import { verify } from '../Common/CommonOTP';
import { loadingActions } from '../../actions/loading'
import config from '../../lib/config';
import { getSContract } from '../../lib/utils';

const get_address_fromlocal = (address, tokenAddress) => {
  let p = JSON.parse(localStorage.getItem('itemsArray'));
  let answer = 0;
  if (p) {
    p.map(obj => {
      if (obj.address === address && obj.token_address === tokenAddress) {
        answer = obj.deposit_address;
        return false;
      }
      return true;
    });
  }

  return answer;
};

const validateWithdrawAddress = (coinName, address,additionalInfo) => {
  const address2 = address.split('?')[0];
  try {
    if(additionalInfo==='')return false
    if (coinAddressValidator.validate(address2, coinName, 'both')) return true;
  } catch (err) {
    return false;
  }
};

const validateAmount = (amount, balance) => {
  if (Number(amount) < 0 || Number(amount) > Number(balance)) {
    return false;
  } else return true;
};

const validateMinAmount = (amountToSend, minAmount) => {
  if (Number(amountToSend) < 0 || Number(amountToSend) < Number(minAmount)) {
    return false;
  } else return true;
};

const constLimitDecimal = (noOfDecimal, inputValue) => {
  let regex = new RegExp("^[0-9]{1,10}([.,][0-9]{1,10})?$")
  return regex.test(Number(inputValue))
}

const mapStateToProps = state => {
  const DepositAddressInfo = state.DepositAddress;
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  const langJson = state.language.langJson;
  const tokenAddress = state.wallet.tokenAddress;
  const loading = state.loading;
  return {
    tokensInfo: stateUtils.getTokensInfo(state, address),
    address,
    DepositAddressInfo,
    ethBalance: toUnitAmount(state.WalletReducer.getIn(['accounts', selectedAccountID, 'balance']), 18),
    langJson,
    tokenAddress,
    loading,
    isDarkMode: state.theme.isDarkMode
  };
};

class ChildDeposit extends React.PureComponent {
  notify = TOAST => {
    toast.success(TOAST.ADDRESS_COPIED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify2 = TOAST => {
    toast.error(TOAST.ENTER_VALID_ADDRESS, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify3 = TOAST => {
    toast.error(TOAST.ENTER_VALID_AMOUNT, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify4 = TOAST => {
    toast.success(TOAST.DEPOSIT_ADDRESS_CREATED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        background: '#6666ff !important',
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify5 = TOAST => {
    toast.error(TOAST.DEPOSIT_ADDRESS_NOT_CREATED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify6 = TOAST => {
    toast.error(TOAST.CONNECT_ACCOUNT, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify7 = TOAST => {
    toast.error(TOAST.LOGIN_ACCOUNT, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify8 = DW => {
    toast.error(DW.KLAY_WARNING, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      amount: '0',
      toAddress: '',
      additionalInfo: '',
      depositChanges: 0,
      withdrawFee: "0",
      minWithdrawAmount: "0",
      selectedTab: 0
    };
  }

  getMinWithdrawFee = async () => {
    const { decimals, tokenAddress } = this.props;
    const minWithdrawFee = await getSContract(config.contract.DEX_ADMIN).methods.GetMinWithdrawFee(this.returnPRCode(tokenAddress)).call();
    this.setState({
      withdrawFee: new BigNumber(minWithdrawFee).dividedBy(new BigNumber(Math.pow(10, decimals))).toString()
    })
  }

  getMinWithdrawAmount = async () => {
    const { decimals, tokenAddress } = this.props;
    const minWithdrawAmount = await getSContract(config.contract.DEXHI).methods.GetMinWithdrawAmount(this.returnPRCode(tokenAddress)).call();
    this.setState({
      minWithdrawAmount: new BigNumber(minWithdrawAmount).dividedBy(new BigNumber(Math.pow(10, decimals))).toString()
    })
  }

  returnPRCode = (tokenAddress) => {
    let regex = "(0x[0]*)([0-9]*)"
    const condition = new RegExp(regex, 'g');
    return condition.exec(tokenAddress)[2]
  }

  componentDidMount() {
    const { address, dispatch, tokenAddress } = this.props;
    if (address) {
      dispatch(loadTokens());
    }
    if (tokenAddress) {
      this.getMinWithdrawAmount()
      this.getMinWithdrawFee()
    }
  }

  componentDidUpdate(prevProps) {
    const { address, dispatch, Token, Action, tokenAddress } = this.props;
    if (address && address !== prevProps.address) {
      dispatch(loadTokens());
      this.setState({
        amount: "0",
        toAddress: "",
        additionalInfo: "",
        depositChanges: 0
      });
    }

    if (tokenAddress && tokenAddress !== prevProps.tokenAddress) {
      this.getMinWithdrawAmount()
      this.getMinWithdrawFee()
      this.setState({
        amount: "0",
        toAddress: "",
        additionalInfo: "",
        depositChanges: 0,
        selectedTab: 0
      });
    }

    if (Token && Token !== prevProps.Token && Action === 'Withdraw') {
      this.setState({
        amount: '',
        toAddress: '',
        additionalInfo: '',
        depositChanges: 0,
        selectedTab: 0
      });
    }
  }

   returnMax(balance,fee) {
        if (balance >= fee )
        {
          const digit = Math.trunc(Math.log10(fee)) - 2;

          const mult = Math.pow(10, -digit)
          


          const maxWithdraw = Math.trunc((balance - fee) * mult) / mult
          return maxWithdraw
        }else{
          return 0
        }
  }

  withdrawHandler = async () => {
    const { Token, dispatch, address, tokenAddress, decimals, langJson } = this.props;
    const { TOAST, POP_UP } = langJson;

    dispatch(loadingActions("WITHDRAW_LOADING", true))
    if (localStorage.getItem(`HydroWallet:lastSelectedWalletType`) === 'CENTRALIZED_WALLET') {
      const userName = await getUserName();
      signIn(userName)
        .then(async (res) => {
          if (!await verify(userName, res, POP_UP.VERIFY , POP_UP.CANCEL, {msg: POP_UP.OTP_MESSAGE})) {
            dispatch(loadingActions("WITHDRAW_LOADING", false))
          } else {
            dispatch(
              NewWithdrawRequest(
                address,
                Token,
                tokenAddress,
                new BigNumber(this.state.amount).multipliedBy(Math.pow(10, decimals)).toString(),
                Token === 'XRP' ? this.state.toAddress +
                  '?' +
                  TokensConfig.EXTRA_PARAMS[Token]['SYMBOL'] +
                  this.state.additionalInfo : this.state.toAddress,
                TOAST
              )
            );
            this.setState({
              amount: "0",
              toAddress: "",
              additionalInfo: ""
            });
          }
        }).catch((err) => {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            className: css({
              textAlign: 'center',
              width: '280px',
              fontSize: '15px'
            })
          });
          dispatch(loadingActions("WITHDRAW_LOADING", false))
        })
    } else {
      dispatch(
        NewWithdrawRequest(
          address,
          Token,
          tokenAddress,
          new BigNumber(this.state.amount).multipliedBy(Math.pow(10, decimals)).toString(),
          Token === 'XRP' ? this.state.toAddress +
            '?' +
            TokensConfig.EXTRA_PARAMS[Token]['SYMBOL'] +
            this.state.additionalInfo : this.state.toAddress,
          TOAST
        )
      );
      this.setState({
        amount: "0",
        toAddress: "",
        additionalInfo: "",
        selectedTab: 0
      });
    }

  }

  render() {
    const { Action, Token, Balance, dispatch, address, tokenAddress, tokenType, decimals, langJson, loading, isDarkMode } = this.props;
    const { DW, TOAST, POP_UP } = langJson;
    const demicalsForTextField = Math.log10(this.state.minWithdrawAmount) < 0 ? Math.abs(Math.log10(this.state.minWithdrawAmount)) : 0
    let name = DW.GENERATE_ADDRESS;
    if (Action === 'Withdraw') {
      if (tokenType === 'BITGO') {
        return (
          <Wrap1 Action={Action} dark={isDarkMode}>
            <div className="daw-img" />
            <div className="parent">
              <div className="daw-address">
                <div className="daw-address-content">
                  <div className="daw-address-label">{DW.ADDRESS_PLACEHOLDER}</div>
                  <input
                    className="Address_input"
                    type="text"
                    color="#6666ff"
                    value={this.state.toAddress}
                    onChange={e => {
                      if(Token==='XRP' && e.target.value.includes("rBPaKhXzkwuSjEq98vYeor6Z5uHyQBCW5H")){
                        alert("XRP Withdrawal between exnomy address are blocked Please enter a different address")
                      }else if(Token==='TUSD'  &&  /^0x[a-fA-F0-9]{40}$/g.test(e.target.value)!==true){
                        alert("Wrong Address!! Please Enter ERC-20 Address")
                      }
                      else{
                        this.setState({ toAddress: e.target.value });
                      }
                      
                    }}></input>
                </div>
              </div>

              {this.renderAddtionalInfo()}

              <div className="daw-amount" style={{ marginTop: '20px' }}>
                <div className="daw-amount-content">
                  <div className="daw-amount-label">{DW.AMOUNT_PLACEHOLDER}</div>
                  <input
                    className="Amount_input"
                    type="text"
                    value={this.state.amount}
                    onChange={e => {
                
                      if (!constLimitDecimal(demicalsForTextField, e.target.value))
                        return

                      this.setState({ amount: e.target.value });
                    }}></input>
                  <div className="daw-amount-type">{Token}</div>
                </div>
              </div>

              <div className="daw-percent-btns-row">
                <div
                  className={`daw-percent-btn ${this.state.selectedTab === 1 ? 'select' : ''}`}
                  onClick={() => {
                    this.setState({ selectedTab: 1 })
                    const balanceICanWithdraw = (Balance - Number(this.state.withdrawFee) > 0)? Balance - Number(this.state.withdrawFee) : 0
                    let amountStr = (0.25 * balanceICanWithdraw).toFixed(demicalsForTextField);
                    if (amountStr <= 0) amountStr = 0;
                    this.setState({ amount: amountStr });
                  }}>
                  25%
                </div>
                <div
                  className={`daw-percent-btn ${this.state.selectedTab === 2 ? 'select' : ''}`}
                  onClick={() => {
                    this.setState({ selectedTab: 2 })
                    const balanceICanWithdraw = (Balance - Number(this.state.withdrawFee) > 0)? Balance - Number(this.state.withdrawFee) : 0
                    let amountStr = (0.5 * balanceICanWithdraw).toFixed(demicalsForTextField);
                    if (amountStr <= 0) amountStr = 0;
                    this.setState({ amount: amountStr });
                  }}>
                  50%
                </div>
                <div
                  className={`daw-percent-btn ${this.state.selectedTab === 3 ? 'select' : ''}`}
                  onClick={() => {
                    this.setState({ selectedTab: 3 })
                    const balanceICanWithdraw = (Balance - Number(this.state.withdrawFee) > 0)? Balance - Number(this.state.withdrawFee) : 0
                    let amountStr = (0.75 * balanceICanWithdraw).toFixed(demicalsForTextField);
                    if (amountStr <= 0) amountStr = 0;
                    this.setState({ amount: amountStr });
                  }}>
                  75%
                </div>
                <div
                  className={`daw-percent-btn2 ${this.state.selectedTab === 4 ? 'select' : ''}`}
                  onClick={() => {
                    this.setState({ selectedTab: 4 })

                    const max  =this.returnMax(Balance,this.state.withdrawFee)
                    this.setState({ amount: max });
                  }}>
                  MAX
                </div>
              </div>

              <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', fontSize: '12px' }}>{DW.MIN_WITHDRAW_AMT} {this.state.minWithdrawAmount} {Token}</div>
                <div style={{ width: '50%', textAlign: 'end', fontSize: '12px' }}>{DW.WITHDRAW_FEE} {this.state.withdrawFee} {Token}</div>
              </div>

              {(loading.withdraw_loading === false) ? (
                <button
                  className="withdraw_button"
                  onClick={async () => {
                    if (window.localStorage.getItem(`accData-${address}`) == null) {
                      this.notify6(TOAST);
                      return;
                    }
                    if (window.localStorage.getItem(`loginData-${address}`) == null) {
                      this.notify7(TOAST);
                      return;
                    }

                    if (Token === 'EOS' || Token === 'LNC' || Token === 'TUSD') {
                      if (!this.state.toAddress || this.state.toAddress === "") {
                        this.notify2(TOAST);
                        return;
                      }
                      if (!validateMinAmount(this.state.amount, this.state.minWithdrawAmount)) {
                        this.notify3(TOAST);
                        return;
                      }
                      if (!validateAmount(new BigNumber(this.state.amount).plus(new BigNumber(this.state.withdrawFee)).toString(), Balance)) {
                        this.notify3(TOAST);
                        return;
                      }
                      if (localStorage.getItem(`HydroWallet:lastSelectedWalletType`) === 'CENTRALIZED_WALLET' &&
                        (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{DW.WITHDRAW_NOTE1} {this.state.amount} {Token} {DW.WITHDRAW_NOTE2}</span>, POP_UP.OK, POP_UP.CANCEL,{ backgroundColor: "#ec6856" }))) {
                        return;
                      }
                      this.withdrawHandler()
                    } else if (TokensConfig.EXTRA_PARAMS[Token]) {
                      if (
                        !validateWithdrawAddress(
                          Token,
                          this.state.toAddress +
                          '?' +
                          TokensConfig.EXTRA_PARAMS[Token]['SYMBOL'] +
                          this.state.additionalInfo,this.state.additionalInfo
                        )
                      ) {
                        this.notify2(TOAST);
                        return;
                      }
                      if (!validateMinAmount(this.state.amount, this.state.minWithdrawAmount)) {
                        this.notify3(TOAST);
                        return;
                      }
                      if (!validateAmount(new BigNumber(this.state.amount).plus(new BigNumber(this.state.withdrawFee)).toString(), Balance)) {
                        this.notify3(TOAST);
                        return;
                      }
                      if (localStorage.getItem(`HydroWallet:lastSelectedWalletType`) === 'CENTRALIZED_WALLET' &&
                        (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{DW.WITHDRAW_NOTE1} {this.state.amount} {Token} {DW.WITHDRAW_NOTE2}</span>, POP_UP.OK, POP_UP.CANCEL,{ backgroundColor: "#ec6856" }))) {
                        // this.setState({
                        //   amount: "0",
                        //   toAddress: "",
                        //   additionalInfo: ""
                        // });
                        return;
                      }
                      this.withdrawHandler()
                    } else {
                      if (!validateWithdrawAddress(Token, this.state.toAddress)) {
                        this.notify2(TOAST);
                        return;
                      }
                      if (!validateMinAmount(this.state.amount, this.state.minWithdrawAmount)) {
                        this.notify3(TOAST);
                        return;
                      }
                      if (!validateAmount(new BigNumber(this.state.amount).plus(new BigNumber(this.state.withdrawFee)).toString(), Balance)) {
                        this.notify3(TOAST);
                        return;
                      }
                      if (localStorage.getItem(`HydroWallet:lastSelectedWalletType`) === 'CENTRALIZED_WALLET' &&
                        (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{DW.WITHDRAW_NOTE1} {this.state.amount} {Token} {DW.WITHDRAW_NOTE2}</span>, POP_UP.OK, POP_UP.CANCEL,{ backgroundColor: "#ec6856" }))) {
                        return;
                      }
                      this.withdrawHandler()
                    }

                  }}>
                  {DW.WITHDRAW}
                </button>
              ) : (
                  <button
                    className="withdraw_button"
                    disabled
                  >
                    {DW.WITHDRAW}
                  </button>
                )}
            </div>
            <div className="notice" style={{ marginTop: '48px' }}>
              <img
                src={require('../../images/warning.png')}
                alt="dw_image"
                width="14"
                height="14"
                style={{ marginBottom: '2px' }}
              />
              <p className="img_para text-danger" style={{ display: 'inline-block', paddingLeft: '4px' }}>
                {DW.NOTE}
              </p>
              <ul style={{ marginLeft: '-20px' }}>
                <li>
                  {' '}
                  {DW.WITHDRAW_NOTE_PART1}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.WITHDRAW_NOTE_PART2}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.WITHDRAW_NOTE_PART3}
                </li>
              </ul>
            </div>
          </Wrap1>
        );
      } else {
        return (
          <Wrap1 Action={Action} dark={isDarkMode}>

            <div className="daw-img " />
            <div className="parent">
              <div className="daw-amount" style={{ marginTop: '40px' }}>
                <div className="daw-amount-content">
                  <div className="daw-amount-label">{DW.AMOUNT_PLACEHOLDER}</div>
                  <input
                    className="Amount_input"
                    type="text"
                    value={this.state.amount}
                    onChange={e => {
                 

                      if (!constLimitDecimal(demicalsForTextField, e.target.value))
                        return

                      this.setState({ amount: e.target.value });
                    }}></input>
                  <div className="daw-amount-type">{Token}</div>
                </div>
              </div>

              <div className="daw-percent-btns-row">
                <div className={`daw-percent-btn ${this.state.selectedTab === 1 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 1 })
                  const balanceICanWithdraw = (Balance - Number(this.state.withdrawFee) > 0)? Balance - Number(this.state.withdrawFee) : 0
                  let amountStr = (0.25 * balanceICanWithdraw).toFixed(demicalsForTextField);
                  if (amountStr <= 0) amountStr = 0;
                  this.setState({ amount: amountStr });
                }}>
                  25%
                </div>
                <div className={`daw-percent-btn ${this.state.selectedTab === 2 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 2 })
                  const balanceICanWithdraw = (Balance - Number(this.state.withdrawFee) > 0)? Balance - Number(this.state.withdrawFee) : 0
                  let amountStr = (0.5 * balanceICanWithdraw).toFixed(demicalsForTextField);
                  if (amountStr <= 0) amountStr = 0;
                  this.setState({ amount: amountStr });
                }}>
                  50%
                </div>
                <div className={`daw-percent-btn ${this.state.selectedTab === 3 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 3 })
                  const balanceICanWithdraw = (Balance - Number(this.state.withdrawFee) > 0)? Balance - Number(this.state.withdrawFee) : 0
                  let amountStr = (0.75 * balanceICanWithdraw).toFixed(demicalsForTextField);
                  if (amountStr <= 0) amountStr = 0;
                  this.setState({ amount: amountStr });
                }}>
                  75%
                </div>
                <div className={`daw-percent-btn2 ${this.state.selectedTab === 4 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 4 })
                  const max  =this.returnMax(Balance,this.state.withdrawFee)
                  this.setState({ amount: max });
                }}>
                  MAX
                </div>
              </div>

              <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', fontSize: '12px' }}>{DW.MIN_WITHDRAW_AMT} {this.state.minWithdrawAmount} {Token}</div>
                <div style={{ width: '50%', textAlign: 'end', fontSize: '12px' }}>{DW.WITHDRAW_FEE} {this.state.withdrawFee} {Token}</div>
              </div>

              {(loading.withdraw_loading === false) ? (
                <button className="withdraw_button" style={{ marginTop: '24px' }} onClick={async () => {
                  if (!validateMinAmount(this.state.amount, this.state.minWithdrawAmount)) {
                    this.notify3(TOAST);
                    return;
                  }
                  if (!validateAmount(new BigNumber(this.state.amount).plus(new BigNumber(this.state.withdrawFee)).toString(), Balance)) {
                    this.notify3(TOAST);
                    return;
                  }

                  if (Token === 'KLAY' && localStorage.getItem(`HydroWallet:lastSelectedWalletType`) !== 'Hydro_Wallet') this.notify8(DW);
                  else {
                    if (localStorage.getItem(`HydroWallet:lastSelectedWalletType`) === 'CENTRALIZED_WALLET' &&
                      (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{DW.WITHDRAW_NOTE1} {this.state.amount} {Token} {DW.WITHDRAW_NOTE2}</span>, POP_UP.OK, POP_UP.CANCEL, { backgroundColor: "#ec6856" }))) {
                      return;
                    }
                    dispatch(loadingActions("WITHDRAW_LOADING", true))
                    dispatch(withdrawKlay(this.state.amount, TOAST));
                    this.setState({
                      amount: "0",
                      selectedTab: 0
                    });
                  }
                }
                }>
                  {DW.WITHDRAW}
                </button>
              ) : (
                  <button className="withdraw_button" style={{ marginTop: '24px' }} disabled>
                    {DW.WITHDRAW}
                  </button>
                )}
            </div>

            <div className="notice" style={{ marginTop: '48px' }}>
              <img
                src={require('../../images/warning.png')}
                alt="dw_image"
                width="14"
                height="14"
                style={{ marginBottom: '2px' }}
              />
              <p className="img_para text-danger" style={{ display: 'inline-block', paddingLeft: '4px' }}>
                {DW.NOTE}
              </p>
              <ul style={{ marginLeft: '-20px' }}>
                <li>
                  {' '}
                  {DW.WITHDRAW_NOTE_PART1}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.WITHDRAW_NOTE_PART2}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.WITHDRAW_NOTE_PART3}
                </li>
              </ul>
            </div>
          </Wrap1>
        );
      }
    } else if (Action === 'Deposit') {
      if (tokenType === 'BITGO') {
        if (
          Action === 'Deposit' &&
          (get_address_fromlocal(address, tokenAddress) === 0 || get_address_fromlocal(address, tokenAddress) === '')
        ) {
          return (
            <Wrap1 Action={Action} dark={isDarkMode}>

              <div className="daw-img" />
              <div className="parent">
                <div className="title_address">
                  {' '}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.DEPOSIT_ADDRESS}
                </div>

                <p className="para">
                  {DW.TO_CREATE_NOTE1}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.TO_CREATE_NOTE2}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.TO_CREATE_NOTE3}
                  {DW.TO_CREATE_NOTE4}
                </p>

                {(loading.deposit_loading === false) ? (
                  <button
                    className="deposit_button"
                    onClick={() => {
                      if (window.localStorage.getItem(`accData-${address}`) == null) {
                        this.notify6(TOAST);
                        return;
                      }
                      if (window.localStorage.getItem(`loginData-${address}`) == null) {
                        this.notify7(TOAST);
                        return;
                      }

                      dispatch(loadingActions("DEPOSIT_LOADING", true))
                      dispatch(NewDepositAddress(tokenAddress, Token)).then(result => {
                        if (!isNaN(result)) {
                          if (result > 0) {
                            this.notify4(TOAST);
                            this.setState({
                              depositChanges: this.state.depositChanges + 1
                            });
                          } else {
                            this.notify5(TOAST);
                          }
                        } else {
                          this.notify5(TOAST);
                        }
                      });
                    }}>
                    {name}
                  </button>
                ) : (
                    <button
                      className="deposit_button"
                      disabled>
                      {name}
                    </button>
                  )}
              </div>

              <div className="notice">
                <img
                  src={require('../../images/warning.png')}
                  alt="dw_image"
                  width="14"
                  height="14"
                  style={{ marginBottom: '2px' }}
                />
                <p className="img_para text-danger" style={{ display: 'inline-block', paddingLeft: '4px' }}>
                  {DW.NOTE}
                </p>
                <ul style={{ marginLeft: '-20px' }}>
                  <li>
                    {' '}
                    {DW.DEPOSIT_NOTE_PART3}
                    {TokensConfig.CONSTANT.APPEND}
                    {Token}
                    {DW.DEPOSIT_NOTE_PART4}
                  </li>
                  <li>
                    {' '}
                    {DW.DEPOSIT_NOTE_PART5}
                    {TokensConfig.CONSTANT.APPEND}
                    {Token}
                    {DW.DEPOSIT_NOTE_PART6}
                  </li>
                </ul>
              </div>
            </Wrap1>
          );
        } else {
          return (
            <Wrap1 Action={Action} dark={isDarkMode}>

              <div className="daw-img " />
              <div className="parent">
                <div className="title_address">
                  {' '}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.DEPOSIT_ADDRESS}
                </div>
                <div className="Qrcode">
                  <div className="qr_code">
                    <QRCode value={get_address_fromlocal(address, tokenAddress)} />
                  </div>
                </div>

                <div style={{ width: '100%', textAlign: 'center' }}>
                  <div className="nav round_border">
                    <CopyToClipboard text={get_address_fromlocal(address, tokenAddress)}>
                      <div className="deposit_address" onClick={() => this.notify(TOAST)}>
                        {get_address_fromlocal(address, tokenAddress)}
                      </div>
                    </CopyToClipboard>
                    <CopyToClipboard text={get_address_fromlocal(address, tokenAddress)}>
                      <img
                        className="img-copy"
                        src={process.env.PUBLIC_URL + '/images/copy.png'}
                        alt="Copy"
                        width="18"
                        height="18"
                        onClick={() => this.notify(TOAST)}
                      />
                    </CopyToClipboard>
                  </div>
                </div>
              </div>

              <div className="notice">
                <img
                  src={require('../../images/warning.png')}
                  alt="dw_image"
                  width="14"
                  height="14"
                  style={{ marginBottom: '2px' }}
                />
                <p className="img_para text-danger" style={{ display: 'inline-block', paddingLeft: '4px' }}>
                  {DW.NOTE}
                </p>
                <ul style={{ marginLeft: '-20px' }}>
                  <li>
                    {' '}
                    {DW.DEPOSIT_NOTE_PART1}
                    {TokensConfig.CONSTANT.APPEND}
                    {Token}
                    {DW.DEPOSIT_NOTE_PART2}
                  </li>
                </ul>
              </div>
            </Wrap1>
          );
        }
      } else {
        return (
          <Wrap1 Action={Action} dark={isDarkMode}>
            <div className="guide">
              <div className="left text-danger">{DW.WARNING}</div>
              <div className="right">{DW.KLAY_WARNING}</div>
            </div>
            <div className="daw-img " />
            <div className="parent">
              <div className="daw-amount" style={{ marginTop: '40px' }}>
                <div className="daw-amount-content">
                  <div className="daw-amount-label">{DW.AMOUNT_PLACEHOLDER}</div>
                  <input
                    className="Amount_input"
                    type="text"
                    value={this.state.amount}
                    onChange={e => {
                 

                      if (!constLimitDecimal(demicalsForTextField, e.target.value))
                        return

                      this.setState({ amount: e.target.value });
                    }}></input>
                  <div className="daw-amount-type">{Token}</div>
                </div>
              </div>

              <div className="daw-percent-btns-row">
                <div className={`daw-percent-btn ${this.state.selectedTab === 1 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 1 })
                  let amountStr = (0.25 * Balance).toFixed(demicalsForTextField);
                  if (amountStr <= 0) amountStr = 0;
                  this.setState({ amount: amountStr });
                }}>
                  25%
                </div>
                <div className={`daw-percent-btn ${this.state.selectedTab === 2 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 2 })
                  let amountStr = (0.5 * Balance).toFixed(demicalsForTextField);
                  if (amountStr <= 0) amountStr = 0;
                  this.setState({ amount: amountStr });
                }}>
                  50%
                </div>
                <div className={`daw-percent-btn ${this.state.selectedTab === 3 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 3 })
                  let amountStr = (0.75 * Balance).toFixed(demicalsForTextField);
                  if (amountStr <= 0) amountStr = 0;
                  this.setState({ amount: amountStr });
                }}>
                  75%
                </div>
                <div className={`daw-percent-btn2 ${this.state.selectedTab === 4 ? 'select' : ''}`} onClick={() => {
                  this.setState({ selectedTab: 4 })
                  const max  =this.returnMax(Balance,this.state.withdrawFee)
                  this.setState({ amount: max });
                }}>
                  MAX
                </div>
              </div>

              {(loading.deposit_loading === false) ? (
                <button
                  className="deposit_button"
                  style={{ marginTop: '40px' }}
                  onClick={async () => {
                    if (Token === 'KLAY' && localStorage.getItem(`HydroWallet:lastSelectedWalletType`) !== 'Hydro_Wallet') {
                      this.notify8(DW)
                    }
                    else if (Token === 'KLAY') {
                      if (localStorage.getItem(`HydroWallet:lastSelectedWalletType`) === 'CENTRALIZED_WALLET' &&
                        (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{DW.WITHDRAW_NOTE1} {this.state.amount} {Token} {DW.WITHDRAW_NOTE2}</span>, POP_UP.OK, POP_UP.CANCEL,{ backgroundColor: "#ec6856" }))) {
                        return;
                      }
                      dispatch(loadingActions("DEPOSIT_LOADING", true))
                      dispatch(depositKLAY(this.state.amount, TOAST));
                      this.setState({
                        amount: "0",
                        selectedTab: 0
                      });
                    }
                  }}>
                  {DW.DEPOSIT}
                </button>
              ) : (
                  <button
                    className="deposit_button"
                    style={{ marginTop: '40px' }}
                    disabled>
                    {DW.DEPOSIT}
                  </button>
                )}
            </div>

            <div className="notice">
              <img
                src={require('../../images/warning.png')}
                alt="dw_image"
                width="14"
                height="14"
                style={{ marginBottom: '2px' }}
              />
              <p className="img_para text-danger" style={{ display: 'inline-block', paddingLeft: '4px' }}>
                {DW.NOTE}
              </p>
              <ul style={{ marginLeft: '-20px' }}>
                <li>
                  {' '}
                  {DW.DEPOSIT_NOTE_PART1}
                  {TokensConfig.CONSTANT.APPEND}
                  {Token}
                  {DW.DEPOSIT_NOTE_PART2}
                </li>
              </ul>
            </div>
          </Wrap1>
        );
      }
    } else {
      return (
        <Wrap1 Action={Action}>
          <DepositWithdrawHistory Token={Token} decimals={decimals} />
        </Wrap1>
      );
    }
  }

  renderAddtionalInfo() {
    const { Token, langJson } = this.props;
    const { DW } = langJson;
    if (TokensConfig.EXTRA_PARAMS[Token]) {
      return (
        <div className="daw-address" style={{ marginTop: '20px' }}>
          <div className="daw-address-content">
            <div className="daw-address-label" style={{ width: '144px' }}>
              {TokensConfig.EXTRA_PARAMS[Token]['FIELD'] === 'Enter the Destination Tag'
                ? DW.DEST_TAG_PLACEHOLDER
                : DW.MEMO_ID_PLACEHOLDER}
            </div>
            <input
              className="Address_input"
              type="text"
              color="#6666ff"
              value={this.state.additionalInfo}
              onChange={e => {
                this.setState({ additionalInfo: e.target.value });
              }}></input>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
const Wrap1 = styled.div`
  width: 450px;
  @media (max-width: 1024px) {
    width:100%;
  }
  height: 171px;
  min-height: 171px;
  .guide {
    font-size: 13px;
    margin-left :32px;
    padding-top: 12px;
    text-align: justify;
    margin-right :32px;
    .left {
        width: 40px; 
        display:inline-block;
        text-align: right;
        vertical-align : top;
    }
    .right {
        padding-left:8px;
        width: 346px;
        display:inline-block;
    }
  }
  .parent{
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 10px;
    height:316px;
    padding-bottom: 20px;
  }
  .title_address{
    font-size: 15px;
    text-align:center;
    //color: #343a40;
    color: ${({ dark }) => (dark ? '#8d94af' : '#343a40')};
   }

  .para{
    margin-top:16px;
    color: ${({ dark }) => (dark ? 'white' : '#696969')};
    //color: #696969;
  }

  .deposit_button {
    margin-top: 16px;
      width: 100%;
      height: 48px;
      font-size: 15px;
      border-style:none;
      color: #ffffff;
      border-radius: 8px;
      background: #5fa57f;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active,
      &:visited{
        box-shadow: none;
            border: transparent;
            outline:none !important;
    }
  }
  .daw-address {
    position: relative;
    border-radius: 8px;
    border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    padding: 0 8px 0 8px;

    .daw-address-label {
      font-size: 12px;
      width: 50px;
      color: #abb4e5;
    }

    .daw-address-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 40px;
      .daw-amount-balance {
        font-size: 16px;
        color: #364958;
        line-height: 40px;
        outline: none;
        border: none;
        background: none;
        text-align: right;
        width: 100%;
      }
      .Address_input{
        width: 100%;
        height:40px;
        border: none;
        color: ${({ dark }) => (dark ? 'white' : 'black')};
        background-color:transparent;
        padding: 10px 10px;
        text-align: right;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        cursor: text;
        &:hover,
        &:focus,
        &:active,
        &:visited{
          box-shadow: none;
          border: transparent;
          outline:none !important;
        }
    }
      .daw-address-type {
        font-size: 12px;
        color: #abb4e5;
        margin-left: 12px;
      }
    }
  }
  .daw-amount {
    position: relative;
    border-radius: 8px;
    border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    padding: 0 8px 0 8px;

    .daw-amount-label {
      font-size: 12px;
      width: 50px;
      color: #abb4e5;
    }

    .daw-amount-content {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      width: 100%;
      height: 40px;
      .daw-amount-balance {
        font-size: 16px;
        color: #364958;
        line-height: 40px;
        outline: none;
        border: none;
        background: none;
        text-align: right;
        width: 100%;
      }
      .Amount_input{
        width: 100%;
        height:40px;
        border: none;
        color: ${({ dark }) => (dark ? 'white' : 'black')};
        background-color:transparent;
        border-radius: 8px;
        padding: 10px 10px;
        text-align: right;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        cursor: text;
        &:hover,
        &:focus,
        &:active,
        &:visited{
          box-shadow: none;
          border: transparent;
          outline:none !important;
        }
      
      }
      .daw-amount-type {
        font-size: 12px;
        color: #abb4e5;
        margin-left: 12px;
      }
    }
  }
  .daw-percent-btns-row {
		box-sizing: border-box;
    margin-top: 25px;
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 30px;

		.daw-percent-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			width: 96px;
			height: 30px;
			margin-right: 20px;
			font-size: 12px;
      border-radius: 8px;
      border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
			color: #6666ff;
      cursor: pointer;
      &.select {
        background: #6666ff;
        color : #ffffff;
      }
    }}

    .daw-percent-btn2 {
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			width: 96px;
			height: 30px;
			font-size: 12px;
      border-radius: 8px;
      border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
			color: #6666ff;
      cursor: pointer;
      &.select {
        background: #6666ff;
        color : #ffffff;
      }
    }}
  .notice{
    font-size: 13px;
    height:112px
    color: ${({ dark }) => (dark ? '#8d94af' : '#676767')}
    border-top: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    padding-top: 12px
    padding-left: 32px;
    padding-right: 32px;
  }
  .Qrcode{
    box-sizing: border-box;
    margin-top: 30px;
    text-align:center;
    width: 100%;
  }
  .round_border{
    border-radius:360px;
    display: inline-block;
    height: 40px;
    margin-top: 16px;
    border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  }
  .deposit_address{
    font-size: 0.7rem;
    text-align: center;
    line-height:40px;
    display: inline-block;
    padding-left : 16px;
    padding-right: 8px;
    font-weight: bold;
    border-right: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
    color: ${({ dark }) => (dark ? 'white' : '#696969')};
    //color: #696969;
    cursor:pointer;
   }
   .img-copy{
    cursor:pointer;
    float:right;
    margin-top:10px;
    margin-left:8px;
    margin-right:16px;
  }
  .daw-img {
    box-sizing: border-box;
    width: 100%;
    margin-top:30px;
    height: 100px;
    background-image: ${({ Action }) =>
    Action === 'Deposit'
      ? `url(${process.env.PUBLIC_URL}'images/deposit.png')`
      : `url(${process.env.PUBLIC_URL}'images/withdraw.png')`};
    background-repeat: no-repeat;
    background-position: center;
  }
  .withdraw_button {
    margin-top: 24px;
      width: 100%;
      height: 48px;
      border-style:none;
      font-size: 15px;
      color: #ffffff;
      border-radius: 8px;
      background: #ec6856;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active,
      &:visited{
        box-shadow: none;
            border: transparent;
            outline:none !important;
    }
  }
  .table_min{
    width: 70%;
    overflow-x: auto;
    table {
        width: 100%;
        margin: 20px 0 32px;
        td {
            padding: 10px 0;
            border-left: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'} !important;
            font-size: 14px;
            width: 50%;
            text-align: center;
            border-bottom : 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'} !important;  
            :first-child {
                border-left: none !important;
            }
        }
    }
    
  `;

export default connect(mapStateToProps)(ChildDeposit);
