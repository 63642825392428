const wallet = (
  state = {
    Action: 'Deposit',
    Token: 'BTC',
    AvailableBalance: 0,
    TotalBalance: 0,
    LockedBalance: 0,
    tokenAddress: '',
    tokenType: 'BITGO',
    decimals: 8
  },
  action
) => {
  switch (action.type) {
    case 'WALLET_OPERATION':
      return {
        Action: action.Action,
        Token: action.Token,
        AvailableBalance: action.AvailableBalance,
        TotalBalance: action.TotalBalance,
        LockedBalance: action.LockedBalance,
        tokenAddress: action.tokenAddress,
        tokenType: action.tokenType,
        decimals: action.decimals
      };
    default:
      return state;
  }
};

export default wallet;
