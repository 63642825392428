let _env = process.env;

if (!_env.REACT_APP_API_URL) {
  _env = window._env;
}

export default {
  API_ADDRESS: _env.REACT_APP_API_URL,
  WS_ADDRESS: _env.REACT_APP_WS_URL,
  NODE_URL: _env.REACT_APP_NODE_URL,
  SWAGGER:_env.REACT_APP_SWAGGER,
  NETWORK_ID: _env.REACT_APP_NETWORK_ID,
  BITGO_API_URL: _env.REACT_APP_BITGO_API_SERVER,
  PUBLIC_KEY: _env.REACT_APP_PUBLIC_KEY,
  PRIVATE_KEY: _env.REACT_APP_PRIVATE_KEY,
  DEX_ADMIN: _env.REACT_APP_DEX_ADMIN,
  DEXHI: _env.REACT_APP_DEXHI,
  DW: _env.REACT_APP_DW,
  HYDRO:_env.REACT_APP_HYDRO
};
