import React from 'react';
import styled from 'styled-components';
import InfoItem from './elements/InfoItem';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';


const mapStateToProps = state => {
  const lang = state.language.lang;
  const langJson = state.language.langJson;
  return {
    lang,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class Info extends React.PureComponent {
  render() {
    const { langJson, lang, isDarkMode } = this.props;
    const { INFO } = langJson;
    return (
      <Wrap dark={isDarkMode}>
        <div className="sud">
          {isDarkMode ? (
              <img src={require('../../images/testdark.svg').default} />
          ): (
            <img src={require('../../images/test.svg').default} />
          )}
        </div>
        <div className="logo" />
        <div className="title">{INFO.ROBO_TITLE}</div>
        {/* <div className="subTitle">{INFO.SUBTITLE}</div> */}
        <Container textAlign='center'>
          <div className="imageArea">
            {getInfoItem(INFO, lang, isDarkMode)}
          </div>
        </Container>
      </Wrap>
    );
  }
}

const getInfoItem = (INFO, lang, isDarkMode) => {
  let content = [];
  for (let i = 1; i < 4; i++) {
    content.push(
      <InfoItem
        isDarkMode={isDarkMode}
        lang={lang}
        img={`info${i}.png`}
        title={INFO[`ROBO_INFO${i}_TITLE`]}
        description={INFO[`ROBO_INFO${i}_DESCRIPTION`]}
        key={INFO[`ROBO_INFO${i}_TITLE`]}
      />
    );
  }
  return content;
};

const Wrap = styled.section`
  width: 100%;
  height: 900px;
  position: relative;
  top: 150px;
  background: ${({ dark }) => dark ? '#1c2b3a' : '#f6f6ff'};
  .logo {
    background: url(${require('../../images/exnomy_logo.png').default}) no-repeat;
    background-size: 100%;
    background-position-y: bottom;
    margin: 100px auto 0;
    padding-top: 100px;
    width: 200px;
    height: 65px;
  }
  .sud {
    // background: url(${require('../../images/test.svg').default}) no-repeat !important;
    width: 100%;
    display: block;
    overflow: hidden;
    vertical-align: middle;
    margin: 0 0 -9px;
    background: #1a1c35;
  }
  .sud img {
    background: ${({ dark }) => dark ? '#1c2b3a' : '#fff'};
    // background: #fff;
  }
  .title {
    text-align: center;
    font-size: 22px;
    color: #364958;
    font-weight: bold;
  }
  .subTitle {
    margin-top: 10px;
    font-size: 15px;
    color: #364958;
    text-align: center;
  }
  .imageArea {
    width: 100%;
    margin: 80px auto 0;
    // div:nth-child(even) {
    //   margin-right: 0;
    // }
  }
  .list-group-item {
    background-color: #f6f6ff !important;
    border: 0px solid !important;
  }
  .row: before {
    content: '';
    position: absolute;
    left: 12.5%;
    right: 0;
    width: 50%;
    height: 2px;
    background: #a6a8d0;
    top: 99px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export default connect(mapStateToProps)(Info);
