import env from './env';
import config from './config';
import Web3 from 'web3';
import Caver from 'caver-js'

export const getSContract = (contractN ,accountAddress )=> {
  const web3 = new Web3(config.HttpProvider);
  //const account = web3.eth.accounts.wallet.add('0x7A47C6AAC6803D036E9C97F21624A4B2C4792791A2573F051ACF0CB68CFBCFD5')

  let contract;
  switch (contractN) {
    case 1:
      contract = new web3.eth.Contract(config.abi.DEX_ADMIN, config.contractAddress.DEX_ADMIN, {
        from: accountAddress, gasPrice: '25000000000'
      });
      break;
    case 2:
      contract = new web3.eth.Contract(config.abi.DEXHI, config.contractAddress.DEXHI, { 
        from: accountAddress, gasPrice: '25000000000' 
      });
      break;
    case 3:
      contract = new web3.eth.Contract(config.abi.DW, config.contractAddress.DW, {from: accountAddress, gasPrice: '25000000000' });
      break;
    case 4:
      contract = new web3.eth.Contract(config.abi.Hydro, config.contractAddress.Hydro, { from: accountAddress,gasPrice: '25000000000' });
      break;
    case 5:
      contract = new web3.eth.Contract(config.abi.Robo, config.contractAddress.Robo, { from: accountAddress,gasPrice: '25000000000' });
      break;
    default:
      break;
  }
  return contract;
};

export const getSContractWS = contractN => {
  const caver = new Caver(env.WS_ADDRESS);
  const account = caver.klay.accounts.wallet.add('0x7A47C6AAC6803D036E9C97F21624A4B2C4792791A2573F051ACF0CB68CFBCFD5')
  let contract;
  switch (contractN) {
    case 1:
      contract = new caver.klay.Contract(config.abi.DEX_ADMIN, config.contractAddress.DEX_ADMIN, {
        gasPrice: '25000000000'
      });
      break;
    case 2:
      contract = new caver.klay.Contract(config.abi.DEXHI, config.contractAddress.DEXHI, { gasPrice: '25000000000' });
      break;
    case 3:
      contract = new caver.klay.Contract(config.abi.DW, config.contractAddress.DW, { gasPrice: '25000000000' });
      break;
    case 4:
      contract = new caver.klay.Contract(config.abi.Hydro, config.contractAddress.Hydro, { gasPrice: '25000000000' });
      break;
    default:
      break;
  }
  return contract;
};