import React from 'react';
import styled from 'styled-components';
import { languageActions } from '../../actions/languageActions';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';

const mapStateToProps = state => {
  const lang = state.language.lang;
  const langJson = state.language.langJson;
  return {
    lang,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class LangSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectOpen: false
    };
  }

  toggleDropdown() {
    this.setState({ selectOpen: !this.state.selectOpen });
  }

  // Method automatically executed by the library.
  handleClickOutside() {
    if (this.state.selectOpen) this.toggleDropdown();
  }

  render() {
    const { dispatch, lang, langJson, isMobile, isDarkMode ,onClose} = this.props;
    const { HEADER } = langJson;
    return (
      <Wrap onClick={() => this.toggleDropdown()} style={{ color:`${isDarkMode? '#8d94af': 'black'}` }} isMobile={isMobile}>
        {lang === 'EN' ? HEADER.LANGUAGE : lang === 'KR' ? HEADER.LANGUAGE : HEADER.LANGUAGE}
        <SelectTab open={this.state.selectOpen} isMobile={isMobile}>
          <div
            className={`menu ${lang === 'EN' && 'select'}`}
            onClick={() => dispatch(languageActions('changeToEN', 'EN')) && onClose && onClose()}>
            English <i className={`${lang === 'EN' && 'xi-check'}`} />
          </div>
          {/* <div
            className={`menu ${lang === 'KR' && 'select'}`}
            onClick={() => dispatch(languageActions('changeToKR', 'KR')) && onClose &&  onClose()}>
            한국어 <i className={`${lang === 'KR' && 'xi-check'}`} />
          </div> */}
          <div
            className={`menu ${lang === 'CH' && 'select'}`}
            onClick={() => dispatch(languageActions('changeToCH', 'CH')) &&  onClose && onClose()}>
            中文 <i className={`${lang === 'CH' && 'xi-check'}`} />
          </div>
        </SelectTab>
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  padding-left: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  font-size: 16px;
  height: 17px;
  line-height: 17px;
  margin-right: 8px;
  color: ${({ isMobile }) => (isMobile ? '#fff !important' : '#676767')};
  background: ${({ isMobile }) =>
    isMobile ? 'none' : `url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/language_icon.png)`};
  background-repeat: no-repeat;
  background-position-x: '20px';
  background-position-y: center;
  border-right: '1px solid black';
`;

const SelectTab = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  z-index: 9999;
  height: 100px;
  width: 96px;
  border: 1px solid #d9d9d9;
  margin-left: -33px;
  background: #fff;
  transition: 0.3s;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  .menu {
    height: 40px;
    line-height: 40px;
    text-align: left;
    margin-left: 4px;
    &.select {
      color: #6666ff;
    }
    &.false {
      color: black;
    }
    i {
      font-weight: bold;
    }
  }
`;

export default connect(mapStateToProps)(onClickOutside(LangSelect));
