import React, { Fragment } from 'react';
import { loginRequest, login } from '../../actions/account';
import { AddAccount, VerifyAccount, checkWallet } from '../../lib/bitGoActions';
import { connect } from 'react-redux';
import { WalletButton, getSelectedAccount, signOut } from 'exnomy-wallet';
import './styles.scss';
import { loadAccountHydroAuthentication } from '../../lib/session';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Route } from 'react-router-dom';
import LangSelect from './LangSelect';
import { css } from 'glamor';
import { themeAction } from '../../actions/themeAction'
import MediaQuery from 'react-responsive';
import { slide as Menu } from 'react-burger-menu';
import { confirm } from '../Common/CommonAlert'
import env from '../../lib/env';


const mapStateToProps = state => {
  console.log(state)
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  const lang = state.language.lang;
  const langJson = state.language.langJson;
  return {
    selectedAccount,
    address,
    isLocked: selectedAccount ? selectedAccount.get('isLocked') : true,
    isLoggedIn: state.account.getIn(['isLoggedIn', address]),
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    markets: state.market.getIn(['markets', 'data']),
    lang,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

class Header extends React.PureComponent {
  notify = TOAST => {
    toast.error(TOAST.SOMETHING_WENT_WRONG, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };

  notify1 = TOAST => {
    toast.success(TOAST.CONNECTION_SUCCESS, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
      className: css({
        background: '#6666ff !important',
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };

  notify2 = TOAST => {
    toast.error(TOAST.SOMETHING_WENT_WRONG, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      addAccount: 0,
      mobileOpen: false,
      isWalletUnlocked: false,
      isDarkMode: false,
      isConnected: 0,
      isNative: false
    };

  }

  componentDidMount() {
    const { address, dispatch } = this.props;
    const hydroAuthentication = loadAccountHydroAuthentication(address);
    if (hydroAuthentication) {
      dispatch(login(address));
    }

    if (window.location.toString().indexOf("isnative") != -1) { this.setState({ isNative: true }) }
  }
  componentDidUpdate(prevProps) {
    const { address, dispatch, isLoggedIn, langJson } = this.props;
    const { TOAST } = langJson;
    const hydroAuthentication = loadAccountHydroAuthentication(address);
    if (address !== prevProps.address && hydroAuthentication) {
      dispatch(login(address));
    }


    if (isLoggedIn && address) {
      if (localStorage.getItem(`accData-${address}`)) {
        //empty
      } else 
      if (this.state.isWalletUnlocked) {
        dispatch(VerifyAccount()).then(result => {
          if (!isNaN(result)) {
            window.localStorage.setItem(`accData-${address}`, JSON.stringify(result));
            this.setState(prevState => {
              return { addAccount: prevState.addAccount + 1 }
            });
          }
        });
        if (localStorage.getItem(`loginData-${address}`)) {
          dispatch(AddAccount()).then(result => {
            if (!isNaN(result)) {
              if (Number(result) > 0) {
                window.localStorage.setItem(`accData-${address}`, JSON.stringify(result));
                this.setState(prevState => {
                  return { addAccount: prevState.addAccount + 1 }
                });
                this.notify1(TOAST);
              } else {
                this.notify2(TOAST);
              }
            } else {
              this.notify2(TOAST);
            }
          });
        } else {
          this.notify(TOAST);
        }
      }
    } else if (address && this.state.isWalletUnlocked && this.state.isConnected === 0) {
      dispatch(loginRequest())
    }

  }


  render() {
    return (
      <div>
        {
          !this.state.isNative && <div><MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
            <MediaQuery minWidth={1024} maxWidth={1365}>
              {this.renderLaptop()}
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
              {this.renderTablet()}
            </MediaQuery>
            <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery></div>
        }

      </div>
    );
  }

  renderDesktop() {
    const { langJson, dispatch, isDarkMode } = this.props;
    const { HEADER } = langJson;

    return (
      <Wrap dark={isDarkMode}>
        <div className="content navbar">
          {/* <Route
            render={({ history }) => (
              <img
                className="navbar-brand"
                src={isDarkMode ? require('../../images/logo_dark.png').default : require('../../images/exnomy_logo.png').default}
                alt="exnomy"
                style={{ cursor: 'pointer' }}
                width={isDarkMode ? '120px' : '120px'}
                height={isDarkMode ? '180%' : '90%'}
                onClick={() => history.push('/')}
              />
            )}
          /> */}

          <div
            className="navbar-nav mr-auto"
            style={{
              width: '468px',
              alignItems: 'center'
            }}>
            <Navbar variant="light">
              <Nav className="mr-auto">
                {/* <NavLink
                  to={'/'}
                  activeClassName="activeLink"
                  activeStyle={{ color: '#525aee', fontSize: '16px', fontWeight: 'normal' }}
                  className="nav-link"
                  style={{ width: '102px', fontSize: '16px', color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
                  {HEADER.EXCHANGE}
                </NavLink> */}
                {/* <NavLink
                  to={'/robo'}
                  activeClassName="activeLink"
                  activeStyle={{ color: '#525aee', fontSize: '16px', fontWeight: 'normal' }}
                  className="nav-link"
                  style={{ width: '102px', fontSize: '16px', color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
                  {HEADER.ADVISORY}
                </NavLink> */}
                {/* <NavLink
                  to={'/wallet'}
                  activeClassName="activeLink"
                  activeStyle={{ color: '#525aee', fontSize: '16px', fontWeight: 'normal' }}
                  className="nav-link"
                  style={{ width: '102px', fontSize: '16px', color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
                  {HEADER.WALLET}
                </NavLink> */}

              </Nav>
            </Navbar>
          </div>

          {this.renderLogout()}

          {this.renderAccount()}

          {/* {this.renderDocumentLink()} */}

          <LangSelect isMobile={false} />

          <span>|</span>

          <div className="switch">
            <button className={`bright ${!isDarkMode && 'select'}`} onClick={() => dispatch(themeAction(false))}><i className="xi-sun" /></button>
            <button className={`dark ${isDarkMode && 'select'}`} onClick={() => dispatch(themeAction(true))}><i className="xi-moon" /></button>
          </div>
        </div>
      </Wrap>
    );
  }

  renderLaptop() {
    const { langJson, dispatch, isDarkMode } = this.props;
    const { HEADER } = langJson;

    return (
      <Wrap dark={isDarkMode}>
        <div className="content navbar">
          {/* <Route
            render={({ history }) => (
              <img
                className="navbar-brand"
                src={isDarkMode ? require('../../images/logo_dark.png').default : require('../../images/exnomy_logo.png').default}
                alt="exnomy"
                style={{ cursor: 'pointer' }}
                width={isDarkMode ? '120px' : '120px'}
                height={isDarkMode ? '180%' : '90%'}
                onClick={() => history.push('/')}
              />
            )}
          /> */}

          <div
            className="navbar-nav mr-auto"
            style={{
              width: '468px',
              alignItems: 'center'
            }}>
            <Navbar variant="light">
              <Nav className="mr-auto">
                {/* <NavLink
                  to={'/'}
                  activeClassName="activeLink"
                  activeStyle={{ color: '#525aee', fontSize: '16px', fontWeight: 'normal' }}
                  className="nav-link"
                  style={{ width: '102px', fontSize: '16px', color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
                  {HEADER.EXCHANGE}
                </NavLink> */}
                {/* <NavLink
                  to={'/robo'}
                  activeClassName="activeLink"
                  activeStyle={{ color: '#525aee', fontSize: '16px', fontWeight: 'normal' }}
                  className="nav-link"
                  style={{ width: '102px', fontSize: '16px', color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
                  {HEADER.ADVISORY}
                </NavLink> */}
                {/* <NavLink
                  to={'/wallet'}
                  activeClassName="activeLink"
                  activeStyle={{ color: '#525aee', fontSize: '16px', fontWeight: 'normal' }}
                  className="nav-link"
                  style={{ width: '102px', fontSize: '16px', color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
                  {HEADER.WALLET}
                </NavLink> */}
              </Nav>
            </Navbar>
          </div>

          {this.renderLogout()}

          {this.renderAccount()}

          {/* {this.renderDocumentLink()} */}

          <LangSelect isMobile={false} />
          <span>|</span>

          <div className="switch">
            <button className={`bright ${!isDarkMode && 'select'}`} onClick={() => dispatch(themeAction(false))}><i className="xi-sun" /></button>
            <button className={`dark ${isDarkMode && 'select'}`} onClick={() => dispatch(themeAction(true))}><i className="xi-moon" /></button>
          </div>
        </div>
      </Wrap>
    );
  }

  renderTablet() {
    const { langJson,selectedAccount } = this.props;
    const { HEADER } = langJson;
    console.log("tablet")
    return (
      <Wrap>
        <div className="content navbar">
          {/* <Route
            render={({ history }) => (
              <img
                className="navbar-brand"
                src={require('../../images/exnomy_logo.png').default}
                alt="exnomy"
                style={{ cursor: 'pointer' }}
                width="120px"
                height="100%"
                onClick={() => history.push('/')}
              />
            )}
          /> */}
          <div style={{marginLeft:'30vw'}}>
          {(selectedAccount && this.renderAccount())}
          </div>
           
          <Fragment>
            <Menu
              right
              isOpen={this.state.mobileOpen}
              customBurgerIcon={<img src={require('../../images/menu.svg').default} width="32px" height="32px" alt="exnomy" onClick={() => this.setState({ mobileOpen: true })} />}
              customCrossIcon={<i className="xi-close" onClick={() => this.setState({ mobileOpen: false })} />}
              className={'burger'}
              width={'55%'}>
              <div>
                <div onClick={() => this.closeMenu()} >
                    {/* <NavLink
                      to={'/'}
                      activeClassName="activeLink"
                      activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                      className="nav-link"
                      style={{ width: '102px', fontSize: '16px', color: 'white', height: '48px' }}>
                      {HEADER.HOME}
                    </NavLink> */}
                    {/* <NavLink
                      to={'/'}
                      activeClassName="activeLink"
                      activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                      className="nav-link"
                      style={{ width: '102px', fontSize: '16px', color: 'white', height: '48px' }}>
                      {HEADER.EXCHANGE}
                    </NavLink> */}
                    {/* <NavLink
                      to={'/robo'}
                      activeClassName="activeLink"
                      activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                      className="nav-link"
                      style={{ width: '102px', fontSize: '16px', color: 'white',height: '48px'  }}>
                      {HEADER.ADVISORY}
                    </NavLink> */}
                    {/* <NavLink
                        to={'/wallet'}
                        activeClassName="activeLink"
                        activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                        className="nav-link"
                        style={{ width: '102px', fontSize: '16px', color: 'white', height: '48px' }}>
                        {HEADER.WALLET}
                      </NavLink> */}
                      {this.renderLogout()}
                  </div>
                <div className="lang">
                  <i className="xi-globus" />
                  <LangSelect isMobile={true} />
                </div>
              </div>
            </Menu>
          </Fragment>
        </div>
      </Wrap>
    );
  }

  handleStateChange(state) {
    this.setState({ mobileOpen: state.isOpen })
  }

  closeMenu() {
    this.setState({ mobileOpen: false })
  }

  renderMobile() {
    const { selectedAccount, address, isDarkMode } = this.props;
    const { langJson } = this.props;
    const { HEADER } = langJson;
    return (
      <Wrap>
        <div className="content navbar">
          {/* <Route
            render={({ history }) => (
              <img
                className="navbar-brand"
                src={require('../../images/exnomy_logo.png').default}
                alt="exnomy"
                style={{ cursor: 'pointer' }}
                width="120px"
                height="100%"
                onClick={() => history.push('/')}
              />
            )}
          /> */}


          {(selectedAccount && this.renderAccount())}


          {/* {this.renderDocumentLink()} */}
          <Fragment>
            <Menu
              right
              isOpen={this.state.mobileOpen}

              customBurgerIcon={<img src={require('../../images/menu.svg').default} alt="exnomy" onClick={() => this.setState({ mobileOpen: true })} />}
              // customCrossIcon={<i className="xi-close" onClick={() => this.setState({ mobileOpen: false })} />}
              className={'burger'}
              width={'55%'}
              onStateChange={(state) => this.handleStateChange(state)}
            >

              <div>
                <div onClick={() => this.closeMenu()} >
                  {/* <NavLink
                    to={'/'}
                    activeClassName="activeLink"
                    activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                    className="nav-link"
                    style={{ width: '102px', fontSize: '16px', color: 'white', height: '48px' }}>
                    {HEADER.HOME}
                  </NavLink> */}
                  {/* <NavLink
                    to={'/'}
                    activeClassName="activeLink"
                    activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                    className="nav-link"
                    style={{ width: '102px', fontSize: '16px', color: 'white', height: '48px' }}>
                    {HEADER.EXCHANGE}
                  </NavLink> */}
                  {/* <NavLink
                    to={'/robo'}
                    activeClassName="activeLink"
                    activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                    className="nav-link"
                    style={{ width: '102px', fontSize: '16px', color: 'white', height: '48px' }}>
                    {HEADER.ADVISORY}
                  </NavLink> */}
                  {/* <NavLink
                    to={'/wallet'}
                    activeClassName="activeLink"
                    activeStyle={{ color: 'white', fontSize: '16px', fontWeight: 'normal' }}
                    className="nav-link"
                    style={{ width: '102px', fontSize: '16px', color: 'white', height: '48px' }}>
                    {HEADER.WALLET}
                  </NavLink> */}

                  {this.renderLogout()}
                </div>
                <div className="lang">
                  <i className="xi-globus" />
                  <LangSelect isMobile={true} onClose={() => this.closeMenu()} />
                </div>
              </div>
            </Menu>
          </Fragment>
        </div>
      </Wrap>
    );
  }

  renderLogout() {
    const { selectedAccount, langJson, address, isDarkMode } = this.props;
    const { HEADER, POP_UP } = langJson;
   
    if (selectedAccount && localStorage.getItem(`loginData-${address}`) && localStorage.getItem(`accData-${address}`)) {
      return (
        <>
          <MediaQuery minWidth={768} maxWidth={1023}>
          <div style={{ color: 'white', fontSize: '16px', fontWeight: 'normal', marginLeft: '1rem' }} onClick={async () => {
              if (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{HEADER.LOGOUT_MSG}</span>, POP_UP.OK, POP_UP.CANCEL, { backgroundColor: "#ec6856" })) {
                return
              }
              signOut()
              window.location.reload(false)
            }}>
              {HEADER.LOGOUT}
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1024}>
            {/* <img
              className="navbar-brand"
              src={require('../../images/login.png').default}
              style={{ marginLeft: '8px', marginRight: '0px' }}
              alt="connect"
              width="16px"
              height="22px"
            /> */}
            <button
              className="connect"
              style={{ color: `${isDarkMode ? '#8d94af' : 'black'}` }}
              onClick={async () => {
                if (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{HEADER.LOGOUT_MSG}</span>, POP_UP.OK, POP_UP.CANCEL, { backgroundColor: "#ec6856" })) {
                  return
                }
                signOut()
                window.location.reload(false)
              }}>
              {HEADER.LOGOUT}
            </button>
            <span>|</span>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div style={{ color: 'white', fontSize: '16px', fontWeight: 'normal', marginLeft: '1rem' }} onClick={async () => {
              if (!await confirm(<span style={{ fontWeight: 'bold', color: "blue" }}>{HEADER.LOGOUT_MSG}</span>, POP_UP.OK, POP_UP.CANCEL, { backgroundColor: "#ec6856" })) {
                return
              }
              signOut()
              window.location.reload(false)
            }}>
              {HEADER.LOGOUT}
            </div>

          </MediaQuery>
        </>
      );
    } else {
      return null
    }

  }

  renderDocumentLink() {
    const { isDarkMode } = this.props
    return (
      <>
        {/* <img
          className="navbar-brand"
          src={require('../../images/api_icon.png').default}
          style={{ marginLeft: '8px', marginRight: '0px' }}
          alt="connect"
          width="16px"
          height="24px"
        /> */}
        {/* <button
          className="connect"
          style={{ color: `${isDarkMode ? '#8d94af' : 'black'}` }}
          onClick={() => {
            window.open(env.SWAGGER, '_blank');
          }}>
          API
        </button> */}
        <span>|</span>
      </>
    );
  }

  renderAccount() {
    const { address, dispatch, isLoggedIn, langJson, isDarkMode } = this.props;
    const { HEADER, TOAST } = langJson;
    // {alert("h0",isLoggedIn,address)}
    dispatch(checkWallet()).then(result => {
      if (result) {
        this.setState({
          isWalletUnlocked: false
        })
      } else {
        this.setState({
          isWalletUnlocked: true
        })
      }
    })

    if (isLoggedIn && address) {
      if (localStorage.getItem(`accData-${address}`)) {
        return (
          <>
            <div className="item" style={{ color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
              <WalletButton />
            </div>
          </>
        );
      } else if (this.state.isWalletUnlocked) {
        dispatch(VerifyAccount()).then(result => {
          if (!isNaN(result)) {
            window.localStorage.setItem(`accData-${address}`, JSON.stringify(result));
            this.setState(prevState => {
              return { addAccount: prevState.addAccount + 1 }
            });
          }
        });
        return (
          <>
            {/* <img
              className="navbar-brand"
              src={require('../../images/login.png').default}
              style={{ marginRight: '0px' }}
              alt="login"
              width="16px"
              height="24px"
            /> */}
            <button
              className="connect"
              style={{ color: `${isDarkMode ? '#8d94af' : 'black'}` }}
              onClick={() => {
                if (localStorage.getItem(`loginData-${address}`)) {
                  dispatch(AddAccount()).then(result => {
                    if (!isNaN(result)) {
                      if (Number(result) > 0) {
                        window.localStorage.setItem(`accData-${address}`, JSON.stringify(result));
                        this.setState(prevState => {
                          return { addAccount: prevState.addAccount + 1 }
                        });
                        this.notify1(TOAST);
                      } else {
                        this.notify2(TOAST);
                      }
                    } else {
                      this.notify2(TOAST);
                    }
                  });
                } else {
                  this.notify(TOAST);
                }
              }}>
              {HEADER.LOGIN}
            </button>
            <span>|</span>
          </>
        );
      } else {
        return (
          <>
            <div className="item" style={{ color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
              <WalletButton />
            </div>
            <span>|</span>
          </>
        );
      }
    } else if (address && this.state.isWalletUnlocked) {
      return (
        <>
          {/* <img
            className="navbar-brand"
            src={require('../../images/connect.png').default}
            style={{ marginRight: '0px' }}
            alt="connect"
            width="16px"
            height="24px"
          /> */}
          <button className="connect" style={{ color: `${isDarkMode ? '#8d94af' : 'black'}` }} onClick={() => dispatch(loginRequest())}>
            {HEADER.CONNECT}
          </button>
          <span>|</span>
        </>
      );
    } else {
      return (
        <>
          <div className="item" style={{ color: `${isDarkMode ? '#8d94af' : 'black'}` }}>
            <WalletButton />
          </div>
          <span>|</span>
        </>
      );
    }
  }

  // renderAddAccount() {
  //   const { address, dispatch, langJson } = this.props;
  //   const { HEADER, TOAST } = langJson;

  //   if (localStorage.getItem(`accData-${address}`)) {
  //     return (
  //       <>
  //         <div className="item">
  //           <WalletButton />
  //         </div>
  //         <span>|</span>
  //       </>
  //     );
  //   } else {
  //     console.log("came in if # 5")
  //     return (
  //       <>
  //         <img
  //           className="navbar-brand"
  //           src={require('../../images/login.png').default}
  //           style={{ marginRight: '0px' }}
  //           alt="login"
  //           width="16px"
  //           height="24px"
  //         />
  //         <button
  //           className="connect"
  //           onClick={() => {
  //             if (localStorage.getItem(`loginData-${address}`)) {
  //               dispatch(AddAccount()).then(result => {
  //                 if (!isNaN(result)) {
  //                   if (Number(result) > 0) {
  //                     window.localStorage.setItem(`accData-${address}`, JSON.stringify(result));
  //                     this.setState({
  //                       addAccount: 1
  //                     });
  //                     this.notify1(TOAST);
  //                   } else {
  //                     this.notify2(TOAST);
  //                   }
  //                 } else {
  //                   this.notify2(TOAST);
  //                 }
  //               });
  //             } else {
  //               this.notify(TOAST);
  //             }
  //           }}>
  //           {HEADER.LOGIN}
  //         </button>
  //         <span>|</span>
  //       </>
  //     );
  //   }
  // }
}

const Wrap = styled.div`
  width: 100%;
  height: 68px;
  background: ${({ dark }) => dark ? '#1c2b3a' : '#f9fbfd'};
  .content {
    width: 1200px;
    margin: 0 auto;
    height: 56px;
  }

  .activeLink {
    font-weight: bold;
  }

  .HydroSDK-toggleButton {
    outline: none;
    border: none;
    background-color: transparent !important;
    border-color: transparent !important;
    border-style: none;
    border-radius: 0px;
    font-size: 16px;
    margin-left: 8px;
    padding: 0px !important;
    color: ${({ dark }) => (dark ? '#8d94af' : 'black')}; !important;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active,
    &:visited {
        box-shadow: none;
        border: transparent;
        outline: none !important;
    }
}

  .switch {
		display: inline-block;
		.bright,
		.dark {
			width: 30px;
      height: 34px;
      margin-top: 4px;
			color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
			background: none;
			padding: 0;
      border: 0;
			cursor: pointer;
			display: inline-block;
			outline: 0;
			i {
        font-size: 17px;
			}
		}
		.select {
			color: #525aee;
		}
		.dark {
      margin-left: 3px;
    }
  }

  @media (max-width: 1024px) {
    width: 95%;
    margin: 0 auto;
    height: 3.5rem;
    .content {
      width: 100%;
      line-height: 3.5rem;
      .navigation,
      .util {
        display: none;
      }
      .bm-overlay {
        top: 0;
        left: 0;
      }
      .burger {
        top: 0;
        background: #525aee;
        .bm-cross-button {
          top: 24px !important;
          right: 24px !important;
          .xi-close {
            color: #fff;
            position: absolute;
            font-size: 1.4rem;
          }
        }

        .bm-item {
          padding-top: 4rem;
          padding-left: 2rem;
          height: 100%;
          .mobileMenu {
            display: block;
            height: 3rem;
            color: #fff;
            text-decoration: none;
          }
        }
        .lang {
          margin-top: 2rem;
          .langSelect {
            color: #fff;
            border: 0;
          }
          .xi-globus {
            font-size: 1.2rem;
            vertical-align: middle;
            color: #fff;
            margin-right: 0.1rem;
          }
        }
      }
      .bm-burger-button {
        position: absolute;
        top: 0;
        right: 1rem;
      }
    }
  }
`;

export default connect(mapStateToProps)(Header);
