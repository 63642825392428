import React from 'react';
import { connect } from 'react-redux';
import { loadMarkets, loadTradeHistory } from '../actions/markets';
import env from '../lib/env';
import MediaQuery from 'react-responsive';
import Label from '../components/For_Dashboard/Label';
import Info from '../components/For_Dashboard/Info';
import Panel from '../components/For_Dashboard/Panel';
import Card from '../components/For_Dashboard/Card';
//import { Wallet as SDKWallet } from 'exnomy-wallet';
import 'exnomy-wallet/index.css';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Popup from '../components/Popup';

const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  return {
    selectedAccountID,
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
    isDarkMode: state.theme.isDarkMode
  };
};

class Dashboard extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      mobileTab: 'trade'
    };
  }

  componentDidMount() {
    const { dispatch, currentMarket } = this.props;
    dispatch(loadMarkets());
    if (parseInt(env.NETWORK_ID) === 66) {
      this.initTestBrowserWallet();
    }
    if (currentMarket) {
      dispatch(loadTradeHistory(currentMarket.id));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentMarket, dispatch } = this.props;
    if (currentMarket !== prevProps.currentMarket) {
      dispatch(loadTradeHistory(currentMarket.id));
    }
  }

  render() {
    const { currentMarket } = this.props;
    if (!currentMarket) {
      return null;
    }
    return (
      <div>
        {/* <SDKWallet title="Starter Kit Wallet" nodeUrl={env.NODE_URL} defaultWalletType="Hydro-Wallet" /> */}
        <Popup />
        <MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </div>
    );
  }

  renderMobile() {
    return (
      <Wrap>
        <Label />
        <Card />
        <Panel />
        <Info />
        <Footer />
      </Wrap>
    );
  }

  renderTablet() {
    return (
      <Wrap>
        <Label />
        <Card />
        <Panel />
        <Info />
        <Footer />
      </Wrap>
    );
  }

  renderLaptop() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
        <Label />
        <Card />
        <Panel />
        <Info />
        <Footer />
      </Wrap>
    );
  }

  renderDesktop() {
    const { isDarkMode } = this.props;
    return (
      <Wrap dark={isDarkMode}>
        <Label />
        <Card />
        <Panel />
        <Info />
        <Footer />
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  width: 100%;
  padding-top: 16px;
  //background-color: #f9fbfd;
  background: ${({dark}) => dark ? '#1c2b3a' : '#f9fbfd'};
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding:8px;
  }
`;
export default connect(mapStateToProps)(Dashboard);
